import { HttpParams } from '@angular/common/http';
import { FiltroRelatorioEgte } from './../../shared/models/filtro-relatorio-egte';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CpsHttpClient } from './cps-http.service';
import { SettingsService } from './settings.service';
import { RelatorioEgte } from '../../shared/models';



@Injectable()
export class RelatorioEgteService {

    constructor(private http: CpsHttpClient, private settings: SettingsService) { }

    consultarEgteConvenete(filtro: FiltroRelatorioEgte): Observable<RelatorioEgte[]> {

        let params = null;
        if (filtro) {
          params = Object.getOwnPropertyNames(filtro).forEach(function(val, idx, array) {
            if(!filtro[val]){
              delete filtro[val];
            }
          });
          params = Object.getOwnPropertyNames(filtro).reduce((p, key) => p.set(key, filtro[key]), new HttpParams());
        }
        const options = params ? {params: params} : {};

        return this.http.get<RelatorioEgte[]>(this.settings.UrlCpsRest() + 'api/relatorio/relatorioegte', options);
    }
   
}
