import { Egt } from './egt';

export class EgtVersao {

  constructor(
    public cpsVersaoFk: number,
    public egtFk: number,
    public quantidade: number,
    public egt: Egt) {
  }
}

