import { Component, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { FiltroDocumentoCobranca } from '../../../../shared/models/filtro-documento-cobranca';
import { EventButtonService, EgtService } from '../../../../core/services';
import { Egt } from '../../../../shared/models';
import * as moment from 'moment';
import 'moment/locale/pt-br';

@Component({
  selector: 'cps-doc-cob-filtro',
  templateUrl: './filtro.component.html',
  styleUrls: ['./filtro.component.scss']
})
export class FiltroDocumentoCobrancaComponent implements OnInit {

  @Output() filtroDocCob: FiltroDocumentoCobranca = null;
  form: FormGroup;

  listaEgts: any[] = new Array();
  listaUFs: any[] = ['AC','AL','AM','AP','BA','CE','DF','ES','GO','MA','MG',
  'MS','MT','PA','PB','PE','PI','PR','RJ','RN','RO','RR','RS','SC','SE','SP','TO'];
  covenenteCtrl: FormControl = new FormControl('', Validators.required);
  dtEgtInicioCtrl: FormControl = new FormControl('');
  dtEgtFimCtrl: FormControl = new FormControl('');

  constructor(
    private formBuilder: FormBuilder,
    private egtservice: EgtService
  ) {
  }

  ngOnInit() {
    this.getForm();
    this.carregarEgts();
    this.onFiltrar();
  }

  getForm(): void {
    this.form = this.formBuilder.group({
      proposta: null,
      uf: null,
      convenente: null,
      programa: null,
      egt: null,
      dtEgtInicio: null,
      dtEgtFim: null
    });
  }

  carregarEgts() {
    this.listarEgtsOrdinarias();
  }

  listarEgtsOrdinarias() {
    this.egtservice.listarEgtsOrdinarias(null).subscribe(
      (response: Egt[]) => {
        if (response) {
          this.listaEgts = new Array();
          response.map(e => {
            this.listaEgts.push(e.codigo);
          });
          
        }     
        this.listarEgtsExtras();   
      }
    );
  }

  listarEgtsExtras() {
    this.egtservice.listarEgtsExtras(null).subscribe(
      (response: Egt[]) => {
        if (response) {
          response.map(e => {
            this.listaEgts.push(e.codigo);
          });
        }        
      }
    );
  }

  getFiltro(form: FormGroup): FiltroDocumentoCobranca {
    let filtro: FiltroDocumentoCobranca = new FiltroDocumentoCobranca();
    filtro.proposta = form.controls['proposta'].value;
    
    const uf = form.controls['uf'].value;
    if (uf) {
      filtro.uf = uf;
    } else {
      filtro.uf = null;
    }

    filtro.convenente = form.controls['convenente'].value;
    if (filtro.convenente) {      
      filtro.convenente = filtro.convenente.toUpperCase() + '%';
    }
    
    filtro.programa = form.controls['programa'].value;   

    filtro.egt = form.controls['egt'].value;
    if (form.controls['dtEgtInicio'].value) {
      filtro.dataInicialEgt = (moment(form.controls['dtEgtInicio'].value, 'YYYY-MM-DD').format('YYYY-MM-DD'));
    } else {
      filtro.dataInicialEgt = null;
    }
    if (form.controls['dtEgtFim'].value) {
      filtro.dataFinalEgt = (moment(form.controls['dtEgtFim'].value, 'YYYY-MM-DD').format('YYYY-MM-DD'));
    } else {
      filtro.dataFinalEgt = null;
    }
    return filtro;
  }

  onFiltrar() {
    this.filtroDocCob = this.getFiltro(this.form);    
    EventButtonService.get('filtrarDocCob').emit(this.filtroDocCob);
  }  
}
