export class CpsAnexo {

  constructor(
    public id: number = 0,
    public cpsVersaoFk: number = 0,
    public txDescricao: string = null,
    public arquivo: string = null,
    public nome: string = null,
    public situacao: string = null,
    public tamanho: number = null
  ) { }
}
