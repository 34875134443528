import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { CpsService, DocumentoCobrancaService, EventButtonService, IdpService } from '../../core/services';
import { Cps, CpsVersaoSituacaoEnum, Usuario, DataExport } from '../../shared/models';
import { AlertMessageService } from '@serpro/ngx-siconv';
import { FiltroCps } from '../../shared/models/filtro-cps';

@Component({
  selector: 'cps-listagem',
  templateUrl: './cps-listagem.component.html',
  styleUrls: ['./cps-listagem.component.scss']
})
export class CpsListagemComponent implements OnInit {

  modalRef: BsModalRef;
  deleteMessage = '';
  delCps: Cps = null;
  listaCps: any[] = new Array();
  listaCpsOrdenavel: any[] = new Array();
  lista: any[] = null;
  usuario: Usuario = null;

  ehUsuarioMandatariaGestor = false;
  
  _currentPage: number = 1;
  public get currentPage() : number {
    return this._currentPage;
  }
  public set currentPage(v : number) {
    this._currentPage = v;
    localStorage.setItem('cps_page', this._currentPage.toString());
  }

  // Grid
  export: DataExport;
  fileName: string = 'CPS'

  constructor(
    private cpsService: CpsService,
    private docService: DocumentoCobrancaService,
    private idpService: IdpService,
    private router: Router,
    private route: ActivatedRoute,
    private mensageService: AlertMessageService,
    private modalService: BsModalService) {

      EventButtonService.get('filtrarCps').subscribe(filtro => {
        if (filtro) {          
          this.listarCpsPorFiltro(filtro);
        }
      });   
    }

  ngOnInit() {
    this.mensageService.dismissAll();
    this.cpsService.removerCpsCorrente();
    this.docService.removerDocumentoCobrancaCorrente();
    this.usuario = this.idpService.loggedUser;
    this.idpService.retoken();
    this.listarCps();
    this.ehUsuarioMandatariaGestor = this.usuario.isUsuarioMandatariaGestor();    
    let hasPageQueryString = false;;
    this.route.queryParams
      .filter(params => params.page).subscribe(params => {
        hasPageQueryString = true;
        if(localStorage.getItem('cps_page')) {
          this.currentPage = +localStorage.getItem('cps_page');
        }
      });
    
      if(!hasPageQueryString){
        this.currentPage = 1;
        localStorage.setItem('cps_page', '1');
      }
  }

  listarCps() {
    if (this.usuario.orgao != null) {
      this.listaCpsPorOrgao(this.usuario.orgao.id);            
    } else {
      this.listaCps = [];
      this.mensageService.warn('Orgão Administrativo não encontrado', 
      'Não foi possível recuperar o Orgão Administrativo do Usuário');
    }
  }
  
  afterPesquisa(data: Cps[]){
    this.listaCps = [];
    this.listaCpsOrdenavel = [];
    if (data) {
      this.listaCps = data;
      this.listaCps.forEach(element => {            
        let linha = element;
        linha._codigo = element.versao.codigoF,
        linha._situacao = element.versao.situacaoF,
        linha._dtInicio = element.versao.dtInicioVigencia,
        linha._dtFim = element.versao.dtFimVigencia;
        this.listaCpsOrdenavel.push(linha);
      });  
    }
    this.getExport();


  }

  listarCpsPorFiltro(filtro: FiltroCps){
    if(filtro){
      this.cpsService.listaCpsPorFiltro(this.usuario.orgao.id, filtro).subscribe(
        (data: Cps[]) => {
          this.afterPesquisa(data);
        }        
      )
    }    
  }
  
  listaCpsPorOrgao(orgaoId) {
    this.listaCps = new Array();
    this.listaCpsOrdenavel = new Array();

    this.cpsService.listaCpsPorFiltro(orgaoId, null).subscribe(
      (data: Cps[]) => {
        this.afterPesquisa(data);
      }
    );
  }

  podeDeletar(situacao: CpsVersaoSituacaoEnum, orgaoConcedente: number, orgaoSuperior: number): boolean {
    return this.usuario.isConcedente() &&
      (this.usuario.orgao.id === orgaoConcedente || this.usuario.orgao.id === orgaoSuperior) &&
      (situacao === CpsVersaoSituacaoEnum.ELABORACAO || situacao === CpsVersaoSituacaoEnum.EM_ADITIVACAO );
  }

  podeEditar(situacao: CpsVersaoSituacaoEnum, orgaoConcedente: number, orgaoSuperior: number): boolean {
    return (
      (this.usuario.isConcedente() &&
        (this.usuario.orgao.id === orgaoConcedente || this.usuario.orgao.id === orgaoSuperior) &&
        (situacao === CpsVersaoSituacaoEnum.ELABORACAO || situacao === CpsVersaoSituacaoEnum.ENVIADO_PARA_CIENCIA 
          || situacao === CpsVersaoSituacaoEnum.ATIVO || situacao === CpsVersaoSituacaoEnum.EM_ADITIVACAO))
      ||
      (this.usuario.isMandataria()
        && this.ehUsuarioMandatariaGestor
        && situacao === CpsVersaoSituacaoEnum.ENVIADO_PARA_CIENCIA)
    );
  }

  adicionarCps() {
    this.router.navigate(['/cps']);
  }

  // deletarAditivoCps(){
  //   this.cpsService.deletarAditivoCps(this.delCps.id).subscribe(
  //     (data) => {        
  //       this.listarCps();
  //       this.mensageService.success('Aditivo de CPS excluído.', 'Aditivo de CPS excluído com sucesso.');
  //     }
  //   );  
  // }
  
  deletarCps() {    
    this.cpsService.deletarCps(this.delCps.id).subscribe(
      (data) => {        
        this.listarCps();
        this.mensageService.success('CPS excluído.', 'CPS excluído com sucesso.');
      }
    );    
  }

  showConfirm(template: TemplateRef<any>, item) {
    this.delCps = item;
    if(item.versao.codigo === 1){
      this.deleteMessage = 'Confirma a exclusão do CPS ' + item.cps + '?';  
      this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
    } else {
      this.mensageService.error('', 'CPS está em Aditivação. Para excluir o CPS é necessário cancelar a aditivação');      
    }      
  }

  confirm(): void {
    if (this.delCps != null) {
      if(this.delCps.versao.codigo === 1){
        this.deletarCps();
      } 
      // else {        
      //   this.deletarAditivoCps();
      // }
      this.router.navigate(['/cps-lista']);
    }
    this.modalRef.hide();
  }

  decline(): void {
    this.modalRef.hide();
  }

  podeAdicionar(): boolean {
    return this.usuario.isConcedente();
  }

  getListaPaginada(listap){
    this.lista = listap;
  }

  pageChange(page){
    this.currentPage = page;
  }

  getExport(): void {
    let data = []
    const columns = [
      'Número do CPS', 'Órgão Concedente', 'Versão', 
      'Situação', 'Início de Vigência', 'Fim de Vigência'
    ];

    this.listaCps.forEach(element => {
      let linha = [];
      linha.push(element.cps);
      linha.push(element.nomeOrgaoConcedente);
      linha.push(element.versao.codigoF);
      linha.push(element.versao.situacaoF);
      linha.push(element.versao.dtInicioF);
      linha.push(element.versao.dtFimF);
      data.push(linha);
    });   

    this.export = new DataExport(columns, data);
  }

  onDetalharCPS(id: number) {
    this.router.navigate(['/cps-detalhe/' + id]);
  }

  ngOnDestroy() {
    EventButtonService.unsubscribe('filtrarCps');
  }
  
}


