import { Cps, DocumentoCobranca, ItemCobrancaResumido, TipoOutroLancamentoEnum } from './../../../../shared/models';
import { CpsCurrencyPipe } from './../../../../shared/pipes';
import { Router } from '@angular/router';
import { Component, OnInit, OnChanges, SimpleChanges, SimpleChange, Input } from '@angular/core';
import { DocumentoCobrancaService, CpsService } from '../../../../core/services';
import { DataExport } from '../../../../shared/models/data-export';
import { AlertMessageService } from '@serpro/ngx-siconv';

@Component({
  selector: 'cps-relatorio-cobranca',
  templateUrl: './relatorio-cobranca.component.html',
  styleUrls: ['./relatorio-cobranca.component.scss']
})
export class RelatorioCobrancaComponent implements OnInit, OnChanges {
  @Input() docSelecionado: DocumentoCobranca;

  mensagem = 'Não houve ateste de serviços no período do documento de cobrança.';
  cps: Cps = null;
  valorTotalAcumulado = 0;
  itensCobrancaResumido: any = new Array();
  itensCobrancaResumidoOrdenavel: any = new Array();
  itensCobrancaResumidoPaginada: any[] = null;
  documentoCobranca: DocumentoCobranca = null;
  valorOutros = 0;
  objItemCobranca: ItemCobrancaResumido = null;

  // Grid
  export: DataExport;
  fileName = "RelatorioCobrancaResumido";

  constructor(private router: Router,
    private dcservice: DocumentoCobrancaService,
    private mensageService: AlertMessageService,
    private cpsservice: CpsService) { }

  ngOnInit() {
    this.mensageService.dismissAll();
    this.cps = this.cpsservice.cpsCorrente;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const doc: SimpleChange = changes.docSelecionado;
    this.documentoCobranca = doc.currentValue;
    this.carregarDocumentoCobranca();
  }

  carregarDocumentoCobranca() {
    this.valorTotalAcumulado = 0;
    this.objItemCobranca = null;

    this.itensCobrancaResumidoOrdenavel = new Array();

    if (this.documentoCobranca != null) {
      this.itensCobrancaResumido = this.documentoCobranca.itensCobrancaResumido;
      this.itensCobrancaResumido.forEach(element => {
        this.valorTotalAcumulado += element.valorTotal;
        this.itensCobrancaResumidoOrdenavel.push(element);
      });
      if (this.documentoCobranca.itensOutroLancamento != null && this.documentoCobranca.itensOutroLancamento.length > 0) {
        this.valorOutros = 0;
        this.documentoCobranca.itensOutroLancamento.forEach(element => {
          if (element.outroLancamentoTipo === TipoOutroLancamentoEnum.DEBITO) {
            this.valorOutros -= element.valor;
          } else if (element.outroLancamentoTipo === TipoOutroLancamentoEnum.CREDITO) {
            this.valorOutros += element.valor;
          }
        });

        const currencyPipe: CpsCurrencyPipe = new CpsCurrencyPipe();
        let valorFormatado: string = 'R$ ' + currencyPipe.transform((100 * this.valorOutros).toFixed(2).toString());
        if (this.valorOutros < 0) {
          valorFormatado = 'R$ -' + currencyPipe.transform((100 * Math.abs(this.valorOutros)).toFixed(2).toString());
        }

        this.objItemCobranca = new ItemCobrancaResumido(999, '-', 'Outros Lançamentos',
          this.documentoCobranca.itensOutroLancamento.length, this.valorOutros, valorFormatado
        );

        this.itensCobrancaResumidoOrdenavel.push(this.objItemCobranca);

        this.valorTotalAcumulado += this.valorOutros;
      }
    }

    this.getExport();
  }

  getValorTotalAcumuladoF() {
    const currencyPipe: CpsCurrencyPipe = new CpsCurrencyPipe();
    return 'R$ ' +  currencyPipe.transform((100 * this.valorTotalAcumulado).toFixed(2).toString());
  }

  getExport(): void {
    let data = [];
    const columns = [
      'Item', 'EGT', 'Descrição do Serviço', 'Quantitativo Realizado', 'Valor Total Apurado'
    ];
    
    let indice = 0;
    this.itensCobrancaResumido.forEach(element => {
      let linha = [];
      linha.push(++indice);
      linha.push(element.egtCodigo);
      linha.push(element.egtDescricao);
      linha.push(element.quantitativo);
      linha.push(element.valorTotalF);
      data.push(linha);
    });   

    this.export = new DataExport(columns, data);
  }

  getListaPaginada(listap){
    this.itensCobrancaResumidoPaginada = listap;
  }
}
