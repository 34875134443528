export class ItemCobrancaResumido {
  constructor(
    public id: number,
    public egtCodigo: string,
    public egtDescricao: string,
    public quantitativo: number,
    public valorTotal: number,
    public valorTotalF: string
  ) {}
}

