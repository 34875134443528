import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Egt, EgtVersao } from '../../shared/models';
import { CpsHttpClient } from './cps-http.service';
import { SettingsService } from './settings.service';

@Injectable()
export class EgtService {

  constructor(private http: CpsHttpClient, private settings: SettingsService) { }

  listarEgtsOrdinarias(data): Observable<Egt[]> {
    return this.http.get<Egt[]>(this.settings.UrlCpsRest() + 'api/egt/1/' + data);
  }

  listarEgtsVersao(cpsId, dataReferencia): Observable<EgtVersao[]> {
    return this.http.get(this.settings.UrlCpsRest() + 'api/egt/cpsversao/' + cpsId + '/dataReferencia/' + dataReferencia);
  }

  listarEgtsExtras(data): Observable<Egt[]> {
    return this.http.get(this.settings.UrlCpsRest() + 'api/egt/2/' + data);
  }

  listarEgts(cpsId): Observable<Egt[]> {
    return this.http.get(this.settings.UrlCpsRest() + 'api/egt/cps/' + cpsId);
  }

  listarTabelasReferenciaPreco(): Observable<any[]>{
    return this.http.get(this.settings.UrlCpsRest() + 'api/egt/tabelareferencia/');
  }
}
