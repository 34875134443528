import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, FormControlDirective, ReactiveFormsModule } from '@angular/forms';
import { CpsUsuarioLogadGuard } from '../shared/guards/default.guard';
import { CpsComponent } from './cps/cps.component';
import { CpsListagemComponent } from './cps-listagem/cps-listagem.component';
import { DadosGeraisComponent } from './cps/dados-gerais/dados-gerais.component';
import { DetalhamentoContratoComponent } from './cps/detalhamento-contrato/detalhamento-contrato.component';
import { EgtComponent } from './cps/egt/egt.component';
import { DocumentoCobrancaComponent } from './cps/documento-cobranca/documento-cobranca.component';
import { RelatorioCobrancaComponent } from './cps/documento-cobranca/relatorio-cobranca/relatorio-cobranca.component';
import { RelatorioAnaliticoComponent } from './cps/documento-cobranca/relatorio-analitico/relatorio-analitico.component';
import { OutrosLancamentosComponent } from './cps/documento-cobranca/outros-lancamentos/outros-lancamentos.component';
import { CpsTransferenciaComponent } from './cps-transferencia/cps-transferencia.component';
import { FiltroDocumentoCobrancaComponent } from './cps/documento-cobranca/filtro/filtro.component';
import { UtilSqlComponent } from './util-sql/util-sql.component';
import { SiconvModule } from '../siconv.module';
import { RelatorioEgteComponent } from './relatorio-egte/relatorio-egte.component';
import { FiltroRelatorioEgteComponent } from './relatorio-egte/filtro/filtrorelatorioegte.component';
import { BsDatepickerModule } from 'ngx-bootstrap';
import { FiltroCpsComponent } from './cps-listagem/filtro/filtro.component';

@NgModule({
  imports: [
    RouterModule,
    SharedModule,
    FormsModule,
    BsDatepickerModule.forRoot(),
    ReactiveFormsModule,
    SiconvModule
  ],
  providers: [FormControlDirective,
    CpsUsuarioLogadGuard],
  exports: [],
  declarations: [
    CpsComponent,
    CpsListagemComponent,
    RelatorioEgteComponent,
    CpsTransferenciaComponent,
    DadosGeraisComponent,
    DetalhamentoContratoComponent,
    EgtComponent,
    DocumentoCobrancaComponent,
    RelatorioCobrancaComponent,
    RelatorioAnaliticoComponent,
    OutrosLancamentosComponent,
    FiltroDocumentoCobrancaComponent,
    FiltroCpsComponent,
    FiltroRelatorioEgteComponent,
    UtilSqlComponent
  ]
})
export class PagesModule { }
