import { Injectable } from '@angular/core';
import { CpsHttpClient } from './cps-http.service';
import { SettingsService } from './settings.service';
import { Observable } from 'rxjs';
import { MotivoEncerramento } from '../../shared/models';


@Injectable({
  providedIn: 'root'
})
export class MotivoEncerramentoService {

  constructor(private http: CpsHttpClient, private settings: SettingsService) {}

  get(): Observable<MotivoEncerramento[]> {
    return this.http.get(this.settings.UrlCpsRest() + 'api/motivo-encerramento');
  }

}
