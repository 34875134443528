import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'zeroEsquerdaMask'})
export class ZeroEsquerdaPipe implements PipeTransform {
    transform(value: string, size: number = 2) {
        if (value) {
             value = value.toString();
             while (value.length < size) {
                 value = '0' + value;
             }
             return value;
         }
         return value;
     }
}
