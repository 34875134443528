import { EventEmitter } from '@angular/core';

export class EventButtonService {
  private static emitters: {
    [nomeEvento: string]: EventEmitter<any>
  } = {};

  static get(nomeEvento: string): EventEmitter<any> {
    if (!this.emitters[nomeEvento]) {
      this.emitters[nomeEvento] = new EventEmitter<any>();
    }
    return this.emitters[nomeEvento];
  }

  static unsubscribe(nomeEvento: string) {
    if (!this.emitters[nomeEvento]) {
      this.emitters[nomeEvento] = new EventEmitter<any>();
    }
    this.emitters[nomeEvento].unsubscribe();
    this.emitters[nomeEvento] = null;
  }
}
