export class RelatorioEgte {
  constructor(

    public egt: string,
    public cps: string,
    public orgao: string,
    public proposta: string,
    public contrato: string,
    public uf: string,
    public convenente: string,
    public programa: string,
    public uge: string,
    public ugr: string,
    public fonte: string,
    public funcional: string,
    public objeto: string,
    public repasse: number,
    public repasseF: string,
    public nivel: string,
    public dtInicioContratacaoF: string,
    public dtFimContratacaoF: string,
    public dtAteste: string,
    public dtAtesteF: string,
    public statusEgt: string,
    public valorTarifa: number,
    public valorTarifaF: string,
    public responsavelConvenente: string

 
  ) { }
}

