import { ItemOutroLancamento } from './item-outro-lancamento';
import { DocumentoCobrancaAnexo } from './documento-cobranca-anexo';
import { ItemCobrancaResumido } from './item-cobranca-resumido';
import { ItemCobranca } from './item-cobranca';
import { CpsVersao } from './cps-versao';

export class DocumentoCobranca {
  constructor(
    public id: number,
    public dtFechamento: string,
    public dtInicioF: string,
    public dtFimF: string,
    public valorTotal: number,
    public situacao: number,
    public dtFechamentoF: string,
    public periodoF: string,
    public valorTotalF: String,
    public situacaoF: string,
    public dataSituacaoF: string,
    public itensCobrancaResumido: ItemCobrancaResumido[] = [],
    public itensCobrancaOrdinaria: ItemCobranca[] = [],
    public itensCobrancaExtra: ItemCobranca[] = [],
    public itensOutroLancamento: ItemOutroLancamento[] = [],
    public anexos: DocumentoCobrancaAnexo[] = [],
    public cpsVersaoFk: number, 
    public cpsVersao: CpsVersao

  ) { }
}

