import { DocumentoCobranca, DocumentoCobrancaAnexo, ItemOutroLancamento } from './../../shared/models';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CpsHttpClient } from './cps-http.service';
import { SettingsService } from './settings.service';
import { FiltroDocumentoCobranca } from '../../shared/models/filtro-documento-cobranca';

@Injectable()
export class DocumentoCobrancaService {

  private _documentoCobranca: DocumentoCobranca;

  constructor(private http: CpsHttpClient, private settings: SettingsService) { }

  get documentoCobrancaCorrente(): DocumentoCobranca {
    let documentoCobranca: DocumentoCobranca;
    if (sessionStorage.length > 0) {
      documentoCobranca = JSON.parse(sessionStorage.getItem('documento-cobranca-corrente'));
      this._documentoCobranca = documentoCobranca;
    } else {
      this._documentoCobranca = null;
    }
    return this._documentoCobranca;
  }

  set documentoCobrancaCorrente(documentoCobrancaCorrente: DocumentoCobranca) {
    sessionStorage.setItem('documento-cobranca-corrente', JSON.stringify(documentoCobrancaCorrente));
    this._documentoCobranca = documentoCobrancaCorrente;
  }

  public removerDocumentoCobrancaCorrente() {
    sessionStorage.removeItem('documento-cobranca-corrente');
  }

  enviar(documentoCobranca, anexos?: DocumentoCobrancaAnexo[]): Observable<number> {
    const headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    return this.http.put(`${this.getUrl() + '/enviar'}`, this.toServer(documentoCobranca, anexos), { headers: headers });
  }

  aceitar(documentoCobrancaId): Observable<number> {
    const headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    return this.http.put(`${this.getUrl()}` + '/aceitar', documentoCobrancaId, { headers: headers });
  }

  detalharDocumentosCobranca(documento_cobranca_id, filtro: FiltroDocumentoCobranca): Observable<DocumentoCobranca> {
    let params = null;
    if (filtro) {
      params = Object.getOwnPropertyNames(filtro).forEach(function(val, idx, array) {
        if(!filtro[val]){
          delete filtro[val];
        }
      });
      params = Object.getOwnPropertyNames(filtro).reduce((p, key) => p.set(key, filtro[key]), new HttpParams());
    }
    const options = params ? {params: params} : {};
    return this.http.get(`${this.getUrl()}` + '/' + documento_cobranca_id, options);
  }

  listarDocumentosCobranca(cps_id, filtro): Observable<DocumentoCobranca[]> {
    return this.http.get(`${this.getUrl()}` + '/cps/' + cps_id, filtro);
  }

  getUrl() {
    return this.settings.UrlCpsRest() + 'api/cobranca';
  }

  deletarOutroLancamento(outroLancamentoId): Observable<number> {
    return this.http.delete(`${this.getUrl() + '/outrolancamento/'}${outroLancamentoId}`);
  }

  incluirOutroLancamento(lancamento: ItemOutroLancamento): Observable<number> {
    return this.http.post(`${this.getUrl() + '/outrolancamento'}`, lancamento);
  }

  listarAnexos(id): Observable<DocumentoCobrancaAnexo[]> {
    return this.http.get(`${this.getUrl() + '/'}${id}` + '/anexos');
  }

  baixarAnexo(id_doccobranca, id_anexo): Observable<DocumentoCobrancaAnexo[]> {
    return this.http.get(`${this.getUrl() + '/'}${id_doccobranca}` + '/anexos/' + `${id_anexo}`);
  }

  salvarAnexo(documentoCobranca: DocumentoCobranca, anexos?: any) {
    const headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    return this.http.post(`${this.getUrl() + '/anexar'}`, this.toServer(documentoCobranca, anexos), { headers: headers });
  }

  // Cria uma lista de anexos apenas com os arquivos novos para que a requisição não passe de 25MB
  getAnexos(listaAnexos: any): DocumentoCobrancaAnexo[] {
    let anexos: DocumentoCobrancaAnexo[] = null;
    if (!listaAnexos) {
      return null;
    } else {
      anexos = new Array();
      listaAnexos.forEach(anexo => {
        anexos.push(
          anexo.id,
          anexo.documentoCobrancaFk,
          anexo.txDescricao,
          anexo.situacao === 'N' ? anexo.aquivo : null,
          anexo.nome,
          anexo.situacao,
          anexo.tamanho);
      });
    }
    return anexos;
  }

  toServer(documentoCobranca: DocumentoCobranca, array_anexos?: DocumentoCobrancaAnexo[]): any {
    documentoCobranca.anexos = array_anexos ? array_anexos : null;

    return documentoCobranca;
  }
}
