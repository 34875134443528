import { InstrumentoTransferido } from "./instrumento-transferido";

export class TransferenciaInstrumento {
  constructor(    
    public id: number,
    public cpsOrigemFk: number,
    public cpsDestinoFk: number,
    public justificativa: String,
    public dtTransferenciaF: String,
    public login: String,
    public instrumentosTransferidos: InstrumentoTransferido[] = []
  ) { }
}

