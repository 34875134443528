
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { UtilToken } from '../../shared/util';
import { HttpErrorHandler } from '@serpro/ngx-siconv';

export function cpsHttpClientCreator(http: HttpClient, httpErrorHandler: HttpErrorHandler, utilToken: UtilToken) {
  return new CpsHttpClient(http, httpErrorHandler, utilToken);
}

export class CpsRequestOptions {
  headers?: HttpHeaders;
  observe?: 'body';
  params?: HttpParams;
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
  body?: any;
}

export class RequestOptionsTextResponse {
  headers?: HttpHeaders | {
    [header: string]: string | string[];
  };
  observe?: 'body';
  params?: HttpParams | {
    [param: string]: string | string[];
  };
  reportProgress?: boolean;
  responseType: 'text';
  withCredentials?: boolean;
}

@Injectable()
export class CpsHttpClient {

  public constructor(public http: HttpClient,
    private httpErrorHandler: HttpErrorHandler,
    private utilToken: UtilToken) {
  }

  public getNoToken<T>(url: string, options?: CpsRequestOptions, ignoreGlobalErrorHandler = false): Observable<T> {
    return this.watch<T>(this.http.get<T>(url, options), ignoreGlobalErrorHandler);
  }

  public get<T>(url: string, options?: CpsRequestOptions, ignoreGlobalErrorHandler = false): Observable<T> {
    options = this.getDefaultRequestOptions(options);
    return this.watch<T>(this.http.get<T>(url, options), ignoreGlobalErrorHandler);
  }

  public post<T>(url: string, entity: Object, options?: CpsRequestOptions, ignoreGlobalErrorHandler = false): Observable<T> {
    options = this.getDefaultRequestOptions(options);
    return this.watch<T>(this.http.post<T>(url, entity, options), ignoreGlobalErrorHandler);
  }

  public put<T>(url: string, entity: Object, options?: CpsRequestOptions, ignoreGlobalErrorHandler = false): Observable<T> {
    options = this.getDefaultRequestOptions(options);
    return this.watch<T>(this.http.put<T>(url, entity, options), ignoreGlobalErrorHandler);
  }

  public delete<T>(url: string, options?: CpsRequestOptions, ignoreGlobalErrorHandler = false): Observable<T> {
    options = this.getDefaultRequestOptions(options);
    return this.watch<T>(this.http.delete<T>(url, options), ignoreGlobalErrorHandler);
  }

  public getBlob(url: string, options?: {
    headers?: HttpHeaders;
    observe: 'response';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'blob';
    withCredentials?: boolean;
  }, ignoreGlobalErrorHandler = false): Observable<HttpResponse<Blob>> {
    options.headers = this.getHeader();
    return this.watch(this.http.get(url, options), ignoreGlobalErrorHandler);
  }

  watch<T>(response: Observable<T>, ignoreGlobalErrorHandler: boolean): Observable<T> {
    return response.catch(err => {
      if (!ignoreGlobalErrorHandler) {
        if (err && err.error instanceof Blob) {
          const fileReader = new FileReader();
          fileReader.onloadend = (e => {
            const error = JSON.parse(fileReader.result.toString());
            const responseError: any = {
              'error': {
                'code': err.status,
                'messages': error.messages
              }
            };
            this.httpErrorHandler.handle(responseError);
          });
          fileReader.readAsText(err.error, 'utf-8');
        } else {
          this.httpErrorHandler.handle(err);
        }
      }
      return observableThrowError(err);
    });
  }

  getHeader(): HttpHeaders {
    const jwt = this.utilToken.getToken();
    if (jwt) {
      return new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + jwt.token
      });
    } else {
      return new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      });
    }
  }

  getDefaultRequestOptions(options?: CpsRequestOptions): CpsRequestOptions {
    const header = this.getHeader();
    if (!options) {
      return <CpsRequestOptions>{
        headers: header
      };
    } else {
      options.headers = header;
      return options;
    }
  }
}
