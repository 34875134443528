import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'limitarTexto'
})
export class LimitarTextoPipe implements PipeTransform {

  transform(value: string, args?: any): any {

    if (value && args > 0 && value.length > args) {
      return value.substring(0, args) + '...';
    }

    return value;
  }

}
