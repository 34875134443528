import { OnInit, Component } from '@angular/core';
import { BsModalRef, TypeaheadOptions } from 'ngx-bootstrap';
import { UtilToken } from '../../util';
import { IdpService } from '../../../core/services';

@Component({
  selector: 'pc-modal',
  templateUrl: './modal.component.html'
})
export class ModalContentComponent implements OnInit {
  msg: string;
  logout: boolean;

  constructor(private idpService: IdpService, public bsModalRef: BsModalRef) {}

  ngOnInit() {
  }

  realizarLogout(): void {
    this.idpService.logout();
  }

  close(): void {
    this.bsModalRef.hide();
  }
}
