import { Injectable } from '@angular/core';
import { Headers } from '@angular/http';
import { Observable } from 'rxjs';

import { CpsHttpClient } from './cps-http.service';
import { SettingsService } from './settings.service';

@Injectable()
export class MenuService {

  constructor(private http: CpsHttpClient, private settings: SettingsService) { }

  getMenu(): Observable<any> {
    const headers = new Headers();
    headers.append('Accept', 'application/json');
    const options = this.http.getDefaultRequestOptions();
    options.withCredentials = true;
    return this.http.getNoToken(this.settings.UrlSiconv() + '/api/menu', options);
  }
}
