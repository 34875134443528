import { PropostaSituacaoPipe, SiconvDateHourPipe } from './../../../shared/pipes';
import { ContratoRepasseVinculado, ContratoRepasseSubrogado, HistoricoCps, DataExport } from '../../../shared/models';
import { CpsService, EventButtonService, DetalhamentoContratoService } from './../../../core/services';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Angular2Csv } from 'angular2-csv/Angular2-csv';
import { SettingsService } from '../../../core/services/settings.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertMessageService } from '@serpro/ngx-siconv';

@Component({
  selector: 'cps-detalhamento-contrato',
  templateUrl: './detalhamento-contrato.component.html',
  styleUrls: ['./detalhamento-contrato.component.scss']
})
export class DetalhamentoContratoComponent implements OnInit, OnDestroy {

  @Input() nomeOrgaoConcedente: String = '';
  contratosRepasse: ContratoRepasseVinculado[] = new Array();
  contratosRepasseSubrogado: ContratoRepasseSubrogado[] = new Array();
  historicoContrato: HistoricoCps[] = new Array();
  contratosRepassePaginada: any[] = null;
  contratosRepasseSubrogadoPaginada: any[] = null;
  historicoContratoPaginada: any[] = null;
  file: Angular2Csv = null;  

  // Grid
  export1: DataExport;
  export2: DataExport;
  export3: DataExport;
  fileName1 = "ContratosRepasse";
  fileName2 = "ContratosRepasseSubrogados";
  fileName3 = "HistoricoContrato";

  cpsId = null;

  constructor(
    private settingsService: SettingsService,
    private detalhamentoContratoService: DetalhamentoContratoService,
    private cpsService: CpsService,
    private router: Router,
    private mensageService: AlertMessageService,
    private route: ActivatedRoute) {
      this.cpsId = cpsService.cpsCorrente.id;
      
    EventButtonService.get('registrarCiencia').subscribe(data => { if (data) { this.chamarConsultas(); } });
    EventButtonService.get('enviarParaCiencia').subscribe(data => { if (data) { this.chamarConsultas(); } });
    EventButtonService.get('salvarCps').subscribe(data => { if (data) { this.chamarConsultas(); } });
  }

  ngOnInit() {
    this.mensageService.dismissAll();
    this.chamarConsultas();
  }
  
  getTextoTransferidoSubrogado(row) : string{
    let retorno = "";
    if(row.transferido === true){
      retorno = "Transferido";
    } else if(row.transferido === false && row.dataSubrogacao){
      retorno = "Subrogado";
    }
    return retorno;
  }

  ngOnDestroy() {
    EventButtonService.unsubscribe('registrarCiencia');
    EventButtonService.unsubscribe('enviarParaCiencia');
    EventButtonService.unsubscribe('salvarCps');
  }

  chamarConsultas() {
    this.consultarContratosRepasseVinculados(this.cpsService.cpsCorrente.id);
    this.consultarContratosRepasseSubrogados(this.cpsService.cpsCorrente.id);
    this.consultarHistoricoCPS(this.cpsService.cpsCorrente.id);
  }

  consultarHistoricoCPS(id: number) {
    this.detalhamentoContratoService
      .consultarHistoricoCps(id)
      .subscribe((response: any) => {
        if (response) {
          this.historicoContrato = response.map(
            h => new HistoricoCps(h.usuario,
              new Date(h.dataSituacao.year, h.dataSituacao.monthValue, h.dataSituacao.dayOfMonth, h.dataSituacao.hour, h.dataSituacao.minute, h.dataSituacao.second), 
              h.dataSituacaoF, h.situacaoPropostaF, h.situacaoPropostaAnteriorF, h.motivoEncerramento, h.justificativaEncerramento, h.cpsVersaoFk, h.codigo
              )
          );
          this.getExport3();
        }
      });
  }

  consultarContratosRepasseVinculados(id: number) {
    this.detalhamentoContratoService
      .consultarContratosRepasseVinculados(id)
      .subscribe((response: any) => {
        if (response) {
          const propostaSituacaoPipe = new PropostaSituacaoPipe();  
          this.contratosRepasse = response.map(
            cr => new ContratoRepasseVinculado(cr.numeroProposta, cr.numeroSiconv,
              cr.uf, cr.convenente, cr.situacaoProposta ? propostaSituacaoPipe.transform(cr.situacaoProposta) : '', cr.valorRepasse, cr.valorRepasseF,
              cr.cpsOrigem, cr.orgaoOrigem, cr.dataSubrogacao, cr.propFk, cr.transferido)
          );
          this.getExport1();
        }        
      });
  }

  consultarContratosRepasseSubrogados(id: number) {
    this.detalhamentoContratoService
      .consultarContratosRepasseSubrogados(id)
      .subscribe((response: any) => {
        if (response) {          
          const propostaSituacaoPipe = new PropostaSituacaoPipe();  
          this.contratosRepasseSubrogado = response.map(            
            cr => new ContratoRepasseSubrogado(cr.numeroProposta, cr.numeroSiconv,
              cr.uf, cr.convenente, 
              cr.situacaoProposta ? propostaSituacaoPipe.transform(cr.situacaoProposta) : '', 
              cr.valorRepasse, cr.valorRepasseF,
              cr.idCpsDestino, cr.cpsDestino, cr.orgaoDestino, cr.dataSubrogacao, cr.dataSubrogacaoF)              
          );
                   
          
          this.getExport2();
        }
      });
  }

  podeExportar() {
    return (this.contratosRepassePaginada);
  }

  podeExportarSubrogados() {
    return (this.contratosRepasseSubrogadoPaginada);
  }

  getListaPaginada1(listap){
    this.contratosRepassePaginada = listap;
  }

  getListaPaginada2(listap){
    this.contratosRepasseSubrogadoPaginada = listap;
  }
 
  getListaPaginada3(listap){
    this.historicoContratoPaginada = listap;
  }

  getExport1(): void {
    let data = []
    const columns = [
      'Número da Proposta', 'Número SICONV/SIAFI', 'UF', 'Convenente', 
      'Situação', 'Valor de Repasse', 'Ação', 'Data da Subrogação/Trasnferência', 'CPS Origem', 'Órgão Origem'
    ];
    
    const propostaSituacaoPipe = new PropostaSituacaoPipe();
    const datePipe = new SiconvDateHourPipe();
    this.contratosRepasse.forEach(element => {
      let linha = [];
      linha.push(element.numeroProposta);
      linha.push(element.numeroSiconv);
      linha.push(element.uf);
      linha.push(element.convenente);
      linha.push(element.situacaoProposta);
      linha.push(element.valorRepasseF);
      linha.push(element.transferido === true ? 'Transferência' : (element.transferido === false && element.dataSubrogacao ? 'Subrogação' : ''));
      linha.push(element.dataSubrogacao ? datePipe.transform(element.dataSubrogacao)  : '');
      linha.push(element.cpsOrigem);
      linha.push(element.orgaoOrigem);

      data.push(linha);
    });   

    this.export1 = new DataExport(columns, data);
  }

  getExport2(): void {
    let data = [];
    const columns = [
      'Número da Proposta', 'Número SICONV/SIAFI', 'UF', 'Convenente', 
      'Valor de Repasse', 'Data da Subrogação / Transferência' , 'CPS | Órgão Destino'
    ];
   
    const propostaSituacaoPipe = new PropostaSituacaoPipe();
    this.contratosRepasseSubrogado.forEach(element => {
      let linha = [];
      linha.push(element.numeroProposta);
      linha.push(element.numeroSiconv);
      linha.push(element.uf);
      linha.push(element.convenente);      
      linha.push(element.valorRepasseF);
      linha.push(element.dataSubrogacaoF);      
      linha.push('CPS ' + element.cpsDestino + ' | ' + element.orgaoDestino);
      data.push(linha);
    });

    this.export2 = new DataExport(columns, data);
  }

  getExport3(): void {
    let data = [];
    const columns = [
      'Usuário', 'Data da Situação', 'Situação'      
    ];
   
    const propostaSituacaoPipe = new PropostaSituacaoPipe();
    this.historicoContrato.forEach(element => {
      let linha = [];
      linha.push(element.usuario);
      linha.push(element.dataSituacaoF);
      linha.push(element.situacaoPropostaF);
      data.push(linha);
    });

    this.export3 = new DataExport(columns, data);
  }

  getLinkExterno(item: ContratoRepasseVinculado) {
    return this.settingsService.UrlSiconv()
      + '/ConsultarProposta/ResultadoDaConsultaDePropostaDetalharProposta.do?idProposta='
      + item.propFk;
  }

  onDetalharCPS(item: ContratoRepasseSubrogado) {
    // console.log(this.router.url);    
    this.redirectTo('/cps-detalhe/' + item.idCpsDestino);
  }

  redirectTo(uri: any): void {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
    this.router.navigate([uri]));
  }
}
