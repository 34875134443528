import { EventButtonService } from './../../core/services/event-button.service';
import { FiltroRelatorioEgte } from './../../shared/models/filtro-relatorio-egte';
import { RelatorioEgteService, IdpService } from '../../core/services';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertMessageService } from '@serpro/ngx-siconv';
import { RelatorioEgte, Usuario, DataExport } from '../../shared/models';


@Component({
  selector: 'cps-relatorio-egte',
  templateUrl: './relatorio-egte.component.html',
  styleUrls: ['./relatorio-egte.component.scss']
})
export class RelatorioEgteComponent implements OnInit {

  listaEgte: any[] = new Array();
  lista: any[] = null;
  usuario: Usuario = null;
  filtroRelatorioEgte: FiltroRelatorioEgte = null;
  mensagem = 'Não houve ateste de serviços demandados pelo Convenente para o filtro informado.';
  
  _currentPage: number = 1;
  public get currentPage() : number {
    return this._currentPage;
  }
  public set currentPage(v : number) {
    this._currentPage = v;
    localStorage.setItem('relegte_page', this._currentPage.toString());
  }

  export: DataExport;
  fileName: string = 'Relatório EGTE Convenente'
  

  constructor(
    private relatorioEgte: RelatorioEgteService,
    private idpService: IdpService,
    private route: ActivatedRoute,
    private mensageService: AlertMessageService) 
    {
      EventButtonService.get('filtrarRelEgt').subscribe(filtro => {
        if (filtro) {
          this.filtroRelatorioEgte = filtro;           
          this.listarRelatorioEgte();
        }
      });
     }

  ngOnInit() {

    this.mensageService.dismissAll();
    this.usuario = this.idpService.loggedUser;
    this.idpService.retoken();
    // this.listarRelatorioEgte();
    let hasPageQueryString = false;
    this.route.queryParams
      .filter(params => params.page).subscribe(params => {
        hasPageQueryString = true;
        if(localStorage.getItem('relegte_page')) {
          this.currentPage = +localStorage.getItem('relegte_page');
        }
      });
    
      if(!hasPageQueryString){
        this.currentPage = 1;
        localStorage.setItem('relegte_page', '1');
      }
    }

  listarRelatorioEgte() {
    this.relatorioEgte.consultarEgteConvenete(this.filtroRelatorioEgte).subscribe(
      (data: any) => {
        if (data === null) {
          this.listaEgte = new Array();
          //this.mensageService.warn('Não houve ateste de serviços demandados pelo Convenente para o filtro informado.');
        } else {
          this.listaEgte = data.map(
            rel => new RelatorioEgte(rel.egt, rel.cps, rel.orgao,rel.proposta, rel.contrato,
              rel.uf, rel.convenente, rel.programa, rel.uge, rel.ugr,
              rel.fonte,rel.funcional, rel.objeto, rel.repasse, rel.repasseF,
              rel.nivel, rel.dtInicioContratacaoF, rel.dtFimContratacaoF,
              rel.dtAteste, rel.dtAtesteF, rel.statusEgt, rel.valorTarifa, rel.valorTarifaF, rel.responsavelConvenente
              )
          ); 
        }
        this.getExport();
      }
    );
  }

  getListaPaginada(listap){
    this.lista = listap;
  }

  pageChange(page){
    this.currentPage = page;
  }


  getExport(): void {
    let data = []
    const columns = [
      'Egt', 'Número do CPS', 'Órgão Concedente', 'Número da Proposta', 
      'Número SICONV/SIAFI', 'UF', 'Convenente', 'Programa', 'UG Emitente', 'UG Responsável',
      'Fonte', 'Funcional', 'Objeto', 'Repasse (R$)', 'Nível', 'Data de Contratação', 'Data Fim de Vigência', 
      'Data do EGT', 'Status do EGT',  'Valor da Tarifa (R$)'
    ];

   
    this.listaEgte.forEach(element => {
      let linha = [];
      linha.push(element.egt);
      linha.push(element.cps);
      linha.push(element.orgao);
      linha.push(element.proposta);
      linha.push(element.contrato);
      linha.push(element.uf);
      linha.push(element.convenente);
      linha.push(element.programa);
      linha.push(element.uge);
      linha.push(element.ugr);
      linha.push(element.fonte);
      linha.push(element.funcional);
      linha.push(element.objeto);
      linha.push(element.repasseF);
      linha.push(element.nivel);
      linha.push(element.dtInicioContratacaoF);
      linha.push(element.dtFimContratacaoF);
      linha.push(element.dtAtesteF);
      linha.push(element.statusEgt);
      linha.push(element.valorTarifaF);
      //linha.push(element.responsavelConvenente);
      data.push(linha);
    });   

    this.export = new DataExport(columns, data);
  }
}


