import { FileUtil } from './../../../shared/util';
import { EventEmitter, Component, Input, OnInit, OnDestroy, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import * as moment from 'moment';
import 'moment/locale/pt-br';
import { CpsService, IdpService, OrgaoService, 
         EventButtonService } from '../../../core/services';
import { Cps, CpsAnexo, CpsVersaoSituacaoEnum, Orgao, Usuario, TipoPessoaEnum } from '../../../shared/models';
import { CpsCurrencyPipe, ZeroEsquerdaPipe } from '../../../shared/pipes';
import { AlertMessageService } from '@serpro/ngx-siconv';

@Component({
  selector: 'cps-dados-gerais',
  templateUrl: './dados-gerais.component.html',
  styleUrls: ['./dados-gerais.component.scss']
})
export class DadosGeraisComponent implements OnInit, OnDestroy {

  @Input() modoEdicao: Boolean = true;
  @Input() modoAditivacao: Boolean = true;  

  form: FormGroup;
  listaOrgaosConcedentes: any[] = new Array();
  listaMandatarias: any[] = new Array();
  listaBancos: any[] = new Array();
  usuarioLogado: Usuario;
  orgaoUsuarioLogado: Orgao;
  orgaoCps: Orgao;

  idCps: number;
  ehUsuarioMandatariaGestor = false;
  checkAditivadoParaEncerramento = null;
  
  tipoPessoa: number = 1;

  isAnexando: Boolean = false;
  anexos: CpsAnexo[] = new Array();
  anexoAtual: CpsAnexo;
  anexoDescricao: string = '';
  
  
  @Output() setAnexos = new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
    private orgaoService: OrgaoService,
    private cpsService: CpsService,
    private idpService: IdpService,
    private mensageService: AlertMessageService,
    private fileUtil: FileUtil) {
    EventButtonService.get('salvarCps').subscribe(data => { if (data) { this.carregarAnexos(); this.isAnexando = false; } });
    EventButtonService.get('aditivarCps').subscribe(data => { if (data) { this.habilitarAditivacao(); } });
    EventButtonService.get('salvarAnexoCps').subscribe(data => { if (data) { this.carregarAnexos(); this.isAnexando = false; } });
    EventButtonService.get('enviarParaCiencia').subscribe(data => { if (data) { this.carregarAnexos(); this.isAnexando = false; } });
    EventButtonService.get('alerta').subscribe(data => { if (data) { this.mensageService.info('', 'Esta é uma versão anterior do CPS, as datas de vigência, valor de contrato e egts podem diferir da versão mais atual'); } });
  
  }

  ngOnInit() {
    this.isaditivacaoHabilitada = false;
    this.usuarioLogado = this.idpService.loggedUser;
    this.getForm();

    if (this.cpsService.cpsCorrente) {
      this.recuperarCpsCorrente();
      this.carregarAnexos();
      if(this.modoAditivacao){
        this.habilitarAditivacao();
      }      
    }

    this.orgaoUsuarioLogado = this.usuarioLogado.orgao;
    this.carregarCombos();    

    this.form.controls['tipoPessoa'].setValue(this.cpsService.cpsCorrente.cpfCnpjFavorecido.length > 14 ? TipoPessoaEnum.PESSOA_JURIDICA : TipoPessoaEnum.PESSOA_FISICA);
    this.ehUsuarioMandatariaGestor = this.usuarioLogado.isUsuarioMandatariaGestor();

    this.form.valueChanges.subscribe(() => {
      this.atualizarCpsCorrente();
    });
  }

  ngOnDestroy() {
    EventButtonService.unsubscribe('salvarCps');
    EventButtonService.unsubscribe('salvarAnexoCps');
    EventButtonService.unsubscribe('enviarParaCiencia');
    EventButtonService.unsubscribe('alerta');
  }

  carregarAnexos() {
    this.cpsService.listarAnexos(this.cpsService.cpsCorrente.id).subscribe(
      (response: CpsAnexo[]) => {
        this.anexos = new Array();
        if (response) {
          response.map(e => {
            this.anexos.push(e);
          });
        }
        this.setAnexos.emit(this.anexos);
      }
    );
  }

  codigoCtrl: FormControl = new FormControl('', Validators.compose([Validators.required, Validators.maxLength(3)]));
  anoCtrl: FormControl = new FormControl('', Validators.compose([Validators.required, Validators.maxLength(4)]));
  cpsVersaoCtrl: FormControl = new FormControl('', Validators.maxLength(10));
  cpsSituacaoCtrl: FormControl = new FormControl('', Validators.maxLength(10));
  cpsProcessoCtrl: FormControl = new FormControl('', [Validators.maxLength(20), Validators.minLength(20)]);
  cpsNumeroCtrl: FormControl = new FormControl('', Validators.maxLength(10));
  orgaoSuperiorCtrl: FormControl = new FormControl({value: '', disabled: true}, Validators.maxLength(10));
  orgaoConcedenteCtrl: FormControl = new FormControl('', Validators.required);
  orgaoMandatariaCtrl: FormControl = new FormControl('', Validators.maxLength(10));
  dtInicioVigenciaCtrl: FormControl = new FormControl('');
  dtFimVigenciaCtrl: FormControl = new FormControl({value: '', disabled: true}, Validators.maxLength(10));
  dtInicioLegalCtrl: FormControl = new FormControl('');
  dtFimLegalCtrl: FormControl = new FormControl('');
  douSecaoCtrl: FormControl = new FormControl('', Validators.maxLength(10));
  douPaginaCtrl: FormControl = new FormControl('', Validators.maxLength(10));
  douNumeroProcessoCtrl: FormControl = new FormControl('', [Validators.maxLength(20), Validators.minLength(20)]);
  douDtPublicacaoCtrl: FormControl = new FormControl('');
  vlContratoCtrl: FormControl = new FormControl('', Validators.required);
  uasgCtrl: FormControl = new FormControl('', Validators.maxLength(6));
  ptCtrl: FormControl = new FormControl('', Validators.maxLength(17));
  acaoCtrl: FormControl = new FormControl('', Validators.maxLength(8));
  fonteCtrl: FormControl = new FormControl('', Validators.maxLength(10));
  bancoCtrl: FormControl = new FormControl('', Validators.maxLength(10));
  agenciaCtrl: FormControl = new FormControl('', Validators.maxLength(10));
  contaCtrl: FormControl = new FormControl('', Validators.maxLength(15));
  tipoPessoaCtrl: FormControl = new FormControl('', Validators.maxLength(1));
  cpfFavorecidoCtrl: FormControl = new FormControl('', Validators.maxLength(14));
  cnpjFavorecidoCtrl: FormControl = new FormControl('', Validators.maxLength(18));
  nomeFavorecidoCtrl: FormControl = new FormControl('', Validators.maxLength(50));
  nomeAnexoCtrl: FormControl = new FormControl('');

  getForm(): void {
    this.form = this.formBuilder.group({      
      codigo: this.codigoCtrl,     
      ano: this.anoCtrl,
      cpsVersao: this.cpsVersaoCtrl,
      cpsSituacao: this.cpsSituacaoCtrl,
      cpsProcesso: this.cpsProcessoCtrl,
      cpsNumero: this.cpsNumeroCtrl,
      orgaoSuperior: this.orgaoSuperiorCtrl,
      orgaoConcedente: this.orgaoConcedenteCtrl,
      orgaoMandataria: this.orgaoMandatariaCtrl,
      dtInicioVigencia: this.dtInicioVigenciaCtrl,
      dtFimVigencia: this.dtFimVigenciaCtrl,      
      dtInicioLegal: this.dtInicioLegalCtrl,
      dtFimLegal: this.dtFimLegalCtrl,
      douSecao: this.douSecaoCtrl,
      douPagina: this.douPaginaCtrl,
      douNumeroProcesso: this.douNumeroProcessoCtrl,
      douDtPublicacao: this.douDtPublicacaoCtrl,
      vlContrato: this.vlContratoCtrl,
      uasg: this.uasgCtrl,
      pt: this.ptCtrl,
      acao: this.acaoCtrl,
      fonte: this.fonteCtrl,
      banco: this.bancoCtrl,
      agencia: this.agenciaCtrl,
      conta: this.contaCtrl,
      tipoPessoa: this.tipoPessoaCtrl,
      cpfFavorecido: this.cpfFavorecidoCtrl,
      cnpjFavorecido: this.cnpjFavorecidoCtrl,
      nomeFavorecido: this.nomeFavorecidoCtrl,
      nomeAnexo: this.nomeAnexoCtrl
    });
    this.campoBloqueado();
  }

  isaditivacaoHabilitada = false;
  habilitarAditivacao() {    
    if(this.modoEdicao && this.idpService.loggedUser.isConcedente()){      
      if(!this.cpsService.cpsCorrente.versao.id){
        //situacao onde o usuario entrou no modo aditivacao, mas n salvou ainda a versao
        this.form.controls['dtInicioVigencia'].setValue(null);
        // this.form.controls['dtFimVigencia'].setValue(null);
        this.form.controls['dtInicioLegal'].setValue(null);      

      }
      
      this.form.controls['vlContrato'].enable();      
      this.isaditivacaoHabilitada = true;
    }
  }

  isCheckDisabled(){
    if(!this.modoEdicao){
      return true;
    }
    if(!this.idpService.loggedUser.isConcedente()){
      return true;
    }

    if(!this.habilitarAditivacao){
      return true;
    }     
    
    return null;    
  }

  isCalendarDisabled(){
    if(!this.modoEdicao){
      return true;
    }
    if(!this.idpService.loggedUser.isConcedente()){
      return true;
    } 
    if(this.isaditivacaoHabilitada){
      return null;
    }
    if(this.cpsService.cpsCorrente && this.cpsService.cpsCorrente.versao &&             
      (this.cpsService.cpsCorrente.versao.situacao !== CpsVersaoSituacaoEnum.ELABORACAO &&
        this.cpsService.cpsCorrente.versao.situacao !== CpsVersaoSituacaoEnum.EM_ADITIVACAO &&
        this.cpsService.cpsCorrente.versao.situacao !== CpsVersaoSituacaoEnum.ENVIADO_PARA_CIENCIA)){
          return true;
        }
    
    return null;    
  }

  isCalendarDisabled2(){
    if(!this.modoEdicao){
      return true;
    }
    if(!this.idpService.loggedUser.isConcedente()){
      return true;
    } 
    if(!this.cpsService.cpsCorrente || !this.cpsService.cpsCorrente.id || this.cpsService.cpsCorrente.codigo === 1){
      return true;
    }
    if(this.isaditivacaoHabilitada){
      return null;
    }
    if((this.cpsService.cpsCorrente.versao.situacao === CpsVersaoSituacaoEnum.EM_ADITIVACAO
      || this.cpsService.cpsCorrente.versao.situacao === CpsVersaoSituacaoEnum.ENVIADO_PARA_CIENCIA) 
      && this.cpsService.cpsCorrente.codigo > 1){
        return null;
    }
    if(this.cpsService.cpsCorrente && this.cpsService.cpsCorrente.versao &&       
      (this.cpsService.cpsCorrente.versao.situacao !== CpsVersaoSituacaoEnum.EM_ADITIVACAO &&
        this.cpsService.cpsCorrente.versao.situacao !== CpsVersaoSituacaoEnum.ENVIADO_PARA_CIENCIA)){
          return true;
        }
    
    return null;    
  }

  podeAditivarParaEncerramento(){
    return this.isaditivacaoHabilitada || this.cpsService.cpsCorrente.versao.inEncerramento ;
  }

  bloqueio = false;
  campoBloqueado() {
    if (!this.modoAditivacao && !this.usuarioLogado.isMandataria() && this.modoEdicao
      && (this.cpsService.cpsCorrente.versao.situacao !== CpsVersaoSituacaoEnum.ATIVO)) {
      this.form.enable();
      this.form.controls['orgaoSuperior'].disable();
      return null;
    } else {      
      
      Object.keys(this.form.controls).filter(key => {
        return key != 'dtInicioVigencia' 
        && key != 'dtFimVigencia'        
        && key != 'dtInicioLegal'        
        && key != 'dtFimLegal'                
      })
      .forEach(key => {
        // console.log(key)
        this.form.controls[key].disable();
      });
      this.bloqueio = true;
      return true;
    }
  }
      
  idDataPickerDisable(): boolean {
    if (this.modoAditivacao){
      return true;
    }
    return this.bloqueio;
  }

  carregarOrgaoSuperior() {
    if (this.cpsService.cpsCorrente.codigo == null) {
      this.recuperarOrgaoSuperiorUsuario();
    } else {
      this.recuperarOrgaoSuperiorCps();
    }
  }

  async carregarCombos() {
    this.carregarOrgaoSuperior();
    this.carregarMandatarias();
    this.carregarBancos();
  }

  recuperarOrgaoSuperiorCps() {
    let descricao = '';
    this.orgaoService.getOrgao(this.cpsService.cpsCorrente.orgaoSuperior).subscribe(
      (response: Orgao) => {
        this.orgaoCps = response;
        this.carregarOrgaosConcedentes(this.orgaoCps.codigo);
        if (this.orgaoCps) {
          descricao = this.orgaoCps.codigo + ' - ' + this.orgaoCps.nome;
        } else {
          descricao = 'Orgão não identificado';
        }
        this.form.controls['orgaoSuperior'].setValue(descricao);
      }
    );
  }

  recuperarOrgaoSuperiorUsuario() {
    let descricao = '';
    if (this.usuarioLogado.orgao) {
      this.carregarOrgaosConcedentes(this.orgaoUsuarioLogado.codigo);
      if (this.orgaoUsuarioLogado) {
        descricao = this.orgaoUsuarioLogado.codigo + ' - ' + this.orgaoUsuarioLogado.nome;
      } else {
        descricao = 'Orgão não identificado';
      }
      this.form.controls['orgaoSuperior'].setValue(descricao);
    } else {
      this.mensageService.warn('Usuario não associado', 
      'O usuario não está associado a nenhum orgão');
    }
    return this.orgaoUsuarioLogado;
  }

   private carregarOrgaosConcedentes(codigoOrgao: string): void {
    if (codigoOrgao) {
      this.orgaoService.getSubordinados(codigoOrgao).subscribe(
        (response: Orgao[]) => {
          this.listaOrgaosConcedentes = response;
        }
      );
    } else {
      this.mensageService.warn('Usuario não associado', 
      'O usuario não está associado a nenhum orgão');
    }
  }

  private carregarMandatarias(): void {
    this.orgaoService.getMandatarias().subscribe(
      (response: Orgao[]) => {
        this.listaMandatarias = response;
      }
    );
  }

  private carregarBancos(): void {
    this.orgaoService.getMandatarias().subscribe(
      (response: Orgao[]) => {
        this.listaBancos = response;
      }
    );
  }

  private atualizarCpsCorrente() {
    const currencyPipe: CpsCurrencyPipe = new CpsCurrencyPipe();

    if (this.form.valueChanges) {
      const cps: Cps = this.cpsService.cpsCorrente;
      cps.codigo = this.form.controls['codigo'].value;
      cps.ano = this.form.controls['ano'].value;
      cps.processo = this.form.controls['cpsProcesso'].value;
      cps.cps = cps.codigo + '/' + cps.ano;
      if (!cps.orgaoSuperior && this.orgaoUsuarioLogado) {
        cps.orgaoSuperior = this.orgaoUsuarioLogado.id;
      }
      

      cps.orgaoConcedente = this.form.controls['orgaoConcedente'].value === 'selecione' ? null : this.form.controls['orgaoConcedente'].value;
      cps.orgaoMandataria = this.form.controls['orgaoMandataria'].value === 'selecione' ? null : this.form.controls['orgaoMandataria'].value;

      cps.versao.dtInicioVigencia = this.form.controls['dtInicioVigencia'].value;      
      if(!this.modoAditivacao){
        cps.versao.dtFimVigencia = this.calcularFimVigencia(cps.versao.dtInicioVigencia);        
      } else {
        cps.versao.dtFimVigencia = this.form.controls['dtFimVigencia'].value;
      }      
      
      
      cps.versao.dtInicioLegal = this.form.controls['dtInicioLegal'].value;
      cps.versao.dtFimLegal = this.form.controls['dtFimLegal'].value;
      
      cps.douSecao = this.form.controls['douSecao'].value;
      cps.douPagina = this.form.controls['douPagina'].value;
      cps.douNumeroProcesso = this.form.controls['douNumeroProcesso'].value;

      cps.douDtPublicacao = this.form.controls['douDtPublicacao'].value;

      if (this.form.controls['vlContrato'].value != null) {
        cps.versao.valorTotal = currencyPipe.toBackendParse(this.form.controls['vlContrato'].value, 14);
      }

      cps.uasg = this.form.controls['uasg'].value;
      cps.programaTrabalho = this.form.controls['pt'].value;
      cps.acaoOrcamentaria = this.form.controls['acao'].value;
      cps.fonte = this.form.controls['fonte'].value;

      cps.banco = this.form.controls['banco'].value === 'selecione' ? null : this.form.controls['banco'].value;
      cps.agencia = this.form.controls['agencia'].value;
      cps.conta = this.form.controls['conta'].value;

      const tipoPessoa = this.form.controls['tipoPessoa'].value;      
      if (tipoPessoa === 2) {
        cps.cpfCnpjFavorecido = this.form.controls['cnpjFavorecido'].value;
      } else {
        cps.cpfCnpjFavorecido = this.form.controls['cpfFavorecido'].value;
      }
      
      cps.nomeFavorecido = this.form.controls['nomeFavorecido'].value;

      cps.versao.inEncerramento = this.checkAditivadoParaEncerramento ? true : false;

      this.setAnexos.emit(this.anexos);
      this.cpsService.cpsCorrente = cps;
    }
  }

  private recuperarCpsCorrente() {
    const currencyPipe: CpsCurrencyPipe = new CpsCurrencyPipe();
    const zeroEsquerdaPipe: ZeroEsquerdaPipe = new ZeroEsquerdaPipe();

    if (this.cpsService.cpsCorrente.id > 0) {
      const cps: Cps = this.cpsService.cpsCorrente;
      this.idCps = cps.id;      

      this.form.controls['codigo'].setValue(zeroEsquerdaPipe.transform(cps.codigo.toString(), 3));
      this.form.controls['ano'].setValue(cps.ano);
      this.form.controls['cpsProcesso'].setValue(cps.processo);     

      this.form.controls['cpsVersao'].setValue(cps.versao.id);
      this.form.controls['cpsSituacao'].setValue(cps.versao.situacaoF);

      this.carregarOrgaoSuperior();

      this.form.controls['orgaoConcedente'].setValue(cps.orgaoConcedente);
      this.form.controls['orgaoMandataria'].setValue(cps.orgaoMandataria);

      if (cps.versao.dtInicioVigencia) {
        this.form.controls['dtInicioVigencia'].setValue(moment(cps.versao.dtInicioVigencia, 'YYYY-MM-DD').toDate());
      }      

      if (cps.versao.dtFimVigencia) {
        this.form.controls['dtFimVigencia'].setValue(moment(cps.versao.dtFimVigencia, 'YYYY-MM-DD').toDate());
      } 

      if (cps.versao.dtInicioLegal) {
        this.form.controls['dtInicioLegal'].setValue(moment(cps.versao.dtInicioLegal, 'YYYY-MM-DD').toDate());
      } 
      
      if (cps.versao.dtFimLegal) {
        this.form.controls['dtFimLegal'].setValue(moment(cps.versao.dtFimLegal, 'YYYY-MM-DD').toDate());
      } 

      this.form.controls['douSecao'].setValue(cps.douSecao);
      this.form.controls['douPagina'].setValue(cps.douPagina);
      this.form.controls['douNumeroProcesso'].setValue(cps.douNumeroProcesso);
      
      if (cps.douDtPublicacao) {
        this.form.controls['douDtPublicacao'].setValue(moment(cps.douDtPublicacao, 'YYYY-MM-DD').toDate());
      } 

      if (cps.versao.valorTotal != null) {
        // let parsed = currencyPipe.parse(cps.versao.valorTotal+'');
        // let bparsed = currencyPipe.toBackendParse(Number(cps.versao.valorTotal), 14);
        // console.log('valortotal = ' + cps.versao.valorTotal);
        // console.log('parsed = ' + parsed);
        // console.log('bparsed = ' + bparsed);

        let valorF: string = cps.versao.valorTotal ? this.getValorFormatado(cps.versao.valorTotal) : '0,0';
        console.log('valortotal formatado = ' + valorF);

        this.form.controls['vlContrato'].setValue(valorF);
      }

      this.form.controls['uasg'].setValue(cps.uasg);
      this.form.controls['pt'].setValue(cps.programaTrabalho);
      this.form.controls['acao'].setValue(cps.acaoOrcamentaria);
      this.form.controls['fonte'].setValue(cps.fonte);

      this.form.controls['banco'].setValue(cps.banco);
      this.form.controls['agencia'].setValue(cps.agencia);
      this.form.controls['conta'].setValue(cps.conta);

      if (cps.cpfCnpjFavorecido != "" && cps.cpfCnpjFavorecido.length > 14) {
        this.form.controls['tipoPessoa'].setValue(2);
        this.form.controls['cnpjFavorecido'].setValue(cps.cpfCnpjFavorecido);
      } else {
        this.form.controls['tipoPessoa'].setValue(1);
        this.form.controls['cpfFavorecido'].setValue(cps.cpfCnpjFavorecido);
      }      
      
      this.form.controls['nomeFavorecido'].setValue(cps.nomeFavorecido);   
      this.checkAditivadoParaEncerramento = cps.versao.inEncerramento;   
    } else {
      this.checkAditivadoParaEncerramento = false;   
    }
  }

  getValorFormatado(valor: number) {
    const currencyPipe = new CpsCurrencyPipe();
    const vl_fixed = Number(valor.toFixed(2));
    let valorFormatado: string = (valor === 0 ? '0,00'
      : currencyPipe.transform((100 * vl_fixed).toFixed(2).toString()));
    return valorFormatado;
  }

  getCpfCnpjLabel(): string {
    const tipoPessoa = this.form.controls['tipoPessoa'].value;
    if (tipoPessoa === 2) {
      return 'CNPJ do Favorecido';
    } else {
      return 'CPF do Favorecido';
    }
  }

  selectDateChange(data) {
    // this.mensageService.dismissAll();
    const cps: Cps = this.cpsService.cpsCorrente;

    if(this.form.controls['dtInicioVigencia'].invalid){
      //this.mensageService.dismissAll();
      this.mensageService.error('', 'A data de início de vigência do contrato informada é inválida.');
      cps.versao.dtInicioVigencia = this.form.controls['dtInicioVigencia'].value;    
    } else {
      //this.mensageService.dismissAll();
      cps.versao.dtInicioVigencia = this.form.controls['dtInicioVigencia'].value ? this.form.controls['dtInicioVigencia'].value : null;
    }

    if(this.form.controls['dtInicioLegal'].invalid){
      //this.mensageService.dismissAll();
      this.mensageService.error('', 'A data de início de vigência efetiva informada é inválida.');
      cps.versao.dtInicioLegal = this.form.controls['dtInicioLegal'].value;    
    } else {
      //this.mensageService.dismissAll();
      cps.versao.dtInicioLegal = this.form.controls['dtInicioLegal'].value ? this.form.controls['dtInicioLegal'].value : null;      
    }

    
    if (cps.versao.dtInicioVigencia) {
      if(!this.modoAditivacao){
        cps.versao.dtFimVigencia = this.calcularFimVigencia(cps.versao.dtInicioVigencia);
        if (cps.versao.dtFimVigencia) {
          this.form.controls['dtFimVigencia'].setValue(moment(cps.versao.dtFimVigencia, 'YYYY-MM-DD').toDate());
        }
      } else {
        cps.versao.dtFimVigencia = this.form.controls['dtFimVigencia'].value;
      }      
    }    
    // cps.versao.inEncerramento = this.checkAditivadoParaEncerramento ? true : false;
    this.cpsService.cpsCorrente = cps;
  }

  calcularFimVigencia(data: Date): Date {
    let dtInicio: Date = null;
    dtInicio = moment(data, 'YYYY-MM-DD').toDate();    
    if (dtInicio != null && dtInicio != undefined && dtInicio.toDateString() !== 'Invalid Date') {
      const dtFim = moment(new Date(dtInicio.getFullYear(), dtInicio.getMonth(), dtInicio.getDate()));      
      dtFim.add(3, 'years');
      dtFim.subtract(1, 'day');
      return dtFim.toDate();
    } else {
      return null;
    }
  }

  selectInEncerramento(event: any) {
    const target = event.target || event.srcElement;
    if (target && target.checked === true) {
      this.checkAditivadoParaEncerramento = true; 

    } else {
      this.checkAditivadoParaEncerramento = false;
    }
    
    let cps = this.cpsService.cpsCorrente;
    cps.versao.inEncerramento = this.checkAditivadoParaEncerramento;
    this.cpsService.cpsCorrente = cps;
    
  }


  addAnexo() {
    this.isAnexando = true;
  }

  confirmarAnexo() {
    if (!this.anexoAtual) {
      this.mensageService.error('', 'Nenhum arquivo selecionado');
    } else if (!this.anexoDescricao) {
      this.mensageService.error('', 'A descrição do arquivo é obrigatória');
    } else {
      this.anexoAtual.txDescricao = this.anexoDescricao;      
      this.anexos.push(this.anexoAtual);
      this.setAnexos.emit(this.anexos);
      this.isAnexando = false;
      this.anexoDescricao = '';
      this.nomeAnexoCtrl.setValue('');
      this.anexoAtual = null;
    }
  }

  fileUpload(event, descricao) {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (this.isArquivoValido(file)) {
        const anexo = new CpsAnexo();
        anexo.cpsVersaoFk = this.cpsService.cpsCorrente.versao.id;
        const readerResult: any = reader.result;
        anexo.arquivo = readerResult.split(',')[1];
        anexo.txDescricao = descricao;
        anexo.nome = file.name;
        anexo.tamanho = file.size;
        anexo.situacao = 'N';
        this.anexoAtual = anexo;
      }
    };
    const [file] = event.target.files;
    reader.readAsDataURL(file);
  }

  isArquivoValido(file: File): boolean {
    const tiposValidos: string[] = new Array(
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'image/jpeg',
      'image/png',
      'application/vnd.oasis.opendocument.text',
      'application/vnd.oasis.opendocument.spreadsheet');
    let isValido = true;
    if (file.size / 1000000 > 25) {
      isValido = false;
      this.mensageService.error('', 'O tamanho máximo do arquivo é 25MB');
    } else if (tiposValidos.indexOf(file.type) < 0) {
      isValido = false;
      this.mensageService.error('', 
      'Formatos permitidos: PDF, DOC, DOCX, XLS, XLSX, JPG, JPEG, PNG, ODT e ODS.');
    }
    return isValido;
  }

  anexoDownload(anexo) {
    if (anexo.id) {
      this.cpsService.baixarAnexo(this.cpsService.cpsCorrente.versao.id, anexo.id).subscribe(
        (response: CpsAnexo[]) => {
          response.map(e => {
            this.fileUtil.downloadFile(e.arquivo, e.nome);
          });
        }
      );
    } else {
      this.fileUtil.downloadFile(anexo.arquivo, anexo.nome);
    }
  }

  removerAnexo(anexo: CpsAnexo) {
    anexo.situacao = 'E';
    anexo.arquivo = '';
    this.setAnexos.emit(this.anexos);
  }

  getTipoPessoa (){
    return this.form.controls['tipoPessoa'].value;
  }

  podeExcluirAnexar(item: CpsAnexo){
    return (item.cpsVersaoFk === this.cpsService.cpsCorrente.versao.id) 
      && this.podeAnexar();
  }

  podeAnexar(): boolean {    
    if (!this.cpsService.cpsCorrente.orgaoSuperior) {
      return true;
    }
    return this.orgaoUsuarioLogado && (
      (
        (
          (this.cpsService.cpsCorrente.versao.situacao !== CpsVersaoSituacaoEnum.ATIVO)
          || (this.modoAditivacao && this.cpsService.cpsCorrente.versao.situacao === CpsVersaoSituacaoEnum.ATIVO)
        )
        && (this.orgaoUsuarioLogado.id === this.cpsService.cpsCorrente.orgaoConcedente
          || this.orgaoUsuarioLogado.id === this.cpsService.cpsCorrente.orgaoSuperior) && (this.modoEdicao === true)
      )
    );
  }

}
