import { Component, Compiler } from '@angular/core';
@Component({
  selector: 'cps-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private _compiler: Compiler) {
    this._compiler.clearCache();
  }
}
