import { FiltroCps } from '../../../shared/models/filtro-cps';
import { Component, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { filter } from 'rxjs/operators';
import * as moment from 'moment';
import 'moment/locale/pt-br';
import { CpsService, EventButtonService, OrgaoService } from '../../../core/services';
import { CpsVersaoSituacaoEnum, CpsVersaoSituacaoEnumPipe, Orgao } from '../../../shared/models';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'cps-listagem-filtro',
  templateUrl: './filtro.component.html',
  styleUrls: ['./filtro.component.scss']
})
export class FiltroCpsComponent implements OnInit, OnDestroy {
  
  @Input() orgaoUsuarioLogado = null; 
  @Output() filtroCps: FiltroCps = null;
  form: FormGroup;

  componentName = 'FiltroCpsComponent'
  pageSize = 10;
  currentPage: number = 1;  

  //filtro de pesquisa
  listaSituacao: any[] = new Array();
  listaOrgaosConcedentes: any[] = new Array();

  orgaoConcedenteCtrl = new FormControl('', Validators.required);
  situacaoCtrl = new FormControl('');
  dataInicialVigenciaCtrl = new FormControl('');
  dataFinalVigenciaCtrl = new FormControl('');

  constructor(
    private formBuilder: FormBuilder,
    private cpsService: CpsService,
    private router: Router,

  ) {
    router.events.pipe(filter(event => event instanceof NavigationEnd))
    .subscribe((event: any) => {      
      if (event.url.toString().includes('cps') === false) {
        localStorage.removeItem(this.componentName);
      } 
    });

  }

  ngOnInit() {
    this.buildForm();
    this.carregarCombosFiltro();
    this.onFiltrar();
  }

  

  buildForm(): void {
    const oldForm = localStorage.getItem(this.componentName);
    if(oldForm) {
      const row = JSON.parse(oldForm);
      this.form = this.formBuilder.group({
        situacao: [row.situacao],
        orgaoConcedente: [row.orgaoConcedente],
        dataInicialVigencia: [row.dataInicialVigencia],
        dataFinalVigencia: [row.dataFinalVigencia]
      });
      this.currentPage = row.currentPage;
      this.pageSize = row.pageSize;
    } else {
      this.form = this.formBuilder.group({
        situacao: this.situacaoCtrl,
        orgaoConcedente: this.orgaoConcedenteCtrl,
        dataInicialVigencia: this.dataInicialVigenciaCtrl,
        dataFinalVigencia: this.dataFinalVigenciaCtrl     
      });
      this.currentPage = 1;
      this.pageSize = 10;
    }
  }



  getFromFiltro(form: FormGroup): FiltroCps {
    let filtro: FiltroCps = new FiltroCps();
    
    filtro.orgaoConcedente = form.controls['orgaoConcedente'].value ? form.controls['orgaoConcedente'].value : null;    
    filtro.situacao = form.controls['situacao'].value ? form.controls['situacao'].value : null;            
    filtro.dataInicialVigencia = form.controls['dataInicialVigencia'].value? (moment(form.controls['dataInicialVigencia'].value, 'YYYY-MM-DD').format('YYYY-MM-DD')): null;
    filtro.dataFinalVigencia = form.controls['dataFinalVigencia'].value ? (moment(form.controls['dataFinalVigencia'].value, 'YYYY-MM-DD').format('YYYY-MM-DD')) : null;
    return filtro;
  }


  private carregarListaOrgaosConcedentes(){
    if (this.orgaoUsuarioLogado) {
      this.cpsService.getOrgaosConcedentesFiltro(this.orgaoUsuarioLogado).subscribe(
          (response: Orgao[]) => {
            this.listaOrgaosConcedentes = response;
          }
        );
      // this.orgaoService.getSubordinados(this.codigoOrgaoUsuarioLogado).subscribe(
      //   (response: Orgao[]) => {
      //     this.listaOrgaosConcedentes = response;
      //   }
      // );
    }
  }

  private carregarComboSituacao() {
    this.listaSituacao = new Array();
    this.listaSituacao.push(this.situacao(CpsVersaoSituacaoEnum.ADITIVADO_PARA_ENCERRAMENTO));
    this.listaSituacao.push(this.situacao(CpsVersaoSituacaoEnum.ADITIVADO_POR_EXCLUSAO_DE_UGS));
    this.listaSituacao.push(this.situacao(CpsVersaoSituacaoEnum.ATIVO));
    this.listaSituacao.push(this.situacao(CpsVersaoSituacaoEnum.ELABORACAO));
    this.listaSituacao.push(this.situacao(CpsVersaoSituacaoEnum.EM_ADITIVACAO));
    this.listaSituacao.push(this.situacao(CpsVersaoSituacaoEnum.ENCERRADO));
    this.listaSituacao.push(this.situacao(CpsVersaoSituacaoEnum.ENCERRADO_POR_ADITIVO));
    this.listaSituacao.push(this.situacao(CpsVersaoSituacaoEnum.ENVIADO_PARA_CIENCIA));
    this.listaSituacao.push(this.situacao(CpsVersaoSituacaoEnum.SUSPENSO));
    this.listaSituacao.push(this.situacao(CpsVersaoSituacaoEnum.SUSPENSO_POR_ADITIVO));
  }

  situacao(value: CpsVersaoSituacaoEnum){
    return {id: value, descricao: new CpsVersaoSituacaoEnumPipe().transform(value)}
  }
 
  carregarCombosFiltro(){
    
    this.carregarComboSituacao();
    this.carregarListaOrgaosConcedentes();
  }

  onFiltrar() {
    this.filtroCps = this.getFromFiltro(this.form);    
    EventButtonService.get('filtrarCps').emit(this.filtroCps);
  }  


  ngOnDestroy() {
    EventButtonService.unsubscribe('filtrarCps');
    const form = { 
      situacao: this.form.controls['situacao'].value, 
      orgaoConcedente: this.form.controls['orgaoConcedente'].value, 
      dataInicialVigencia: this.form.controls['dataInicialVigencia'].value, 
      dataFinalVigencia: this.form.controls['dataFinalVigencia'].value
    };
    const pag = {
      currentPage: this.currentPage, 
      pageSize: this.pageSize
    };
    const comp = Object.assign(form, pag);
    localStorage.setItem(this.componentName, JSON.stringify(comp));
  
  }
}
