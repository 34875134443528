export class ItemCobranca {
  constructor(
    public id: number,
    public egtFk: number,
    public egtTipo: number,
    public egtCodigo: string,
    public egtDescricao: string,
    public documentoCobrancaFk: number,
    public referenciaPrecoFk: number,
    public propostaF: string,
    public contratoF: string,
    public programaTrabalho: string,
    public programa: string,
    public uf: string,
    public convenente: string,
    public convenenteF: string,
    public sgSecretaria: string,
    public fonte: string,
    public funcional: string,
    public objeto: string,
    public valorRepasse: number,
    public valorRepasseF: string,
    public dtInicioImrF: string,
    public dtFimImrF: string,
    public statusEgt: string,
    public nivel: string,
    public dtInicioContratacaoF: string,
    public dtFimContratacaoF: string,
    public valorTarifa: number,
    public valorTarifaF: string,
    public empenho: string,
    public ugEmitente: string,
    public ugResponsavel: string,
    public cpfResponsavelConcedente: string,
    public nomeResponsavelConcedente: string,
    public propFk: string,
    public tabelaReferencia: string

  ) { }
}

