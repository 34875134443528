import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ActivatedRoute, UrlSegment, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TabsetComponent, BsModalService, BsModalRef, BsDatepickerConfig } from 'ngx-bootstrap';
import { Cps, CpsAnexo, CpsVersaoSituacaoEnum, ContratoRepasseVinculado, 
         Orgao, Usuario } from '../../shared/models';
import { CpsService, IdpService, 
         OrgaoService, EventButtonService, DetalhamentoContratoService, MotivoEncerramentoService } from '../../core/services';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AlertMessageService } from '@serpro/ngx-siconv';
import * as moment from 'moment';

@Component({
  selector: 'cps-main',
  templateUrl: './cps.component.html',
  styleUrls: ['./cps.component.scss']
})
export class CpsComponent implements OnInit {
  @ViewChild('staticTabs') staticTabs: TabsetComponent;

  modalRef: BsModalRef;
  modalMessage = '';
  funcao: any = null;
  bsConfig: Partial<BsDatepickerConfig>;
  cpsId = null;
  cpsVersaoId = null;
  cps: Cps = null;
  situacaoCps = 0;
  modoEdicao = true;
  //modo aditivacao eh ativado quando salva o aditivo pela 1a vez.
  modoAditivacao = true;  
  orgaoUsuarioLogado: Orgao;
  usuario: Usuario = null;
  ehUsuarioMandatariaGestor = false;
  anexos: CpsAnexo[] = new Array();
  nomeOrgaoConcedente = '';
  codigoOrgaoConcedente = '';
  form: FormGroup;
  modoEncerramento = false;
  listaMotivosEncerramento: any[] = new Array();
  contratosRepasse: ContratoRepasseVinculado[] = new Array();
  pageConsulta = 1;

  justificativaCtrl: FormControl = new FormControl('', Validators.compose([Validators.required, Validators.maxLength(2000)]));
  motivoCtrl: FormControl = new FormControl('', Validators.required);
  dtInicioLegalCtrl: FormControl = new FormControl('');
  dtFimLegalCtrl: FormControl = new FormControl('');
  orgaoCtrl: FormControl = new FormControl('');

  podeEncerrar: boolean = false;
  
  constructor(
    private formBuilder: FormBuilder,
    private cpsService: CpsService,
    private orgaoService: OrgaoService,
    private router: Router,
    private route: ActivatedRoute,
    private idpService: IdpService,
    private serviceMotivoEncerramento: MotivoEncerramentoService,
    
    private mensageService: AlertMessageService,
    private modalService: BsModalService,
    private detalhamentoContratoService: DetalhamentoContratoService
  ) {
    route.paramMap.subscribe(
      params => this.cpsId = params.get('id')
    );   
    // ativa o modo de edição se a rota de origem não for cps-detalhe
    const url: Observable<UrlSegment[]> = route.url.map(segments => segments);
    url.subscribe(value => { 
      this.modoEdicao = value[0].path !== 'cps-detalhe'; 
      //caso venha do detalhamento de contratos, eh um detalhe de versao anterior
      if(value[0].path === 'cps-detalhe' && value[2] && value[2].path === 'versao'){
        this.cpsVersaoId = value[3].path;               
      }

      this.modoAditivacao = false;
    });    
  }

  async ngOnInit() {
    this.limpaMensagem();
    this.idpService.retoken();    
    this.serviceMotivoEncerramento.get().subscribe((response) => {
      this.listaMotivosEncerramento = response;
    });
    this.getForm();   
    if(localStorage.getItem('cps_page')) {
      this.pageConsulta = +localStorage.getItem('cps_page');
    }else{
      this.pageConsulta = 1;
    }

    this.usuario = this.idpService.loggedUser;
    this.ehUsuarioMandatariaGestor = this.usuario.isUsuarioMandatariaGestor();
    this.orgaoUsuarioLogado = this.usuario.orgao;

    if (this.cpsId || this.cpsVersaoId) {
      await this.recuperarCps(this.cpsId, this.cpsVersaoId);          
    } else {
      this.cpsService.removerCpsCorrente();
      this.cps = new Cps();
      this.cpsService.cpsCorrente = this.cps;
      this.situacaoCps = CpsVersaoSituacaoEnum.ELABORACAO;
    }         

    if(this.cpsVersaoId){
      // EventButtonService.get('alerta').emit(true);  
      this.limpaMensagem();
      this.mensageService.info('', 'Esta é uma versão anterior do CPS, as datas de vigência, valor de contrato e egts podem diferir da versão mais atual');
    }
  }

  getForm(): void {
    this.form = this.formBuilder.group({
      motivo: this.motivoCtrl,
      justificativa: this.justificativaCtrl,      
      dtInicioLegal: this.dtInicioLegalCtrl,
      dtFimLegal: this.dtFimLegalCtrl
    });
  }

  onPaste(e: any) {
    e.preventDefault();
  }

  limpaMensagem(){
    this.mensageService.dismissAll();
  }

  isVisivelDocumentoCobranca() {
    return (this.situacaoCps >= CpsVersaoSituacaoEnum.ATIVO && !this.cpsVersaoId) || this.modoAditivacao ;
  }

  isVisivelDetalhamento() {
    return (this.situacaoCps != CpsVersaoSituacaoEnum.ELABORACAO) && !this.cpsVersaoId;
  }

  async recuperarCps(id, versaoId? ) {
    await this.cpsService.getCps(id, versaoId).subscribe((response) => {
      this.cpsService.cpsCorrente = response;
      this.cps = this.cpsService.cpsCorrente;
      this.cpsId = this.cps.id;
      this.situacaoCps = this.cpsService.cpsCorrente.versao.situacao;
      this.modoAditivacao = this.cpsService.cpsCorrente.versao.codigo > 1 && (this.situacaoCps === CpsVersaoSituacaoEnum.EM_ADITIVACAO || this.situacaoCps === CpsVersaoSituacaoEnum.ENVIADO_PARA_CIENCIA);
      this.obterOrgaoCps(this.cps.orgaoConcedente);    
      this.showEncerrar();      
      EventButtonService.get('salvarCps').emit(true);   
      
      //this.limpaMensagem();      
    });
  }

  obterOrgaoCps(orgaoId) {
    if (orgaoId) {
      this.orgaoService.getOrgao(orgaoId).subscribe(
        (response: Orgao) => {
          this.nomeOrgaoConcedente = response.nome;
          this.codigoOrgaoConcedente = response.codigo;
        }
      );
    }
  }
  
  existeAnexo(anexos: CpsAnexo[]): boolean {
    if (anexos) {
      const anexosfilter = anexos.filter(a => a.situacao !== 'E');
      return anexosfilter.length > 0;
    } else {
      return false;
    }
  }

  public onEnviarParaCiencia(template) {
    this.limpaMensagem();
    let msg = '';
    if (this.existeAnexo(this.anexos)) {
      msg = 'Confirmar o envio do CPS ' + this.cpsService.cpsCorrente.cps + ' para ciência?';
    } else {
      msg = 'Não existem arquivos anexados ao CPS. Confirmar o envio do CPS ' + this.cpsService.cpsCorrente.cps + ' para ciência?';
    }
    this.showConfirm(template, msg, this.enviarParaCiencia);
  }

  async enviarParaCiencia() {
    // Salva ANTES de ENVIAR
    if (this.validarCPS(this.cpsService.cpsCorrente)) {
      await this.cpsService.atualizarCps(this.anexos).subscribe(
        (data: Cps) => {
          this.recuperarCps(data);
          this.enviar();
        }
      );
    }
  }

  async enviar() {
    await this.cpsService.enviar(this.anexos).subscribe(
      (data) => {
        this.recuperarCps(this.cpsId);       
        this.modoAditivacao = false;         
        this.mensageService.success('CPS enviado', 'CPS enviado para ciência com sucesso');
      }
    );
    EventButtonService.get('enviarParaCiencia').emit(true);
  }

  public onRegistrarCiencia(template) {    
    const msg = 'Confirmar registro da ciência do CPS ' + this.cpsService.cpsCorrente.cps + '?';
    this.showConfirm(template, msg, this.registrarCiencia);
  }

  async registrarCiencia(cps: Cps) {
    await this.cpsService.ativar().subscribe(
      (data) => {
        this.recuperarCps(this.cpsId);
        this.modoAditivacao = false;
        EventButtonService.get('registrarCiencia').emit(true);        
        this.mensageService.success('Registro realizado', 'Registro da ciência realizado com sucesso');
      }
    );
  }

  public showAditivarCPS() {
    this.podeEncerrar = false;        
    this.modoAditivacao = true;
    this.cps.versao.codigoF = null;
    this.cps.versao.situacaoF = null;    
    this.situacaoCps = CpsVersaoSituacaoEnum.EM_ADITIVACAO;                  
        
    let cps = this.cpsService.cpsCorrente
    cps.versao.id = null;
    this.cpsService.cpsCorrente = cps;
      
    EventButtonService.get('aditivarCps').emit(true);
    this.limpaMensagem();
    
  }

  public onAditivarCPS(template) {
    this.limpaMensagem();

    if(!this.cpsService.cpsCorrente.versao.id){
      const msg = 'Aditivar irá suspender o CPS vigente. Confirmar aditivação do CPS ' + this.cpsService.cpsCorrente.cps + '?';
      this.showConfirm(template, msg, this.aditivar);        
    } else {
      this.aditivar();
    }

  }

  public onSalvarCPS() {
    this.limpaMensagem();
    this.alterar();
  }

  async alterar() {
    if (this.validarCPS(this.cpsService.cpsCorrente)) {
      await this.cpsService.atualizarCps(this.anexos).subscribe(
        (data: Cps) => {
          this.recuperarCps(data);
          if (data) {            
            this.mensageService.success('CPS salvo', 'CPS salvo com sucesso');
          }
        }
      );
      EventButtonService.get('salvarCps').emit(true);
    }
  }

  async aditivar() {    
    if (this.validarCPS(this.cpsService.cpsCorrente)) {
      await this.cpsService.aditivar(this.anexos).subscribe(
        (data) => {
          this.recuperarCps(this.cpsId);
          if (data) {            
            this.mensageService.success('Aditivo de CPS salvo', 'Aditivo de CPS salvo com sucesso');
          }
        }
      );
      EventButtonService.get('salvarCps').emit(true);
    }
  }

  public onSalvarAnexoVersaoCPS() {
    this.limpaMensagem();
    if (this.validarCPS(this.cpsService.cpsCorrente)) {
      this.anexar();
    }
  }

  async anexar() {
    await this.cpsService.salvarAnexo(this.anexos).subscribe(
      (data: Cps) => {
        this.recuperarCps(data);
        EventButtonService.get('salvarAnexoCps').emit(true);
        if (data) {          
          this.mensageService.success('CPS salvo', 'CPS salvo com sucesso');
        }
      }
    );
  }

  public onExibeEncerramento(template) {
    this.modoEncerramento = !this.modoEncerramento;    
    if (this.cpsService.cpsCorrente.versao.dtInicioLegal) {
      this.form.controls['dtInicioLegal'].setValue(moment(this.cpsService.cpsCorrente.versao.dtInicioLegal).format('DD/MM/YYYY'));
    } else {
      this.form.controls['dtInicioLegal'].setValue(null);
    }           
  }

  public onEncerrar(template) {
    let hasError = false;
    this.limpaMensagem();
    let erros = [];
    if (!this.form.controls['motivo'].value) {
      hasError = true;
      erros.push('O Motivo é obrigatório.');
    }   

    if (!this.form.controls['justificativa'].value) {
      hasError = true;
      erros.push('A Justificativa é obrigatória.');
    }    

    if (!this.form.controls['dtFimLegal'].value) {
      hasError = true;
      erros.push('A data de Término Efetivo é obrigatória.');
    }
    
    if (hasError === false) {
      let msg = '';
      this.detalhamentoContratoService
      .consultarContratosRepasseVinculados(this.cpsService.cpsCorrente.id)
      .subscribe((response: any) => {
        if (response) {
          this.contratosRepasse = response;          
        }
        if (this.contratosRepasse.length === 0) {
          msg = 'Confirmar o encerramento do CPS ' + this.cpsService.cpsCorrente.cps + '?';
        } else {
          msg = 'Existem contratos de Repasse vinculados ao CPS ' + this.cpsService.cpsCorrente.cps + ', confirma o encerramento do CPS?';
        }      
        this.showConfirm(template, msg, this.encerrarCps);
      });                  
    } else {      
      this.mensageService.error('Campo Obrigatório', '', erros);
    } 
  }

  async encerrarCps() {      
    const cpsCorrente = this.cpsService.cpsCorrente;
    cpsCorrente.versao.dtFimLegal = this.form.controls['dtFimLegal'].value;
    this.cpsService.cpsCorrente = cpsCorrente;

    await this.cpsService.encerrar(this.form.controls['motivo'].value, this.form.controls['justificativa'].value).subscribe(
      (data) => {
        this.recuperarCps(this.cpsId);
        EventButtonService.get('encerrarCps').emit(true);        
        this.mensageService.success('', 'CPS encerrado com sucesso');
        this.modoEncerramento = false;
      }
    );
  }  

  private validarCPS(cps: Cps): Boolean {
    if (!cps.codigo || !cps.ano) {
      this.mensageService.error('', 'O Número do CPS é obrigatório');
      return false;
    }
    if (!cps.orgaoConcedente) {
      this.mensageService.error('', 'O Órgão Concedente é obrigatório.');
      return false;
    }
    if (this.validarDatas(cps) == false) {
      return false;
    }    
    return this.validarAnexos(this.anexos);
  }

  validarAnexos(listaAnexos: CpsAnexo[]): boolean {
    let retorno = true;
    let tamanhoAnexos = 0;
    listaAnexos.forEach(anexo => {
      if ((anexo.tamanho) && (anexo.situacao === 'N')) {
        tamanhoAnexos += anexo.tamanho;
      }
    });
    if (tamanhoAnexos / 1000000 > 25) {
      this.mensageService.error('', 
      'O somatório do tamanho dos arquivos anexados excede o limite de 25MB');
      retorno = false;
    }
    return retorno;
  }

  podeRegistrar(): boolean {
    return this.cpsService.cpsCorrente.id > 0 && this.usuario.isMandataria()
      && this.ehUsuarioMandatariaGestor
      && this.cpsService.cpsCorrente.versao.situacao === CpsVersaoSituacaoEnum.ENVIADO_PARA_CIENCIA
      && this.modoEdicao;
  }

  podeSalvar(): boolean {
    return !this.modoAditivacao && (
      this.usuario.isConcedente()
      && (this.cpsService.cpsCorrente.versao.situacao === CpsVersaoSituacaoEnum.ELABORACAO
        || this.cpsService.cpsCorrente.versao.situacao === CpsVersaoSituacaoEnum.ENVIADO_PARA_CIENCIA)
      && this.modoEdicao
    );
  }

  podeSalvarAditivo(): boolean {    
    return this.modoAditivacao && (
      this.usuario.isConcedente()
      && ( this.cpsService.cpsCorrente.versao.id == null 
        || this.cpsService.cpsCorrente.versao.situacao === CpsVersaoSituacaoEnum.EM_ADITIVACAO
        || this.cpsService.cpsCorrente.versao.situacao === CpsVersaoSituacaoEnum.ENVIADO_PARA_CIENCIA)
      && this.modoEdicao
    );
  }

  podeAnexar(): boolean {
    return this.cpsService.cpsCorrente.versao.situacao === CpsVersaoSituacaoEnum.ATIVO && this.ehUsuarioMandatariaGestor
      ||
      (this.cpsService.cpsCorrente.versao.situacao === CpsVersaoSituacaoEnum.ATIVO
        && this.usuario.isConcedente()
        && (this.orgaoUsuarioLogado.id === this.cpsService.cpsCorrente.orgaoConcedente
          || this.orgaoUsuarioLogado.id === this.cpsService.cpsCorrente.orgaoSuperior));
  }

  podeEnviar(): boolean {
    return (
      this.cpsService.cpsCorrente.id > 0 && this.usuario.isConcedente()
      && (this.cpsService.cpsCorrente.versao.situacao === CpsVersaoSituacaoEnum.ELABORACAO
        || this.cpsService.cpsCorrente.versao.situacao === CpsVersaoSituacaoEnum.EM_ADITIVACAO)
      && this.modoEdicao
    );
  }

  podeCancelar(){
    return (
      this.cpsService.cpsCorrente.id > 0 && this.usuario.isConcedente()
      && this.cpsService.cpsCorrente.versao.situacao === CpsVersaoSituacaoEnum.EM_ADITIVACAO
      && this.modoEdicao 
    );
  }

  onCancelarAditivacaoCPS(template){
    this.limpaMensagem(); 
    let msg = 'Deseja Cancelar a aditivação do CPS ' + this.cpsService.cpsCorrente.cps + ' ?';
    
    this.showConfirm(template, msg, this.deletarAditivoCps);
  }

  deletarAditivoCps(){
    this.cpsService.deletarAditivoCps(this.cpsService.cpsCorrente.id).subscribe(
      (data) => {          
        this.limpaMensagem();     
        this.mensageService.success('Aditivo de CPS excluído.', 'Aditivo de CPS excluído com sucesso.');
        this.router.navigate(['/cps-lista/' ]);         
      }
    );  
  }

  calcular6anosVigencia(dtInicio: Date): Date {
    // let dtInicio: Date = null;
    // dtInicio = moment(data, 'YYYY-MM-DD').toDate();    
    if (dtInicio != null && dtInicio != undefined) {
      const dtFim = moment(new Date(dtInicio.getFullYear(), dtInicio.getMonth(), dtInicio.getDate()));      
      dtFim.add(6, 'years');
      dtFim.subtract(1, 'day');
      return dtFim.toDate();
    } else {
      return null;
    }
  }


  isPeriodo6anosValido(): boolean{

    if(!this.cpsService.cpsCorrente.dtPrimeiraAtivacao){
      return true;
    }
    let dt6anosVigente = this.calcular6anosVigencia(this.cpsService.cpsCorrente.dtPrimeiraAtivacao);
    return moment(this.cpsService.cpsCorrente.versao.dtFimVigencia).isSameOrBefore(dt6anosVigente, 'day');
  }

  podeAditivar(): boolean {    
    //apenas enquanto o modo aditivacao nao estiver ativo, exibir o botao de Aditivar
    return !this.modoAditivacao && (
      this.cpsService.cpsCorrente.id > 0 && this.usuario.isConcedente()
      && this.cpsService.cpsCorrente.versao.situacao === CpsVersaoSituacaoEnum.ATIVO
      && this.modoEdicao //&& this.isPeriodo6anosValido()
    );
  }


  showEncerrar() {   
    if(this.cpsVersaoId)  {
      this.podeEncerrar = false;
      return;
    }

    this.podeEncerrar = (
      this.cpsService.cpsCorrente.id > 0 && this.usuario.isConcedente()
      && (this.orgaoUsuarioLogado.id === this.cpsService.cpsCorrente.orgaoConcedente
        || this.orgaoUsuarioLogado.id === this.cpsService.cpsCorrente.orgaoSuperior)
      && (this.cpsService.cpsCorrente.versao.situacao === CpsVersaoSituacaoEnum.ATIVO 
        || this.cpsService.cpsCorrente.versao.situacao === CpsVersaoSituacaoEnum.ADITIVADO_PARA_ENCERRAMENTO
        || this.cpsService.cpsCorrente.versao.situacao === CpsVersaoSituacaoEnum.SUSPENSO_POR_ADITIVO)
    );
     
  }

  setAnexos(event: any) {
    this.anexos = event;
  }

  showConfirm(template: TemplateRef<any>, msg: string, funcao) {
    this.modalMessage = msg;
    this.funcao = funcao;
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
  }

  confirm(): void {
    if (this.funcao != null) {
      if (typeof (this.funcao) === 'function') {
        this.funcao.call(this);
      }
    }
    this.modalRef.hide();
  }

  decline(): void {
    this.modalRef.hide();
  } 

  versaoAtual() {
    this.limpaMensagem;
    this.router.navigate(['/cps-detalhe/' + this.cpsId]);         
  }

  validarDatas(cps){
     
    let retorno = true;

    if(cps.versao.dtInicioVigencia === undefined){
      this.mensageService.error('', 'A data de início de vigência do contrato informada é inválida.');
      retorno = false;
    }

    if(cps.versao.dtInicioLegal === undefined){
      this.mensageService.error('', 'A data de início de vigência efetiva informada é inválida.');
      retorno = false;
    }

    // console.log(moment(cps.versao.dtInicioVigencia).isValid());
    
    

    return retorno;
  }
}
