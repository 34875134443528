import { DocumentoCobranca, DocumentoCobrancaAnexo, ItemOutroLancamento } from './../../shared/models';
import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CpsHttpClient } from './cps-http.service';
import { SettingsService } from './settings.service';
import { TransferenciaInstrumento } from '../../shared/models';

@Injectable()
export class TransferenciaInstrumentoService {

  constructor(private http: CpsHttpClient, private settings: SettingsService) { }

//   get documentoCobrancaCorrente(): DocumentoCobranca {
//     let documentoCobranca: DocumentoCobranca;
//     if (sessionStorage.length > 0) {
//       documentoCobranca = JSON.parse(sessionStorage.getItem('documento-cobranca-corrente'));
//       this._documentoCobranca = documentoCobranca;
//     } else {
//       this._documentoCobranca = null;
//     }
//     return this._documentoCobranca;
//   }

//   set documentoCobrancaCorrente(documentoCobrancaCorrente: DocumentoCobranca) {
//     sessionStorage.setItem('documento-cobranca-corrente', JSON.stringify(documentoCobrancaCorrente));
//     this._documentoCobranca = documentoCobrancaCorrente;
//   }

  salvar(transferencia: TransferenciaInstrumento): Observable<number> {
    const headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    return this.http.post(`${this.getUrl()}`, transferencia, { headers: headers });
  }


  getUrl() {
    return this.settings.UrlCpsRest() + 'api/transferencia-instrumento';
  }


//   toServer(documentoCobranca: DocumentoCobranca, array_anexos?: DocumentoCobrancaAnexo[]): any {
//     documentoCobranca.anexos = array_anexos ? array_anexos : null;

//     return documentoCobranca;
//   }
}
