import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CpsHttpClient } from './cps-http.service';
import { Cps, CpsAnexo, Orgao } from '../../shared/models';
import { DateFormatBrPipe } from '../../shared/pipes';
import { SettingsService } from './settings.service';

@Injectable()
export class CpsService {

  private _cps: Cps;

  constructor(protected http: CpsHttpClient, private settings: SettingsService) { }

  public getUrl(): string {
    return this.settings.UrlCpsRest() + 'api/cps';
  }

  get cpsCorrente(): Cps {
    let cps: Cps;
    if (sessionStorage.length > 0) {
      cps = JSON.parse(sessionStorage.getItem('cps-corrente'));
      this._cps = cps;
    } else {
      this._cps = null;
    }
    return this._cps;
  }

  set cpsCorrente(cpsCorrente: Cps) {
    sessionStorage.setItem('cps-corrente', JSON.stringify(cpsCorrente));
    this._cps = cpsCorrente;
  }

  public removerCpsCorrente() {
    sessionStorage.removeItem('cps-corrente');
  }

  getCps(id, cpsVersaoEspecifica?): Observable<Cps> {
    if (cpsVersaoEspecifica){
      return this.http.get(`${this.getUrl() + '/versao/'}${cpsVersaoEspecifica}`);
    }
    return this.http.get(`${this.getUrl() + '/'}${id}`);
  }
  
  listarOrgaosComCPSAtivo(): Observable<Orgao[]> {
    return this.http.get(`${this.getUrl()}` + '/orgaoscpsativo');
  }

  getOrgaosConcedentesFiltro(orgao): Observable<Orgao[]> {
    return this.http.get(`${this.getUrl()}` + '/filtro/orgao/' + orgao);
  }
  

  listaCpsPorFiltro(orgao, filtro): Observable<Cps[]> {
    let params = null;
    if (filtro) {
      params = Object.getOwnPropertyNames(filtro).forEach(function(val, idx, array) {
        if(!filtro[val]){
          delete filtro[val];
        }
      });
      params = Object.getOwnPropertyNames(filtro).reduce((p, key) => p.set(key, filtro[key]), new HttpParams());
    }
    const options = params ? {params: params} : {};
    return this.http.get(`${this.getUrl()}` + '/orgao/' + orgao, options);
  }
  
  listarCpsOrigem(orgaoId){   
    return this.http.get(`${this.getUrl()}` + '/transferencia/' + orgaoId);
  }

  listarCpsDestino(orgaoId, cpsOrigemId){
    return this.http.get(`${this.getUrl()}` + '/transferencia/' + orgaoId + '/' + cpsOrigemId);
  }

  enviar(anexos?: CpsAnexo[]): Observable<number> {
    const headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    return this.http.put(`${this.getUrl()}` + '/envio', this.toServer(anexos), { headers: headers });
  }

  ativar(): Observable<number> {
    const headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    return this.http.put(`${this.getUrl()}` + '/ativacao', this.toServer(), { headers: headers });
  }
  
  aditivar(anexos?: CpsAnexo[]): Observable<number> {
    const headers = new HttpHeaders();
    headers.append('Accept', 'application/json');    
    if (this.cpsCorrente.versao.id > 0) {
      // return this.http.put(`${this.getUrl()}` + '/alterarAditivo', this.toServer(anexos), { headers: headers });
      return this.http.put(`${this.getUrl()}`, this.toServer(anexos), { headers: headers });
    } else {
      return this.http.put(`${this.getUrl()}` + '/incluirAditivo', this.toServer(anexos), { headers: headers });
    }
  }

  deletarAditivoCps(id): Observable<number> {
    return this.http.delete(`${this.getUrl() + '/deletarAditivo/'}${id}`);
  }

  encerrar(motivo: number, justificativa: string): Observable<number> {
    const headers = new HttpHeaders();
    headers.append('Accept', 'application/json');

    return this.http.put(`${this.getUrl()}` + '/encerramento/' + motivo + '/' + justificativa, this.toServer(), { headers: headers });
  }  

  atualizarCps(anexos?: CpsAnexo[]): Observable<Cps> {
    const headers = new HttpHeaders();
    headers.append('Accept', 'application/json');    
    if (this.cpsCorrente.id > 0) {
      return this.http.put(`${this.getUrl()}`, this.toServer(anexos), { headers: headers });
    } else {
      return this.http.post(`${this.getUrl()}`, this.toServer(anexos), { headers: headers });
    }
  }

  deletarCps(id): Observable<number> {
    return this.http.delete(`${this.getUrl() + '/'}${id}`);
  }

  
  listarAnexos(cpsId): Observable<CpsAnexo[]> {
    return this.http.get(`${this.getUrl() + '/'}${cpsId}` + '/anexos');
  }

  baixarAnexo(id_versao, id_anexo): Observable<CpsAnexo[]> {
    return this.http.get(`${this.getUrl() + '/versao/'}${id_versao}` + '/anexos/' + `${id_anexo}`);
  }

  salvarAnexo(array_anexos?: any) {
    const headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    return this.http.post(`${this.getUrl() + '/versao/'}${this.toServer().versao.id}` + '/anexo', this.toServer(array_anexos).versao.anexos, { headers: headers });
  }

  // Cria uma lista de anexos apenas com os arquivos novos e excluidos para que a requisição não passe de 25MB
  getAnexos(listaAnexos: any): CpsAnexo[] {
    let anexos: CpsAnexo[] = null;
    if (!listaAnexos) {
      return null;
    } else {      
      anexos = listaAnexos.filter(a => (a.situacao === 'N') || (a.id && a.situacao === 'E'));      
    }
    return anexos;
  }

  toServer(array_anexos?: any): any {
    const cps = this.cpsCorrente;
    const datePipe = new DateFormatBrPipe();
    
    const myCpsVersao = {
      id: cps.versao.id,
      codigo: cps.versao.codigo,
      situacao: cps.versao.situacao,
      valorTotal: cps.versao.valorTotal,
      inEncerramento: cps.versao.inEncerramento,
      dtInicioVigencia: datePipe.toServerDate(cps.versao.dtInicioVigencia),
      dtFimVigencia: datePipe.toServerDate(cps.versao.dtFimVigencia),
      dtInicioLegal: datePipe.toServerDate(cps.versao.dtInicioLegal),
      dtFimLegal: datePipe.toServerDate(cps.versao.dtFimLegal),
      dtReferenciaPreco: datePipe.toServerDate(cps.versao.dtReferenciaPreco),
      egts: cps.versao.egts,
      anexos: this.getAnexos(array_anexos)     
      // anexos: this.getAnexos(array_anexos)
    };

    return {
      id: cps.id,
      ano: cps.ano,
      versao: myCpsVersao,
      processo: cps.processo,
      codigo: cps.codigo,
      orgaoSuperior: cps.orgaoSuperior,
      orgaoConcedente: cps.orgaoConcedente,
      orgaoMandataria: cps.orgaoMandataria,
      douSecao: cps.douSecao,
      douPagina: cps.douPagina,
      douNumeroProcesso: cps.douNumeroProcesso,
      douDtPublicacao: datePipe.toServerDate(cps.douDtPublicacao),
      uasg: cps.uasg,
      programaTrabalho: cps.programaTrabalho,
      acaoOrcamentaria: cps.acaoOrcamentaria,
      fonte: cps.fonte,
      banco: cps.banco,
      agencia: cps.agencia,
      conta: cps.conta,
      cpfCnpjFavorecido: cps.cpfCnpjFavorecido,
      nomeFavorecido: cps.nomeFavorecido
    };
  }
}
