import { DocumentoCobranca, Egt, ItemOutroLancamento, TipoOutroLancamentoEnum } from './../../../../shared/models';
import { EgtService, EventButtonService,
         DocumentoCobrancaService, CpsService, DetalhamentoContratoService } from './../../../../core/services';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';
import { CpsCurrencyPipe, DateFormatBrPipe } from '../../../../shared/pipes';

import * as moment from 'moment';
import 'moment/locale/pt-br';
import { AlertMessageService } from '@serpro/ngx-siconv';

@Component({
  selector: 'cps-outros-lancamentos',
  templateUrl: './outros-lancamentos.component.html',
  styleUrls: ['./outros-lancamentos.component.scss']
}) 

export class OutrosLancamentosComponent implements OnInit {

  @Input() docSelecionado: DocumentoCobranca;
  form: FormGroup;
  listaPropostas: any[] = new Array();
  listaEgts: any[] = new Array();
  listaTipoOutrosLancamentos: any[] = new Array();

  tipoLancamentoCtrl: FormControl = new FormControl('', Validators.required);
  propostaCtrl: FormControl = new FormControl('', Validators.required);
  egtCtrl: FormControl = new FormControl('', Validators.required);
  valorCtrl: FormControl = new FormControl('', Validators.required);
  justificativaCtrl: FormControl = new FormControl('', Validators.required);

  constructor(
    private formBuilder: FormBuilder,
    private mensageService: AlertMessageService,
    private cpsService: CpsService,
    private detalhamentoContratoService: DetalhamentoContratoService,
    private egtService: EgtService,
    private docCobService: DocumentoCobrancaService
  ) {     
  }

  ngOnInit() {
    this.mensageService.dismissAll();
    this.getForm();
    this.carregarCombos();
  }

  getForm(): void {
    this.form = this.formBuilder.group({
      tipoLancamento: ['', Validators.required],
      proposta: ['', Validators.required],
      egt: ['', Validators.required],
      dataInclusao: ['', Validators.required],
      valor: ['', Validators.compose([Validators.required, Validators.maxLength(10)])],
      justificativa: ['', Validators.compose([Validators.required, Validators.maxLength(2000)])]
    });

    this.form.controls['dataInclusao'].setValue(this.getDataAtual());
    this.form.controls['dataInclusao'].disable();
  }

  async carregarCombos() {
    this.carregarTipos();
    this.carregarCRResumidos(this.cpsService.cpsCorrente.id);
    this.carregarEgtsContratados(this.cpsService.cpsCorrente.id);
  }

  private carregarTipos() {
    this.listaTipoOutrosLancamentos.length = 0;
    this.listaTipoOutrosLancamentos.push({ id: TipoOutroLancamentoEnum.DEBITO, descricao: 'Débito' });
    this.listaTipoOutrosLancamentos.push({ id: TipoOutroLancamentoEnum.CREDITO, descricao: 'Crédito' });
  }

  private carregarCRResumidos(idCps: number): void {
    this.detalhamentoContratoService
      .consultarContratosRepasseResumidos(idCps)
      .subscribe((response: any) => {
        if (response) {
          this.listaPropostas = response;
        }
      });
  }

  private carregarEgtsContratados(idCps: number): void {
    this.egtService.listarEgts(idCps).subscribe(
      (response: Egt[]) => {
        this.listaEgts = response;
      }
    );
  }

  onSalvar() {
    const datePipe = new DateFormatBrPipe();
    const currencyPipe: CpsCurrencyPipe = new CpsCurrencyPipe();

    const outroLancamento = new ItemOutroLancamento(
      null,
      this.form.controls['tipoLancamento'].value,
      this.docSelecionado.id,
      this.form.controls['egt'].value && this.form.controls['egt'].value !== 0 ?
        this.form.controls['egt'].value : null,
      null, null,
      this.form.controls['justificativa'].value,
      
      this.form.controls['proposta'].value && this.form.controls['proposta'].value !== 0
        ? this.form.controls['proposta'].value.id : null,
      
      this.form.controls['proposta'].value && this.form.controls['proposta'].value !== 0
        ? this.form.controls['proposta'].value.sequencial : null,
      
      this.form.controls['proposta'].value && this.form.controls['proposta'].value !== 0
        ? this.form.controls['proposta'].value.ano : null,
      
      this.form.controls['proposta'].value && this.form.controls['proposta'].value !== 0
        ? this.form.controls['proposta'].value.idConvenio : null,
      
      this.form.controls['proposta'].value && this.form.controls['proposta'].value !== 0
        ? this.form.controls['proposta'].value.sequencialConvenio : null,
      
      this.form.controls['proposta'].value && this.form.controls['proposta'].value !== 0
        ? this.form.controls['proposta'].value.anoConvenio : null,

      currencyPipe.toBackendParse(this.form.controls['valor'].value, 14),
      null, null, null, null, null,
      datePipe.toServerDate(this.form.controls['dataInclusao'].value)
    );
    
    this.incluirOutroLancamento(outroLancamento);    
  }

  onCancelar() {
    EventButtonService.get('cancelarOutrosLancamentos').emit(true);
  }

  incluirOutroLancamento(lancamento: ItemOutroLancamento) {
      this.docCobService.incluirOutroLancamento(lancamento).subscribe(
        (data) => {
          const ret = data;
          EventButtonService.get('incluirOuDeletarOutrosLancamentos').emit(true);
          this.mensageService.success('Lançamento incluído', 'Lançamento incluído com sucesso');
        }
      );
  }

  onPaste(e: any) {
    e.preventDefault();
  }

  getDataAtual(): string {
    return moment(new Date()).format('DD/MM/YYYY');
  }
}
