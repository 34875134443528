export class FiltroRelatorioEgte {
    // public tipoVisao: string;
    public egt: string;
    public proposta: string;
    public uf: string;
    public convenente: string;
    public orgao: number;
    public dataInicialEgt: String;
    public dataFinalEgt: String;
    constructor() {}
}
