import { IdpService, EventButtonService,
         SettingsService, DocumentoCobrancaService, CpsService } from './../../../../core/services';
import { Cps, DocumentoCobranca, DocumentoCobrancaSituacaoEnum, ItemCobranca, ItemOutroLancamento, 
         TipoOutroLancamentoEnum, Usuario } from './../../../../shared/models';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CpsCurrencyPipe } from './../../../../shared/pipes';
import { Router } from '@angular/router';
import { Component, OnInit, OnChanges, SimpleChanges, SimpleChange, Input, TemplateRef } from '@angular/core';
import { AlertMessageService } from '@serpro/ngx-siconv';
import { DataExport } from '../../../../shared/models/data-export';
import { LimitarTextoPipe } from '../../../../shared/pipes/limitar-texto.pipe';

@Component({
  selector: 'cps-relatorio-analitico',
  templateUrl: './relatorio-analitico.component.html',
  styleUrls: ['./relatorio-analitico.component.scss']
})
export class RelatorioAnaliticoComponent implements OnInit, OnChanges {
  @Input() docSelecionado: DocumentoCobranca;

  mensagem = 'Não houve ateste de serviços no período do documento de cobrança.';
  cps: Cps = null;
  itemsPerPage = 10;
  valorTotalAcumulado = 0;
  valorOutros = 0;
  itensCobrancaOrdinaria: any = new Array();
  itensCobrancaExtra: any = new Array();
  itensOutroLancamento: ItemOutroLancamento[] = new Array();
  documentoCobranca: DocumentoCobranca = null;
  delOutroLancamentoId = null;
  delOutroLancamentoMessage = '';
  delOutroLancamentoModalRef = null;

  egtsExtras = new Map();
  egtsOrdinarias = new Map();
  
  usuario: Usuario = null;
  ehUsuarioMandatariaGestor = false;

  listaPaginadaOrdinaria = new Map();
  listaPaginadaExtra = new Map();

  listaPaginadaOutrosLancamentos: any = new Array();

  // Grid
  export1: DataExport;
  export2: DataExport;
  export3: DataExport;
  
  constructor(private router: Router,
    private settingsService: SettingsService,
    private dcservice: DocumentoCobrancaService,
    private modalService: BsModalService,
    private mensageService: AlertMessageService,
    private cpsservice: CpsService,
    private idpService: IdpService) {
  }

  ngOnInit() {
    this.mensageService.dismissAll();
    this.cps = this.cpsservice.cpsCorrente;
    this.usuario = this.idpService.loggedUser;
    this.ehUsuarioMandatariaGestor = this.usuario.isUsuarioMandatariaGestor();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const doc: SimpleChange = changes.docSelecionado;
    this.documentoCobranca = doc.currentValue;
    this.egtsExtras = new Map();
    this.egtsOrdinarias = new Map();
    this.valorTotalAcumulado = 0;
    this.carregarDocumentoCobranca();
  }

  carregarDocumentoCobranca() {
    this.valorTotalAcumulado = 0;

    if (this.documentoCobranca != null) {
      this.itensCobrancaOrdinaria = this.documentoCobranca.itensCobrancaOrdinaria;
      this.itensCobrancaExtra = this.documentoCobranca.itensCobrancaExtra;
      this.itensOutroLancamento = this.documentoCobranca.itensOutroLancamento;      
    }

    this.itensCobrancaOrdinaria.forEach(element => {
      const chave = element.egtCodigo + ' - ' + element.egtDescricao;
      if (this.egtsOrdinarias.has(chave)) {
        const el = this.egtsOrdinarias.get(chave);
        el.itensCobranca.push(element);
        this.egtsOrdinarias.set(chave,
          { valorTotal: el.valorTotal + element.valorTarifa, itensCobranca: el.itensCobranca });
      } else {
        this.egtsOrdinarias.set(chave,
          { valorTotal: element.valorTarifa, itensCobranca: [element] });
      }
      this.valorTotalAcumulado += element.valorTarifa;
    });

    this.getExport1();

    this.itensCobrancaExtra.forEach(element => {
      const chave = element.egtCodigo + ' - ' + element.egtDescricao;
      if (this.egtsExtras.has(chave)) {
        const el = this.egtsExtras.get(chave);
        el.itensCobranca.push(element);
        this.egtsExtras.set(chave,
          { valorTotal: el.valorTotal + element.valorTarifa, itensCobranca: el.itensCobranca });
      } else {
        this.egtsExtras.set(chave,
          { valorTotal: element.valorTarifa, itensCobranca: [element] });
      }
      this.valorTotalAcumulado += element.valorTarifa;
    });

    this.getExport2();

    this.valorOutros = 0;
    this.itensOutroLancamento.forEach(element => {
      if (element.outroLancamentoTipo === TipoOutroLancamentoEnum.DEBITO) {
        this.valorOutros -= element.valor;
      } else if (element.outroLancamentoTipo === TipoOutroLancamentoEnum.CREDITO) {
        this.valorOutros += element.valor;
      }
    });
    
    this.getExport3();

    this.valorTotalAcumulado += this.valorOutros;
  }

  getPropostaF(item: ItemOutroLancamento) { 
    return (item.propFk && item.propFk !== 0) ? item.propostaF : '-';
  }

  getKeys(map) {
    if (map === null) {
      return [];
    }
    return Array.from(map.keys());
  }

  getList(map, key) {
    if (map === null) {
      return [];
    }
    return map.get(key).itensCobranca;
  }

  getValorTotalF(map, key) {
    const currencyPipe: CpsCurrencyPipe = new CpsCurrencyPipe();
    const valorTotal:number = this.getValorTotal(map, key);
    return 'R$ ' + (valorTotal === 0 ? '0,00' : currencyPipe.transform((100 * Number(valorTotal.toFixed(2))).toFixed(2).toString()));
  }

  getValorOutrosF() {
    const currencyPipe: CpsCurrencyPipe = new CpsCurrencyPipe();
    const vl_fixed = Number(this.valorOutros.toFixed(2));

    let valorFormatado: string = 'R$ ' + (this.valorOutros === 0 ? '0,00' : currencyPipe.transform((100 * vl_fixed).toFixed(2).toString()));
    if (this.valorOutros < 0) {
      valorFormatado = 'R$ -' + currencyPipe.transform((100 * Math.abs(vl_fixed)).toFixed(2).toString());
    }
    return valorFormatado;
  }

  getValorTotal(map, key) {
    if (map === null) {
      return null;
    }
    return map.get(key).valorTotal;
  }

  isEmpty(mapa): boolean {
    return mapa === null || mapa.size === 0;
  }

  getLinkExterno(item: ItemCobranca) {
    return this.settingsService.UrlSiconv()
      + '/ConsultarProposta/ResultadoDaConsultaDePropostaDetalharProposta.do?idProposta='
      + item.propFk;
  }

  podeEditar() {
    return this.ehUsuarioMandatariaGestor && (this.documentoCobranca.situacao !== DocumentoCobrancaSituacaoEnum.ACEITO
      && this.documentoCobranca.situacao !== DocumentoCobrancaSituacaoEnum.PAGO);
  }

  podeDeletar() {
    return this.ehUsuarioMandatariaGestor && (this.documentoCobranca.situacao !== DocumentoCobrancaSituacaoEnum.ACEITO
      && this.documentoCobranca.situacao !== DocumentoCobrancaSituacaoEnum.PAGO);
  }

  showConfirm(template: TemplateRef<any>, item) {
    this.delOutroLancamentoId = item.id;
    this.delOutroLancamentoMessage = 'Confirma a exclusão do Lançamento ?';
    this.delOutroLancamentoModalRef = this.modalService.show(template, { class: 'modal-sm' });
  }

  confirm(): void {
    if (this.delOutroLancamentoId != null) {
      this.deletarOutroLancamento(this.delOutroLancamentoId);
      // this.router.navigate(['/cps-lista']);
    }
    this.delOutroLancamentoModalRef.hide();
  }

  deletarOutroLancamento(id) {
    this.dcservice.deletarOutroLancamento(id).subscribe(
      (data) => {
        const ret = data;
        this.reloadDocumentoCobranca();
        this.mensageService.success('Lançamento excluído', 'Lançamento excluído com sucesso');
      }
    );
  }

  columns = [
    'Número da Proposta / SICONV', 'Convenente', 'UF', 'Programa', 'Repasse',
    'Data do EGT', 'Status do EGT', 'Valor da Tarifa (R$)'
  ];

  getExport1(): void {
    let data = [];
    this.itensCobrancaOrdinaria.forEach(element => {
      data.push(this.getLinha(element));
    });   

    this.export1 = new DataExport(this.columns, data);
  }

  getExport2(): void {
    let data = [];
    this.itensCobrancaExtra.forEach(element => {
      data.push(this.getLinha(element));      
    });   

    this.export2 = new DataExport(this.columns, data);
  }

  getExport3(): void {
    let data = [];
    this.itensOutroLancamento.forEach(element => {
      data.push(this.getLinha(element));
    });   

    this.export3 = new DataExport(this.columns, data);
  }

  getLinha(element){
    let linha = [];
    linha.push(element.propostaF);
    linha.push(element.convenente)
    linha.push(element.uf)
    linha.push(element.programa)
    linha.push(element.valorRepasseF);
    linha.push(element.dtFimImrF);
    linha.push(element.statusEgt);
    linha.push(element.valorTarifaF);
    return linha;
  }

  reloadDocumentoCobranca() {
    EventButtonService.get('incluirOuDeletarOutrosLancamentos').emit(true);
    // this.dcservice.detalharDocumentosCobranca(this.documentoCobranca.id).subscribe(
    //   (response: DocumentoCobranca) => {
    //     if (response != null) {
    //       this.documentoCobranca = response;
    //       this.carregarDocumentoCobranca();
    //     }
    //   }
    // );
  }

  decline(): void {
    this.delOutroLancamentoModalRef.hide();
  }

  getListEgts(map, key) {
    if (map === null) {
      return [];
    }
    return map.get(key);
  }

  getListaPaginadaOrdinaria(listap, key){    
    this.listaPaginadaOrdinaria.set(key, listap);  
  }

  getListaPaginadaExtra(listap, key){
    this.listaPaginadaExtra.set(key, listap);
  }

  getListaPaginadaOutrosLancamentos(listap){
    this.listaPaginadaOutrosLancamentos = listap;
  }
}
