import { CpsAnexo } from './../models/cps-anexo';
import { Observable, of } from 'rxjs';

import { Cps, CpsVersao, HistoricoCps,  Orgao, Usuario, MotivoEncerramento } from '../models';
import { } from 'jasmine';
import { ContratoRepasseVinculado } from '../models/contrato-repasse-vinculado';

export class IdpServiceMock {
  constructor() { }
  get loggedUser(): Usuario {
    const orgao = new Orgao(1449, '20113', 'teste');
    return new Usuario('Bruno', '02589632587', 'bruno@gmail.com', orgao,
      ['GESTOR_CONVENIO_INSTITUICAO_MANDATARIA', 'GESTOR_CONVENIO_CONCEDENTE']);
  }

  async retoken() {
    return 'pSKLDJFN928RHM879.879H97H7yiouh87y978h9.7897Y9H9898h9GH';
  }
}

export class CpsServiceMock {
  constructor() { }
  get cpsCorrente(): Cps {
    return new Cps(
      1, 1, 2018, '', '001/2018', 123, 456, 789, null, null,
      null, null, null, '', '', '', null,
      null, null, '123456789', '',
      new CpsVersao(1, 1, 1, 50, new Date(), new Date(), new Date(),new Date(),new Date(),false,'', '', '', '', [], []),
      [], '', ''
    );
  }

  set cpsCorrente(cps: Cps) {

  }

  listaCpsPorOrgao(orgao): Observable<Cps[]> {
    const lista: Cps[] = new Array();
    lista.push(new Cps(
      1, 1, 2018, '', '001/2018', 123, 456, 789, null, null,
      null, null, null, '', '', '', null,
      null, null, '123456789', '',
      new CpsVersao(1, 1, 1, 50, new Date(), new Date(), new Date(), new Date(), new Date(), false, '', '', '', '', [], []),
      [], ''
    ), new Cps(
      2, 2, 2018, '', '002/2018', 123, 456, 789, null, null,
      null, null, null, '', '', '', null,
      null, null, '123456789', '',
      new CpsVersao(2, 2, 2, 50, new Date(), new Date(), new Date(), new Date(),new Date(), false, '', '', '', '', [], []),
      [], ''
    ));
    return of(lista);
  }

  listarAnexos(id): Observable<CpsAnexo[]> {
    const lista: CpsAnexo[] = new Array();
    lista.push(
      new CpsAnexo(1, 1, 'Desc1', 'arquivo1', 'nome1.pdf', ''),
      new CpsAnexo(2, 2, 'Desc2', 'arquivo2', 'nome2.pdf', ''));
    return of(lista);
  }

  listarPropostasPorCps(cps_id): Observable<ContratoRepasseVinculado[]> {
    const lista: ContratoRepasseVinculado[] = new Array();
    lista.push(
      new ContratoRepasseVinculado('1', '10', 'PE', 'Covenente 1', 'Situação 1', 10000, '10000', '', '', '', 1, true)
    );
    return of(lista);
  }
}

export class OrgaoServiceMock {
  constructor() { }
  getOrgao(orgaoId: number): Observable<Orgao> {
    return of(new Orgao(1449));
  }

  mapResultOrgao(orgaoId: number): Orgao {
    return new Orgao(1, '1', 'Orgão Teste');
  }

  getOrgaoCodigo(orgaoId: number): Observable<Orgao> {
    return of(new Orgao(1449));
  }

  getOrgaoUsuario(): Observable<Orgao> {
    return of(new Orgao(1449));
  }

  getSubordinados(orgaoSup: number): Observable<Orgao[]> {
    const subordinados = [];
    subordinados.push(new Orgao(1, '1', 'Orgão Teste'));
    let response = new Array();
    response = subordinados;
    return of(response);
  }

  getMandatarias(): Observable<Orgao[]> {
    const mandatarias = [];
    mandatarias.push(new Orgao(1, '1', 'Orgão Mandataria Teste 1'));
    mandatarias.push(new Orgao(2, '2', 'Orgão Mandataria Teste 2'));
    let response = new Array();
    response = mandatarias;
    return of(response);
  }
}


export class MotivoEncerramentoServiceMock {
  constructor() { }
  get(): Observable<MotivoEncerramento[]> {
    const motivoEncerramento = [];
    motivoEncerramento.push(new MotivoEncerramento(1, '1'));
    motivoEncerramento.push(new MotivoEncerramento(2, '2'));
    let response = new Array();
    response = motivoEncerramento;
    return of(response);
  }

  mapResultOrgao(orgaoId: number): Orgao {
    return new Orgao(1, '1', 'Orgão Teste');
  }

  getOrgaoCodigo(orgaoId: number): Observable<Orgao> {
    return of(new Orgao(1449));
  }

  getOrgaoUsuario(): Observable<Orgao> {
    return of(new Orgao(1449));
  }

  getSubordinados(orgaoSup: number): Observable<Orgao[]> {
    const subordinados = [];
    subordinados.push(new Orgao(1, '1', 'Orgão Teste'));
    let response = new Array();
    response = subordinados;
    return of(response);
  }

  getMandatarias(): Observable<Orgao[]> {
    const mandatarias = [];
    mandatarias.push(new Orgao(1, '1', 'Orgão Mandataria Teste 1'));
    mandatarias.push(new Orgao(2, '2', 'Orgão Mandataria Teste 2'));
    let response = new Array();
    response = mandatarias;
    return of(response);
  }
}

export class DetalhamentoContratoServiceMock {
  constructor() { }

  consultarContratosRepasseVinculados(cpsId: number): Observable<ContratoRepasseVinculado[]> {
    const constratos = [];
    constratos.push(new ContratoRepasseVinculado('1', '10', 'PE', 'Covenente 1', 'Situação 1', 10000, '10000', '', '', '', 1, true));
    constratos.push(new ContratoRepasseVinculado('2', '20', 'PE', 'Covenente 2', 'Situação 2', 20000, '20000', '', '', '', 2, true));
    let response = new Array();
    response = constratos;
    return of(response);
  }

  consultarHistoricoCps(cpsId: number): Observable<HistoricoCps[]> {
    const historico = [];
    historico.push(new HistoricoCps('Tite',new Date(), '10/10/2018', 'Situação 1', 'Situação Anterior 1', null, null,2, 0));
    historico.push(new HistoricoCps('Neymar', new Date(), '12/12/2018', 'Situação 2', 'Situação Anterior 2', null, null,1, 0));
    let response = new Array();
    response = historico;
    return of(response);
  }
}

export class UsuarioMandatariaServiceMock {
  constructor() { }
  isUsuarioMandatariaGestor(cpf): Observable<boolean> {
    const response = true;
    return of(response);
  }
}

export class UsuarioServiceMock {
  constructor() { }
  isUsuarioMandatariaGestor(cpf): Observable<boolean> {
    const response = true;
    return of(response);
  }
}

export class MockRouter {
  navigate = jasmine.createSpy('navigate');

  navigateByUrl(url: string) { return url; }
}

export class DocumentoCobrancaServiceMock {
  constructor() { }

  public removerDocumentoCobrancaCorrente() {
    sessionStorage.removeItem('documento-cobranca-corrente');
  }
}

export class SettingsMock {
  public UrlCpsRest() {
    return 'http://localhost:8080/';
  }

  public UrlIdp() {
    return 'https://10.139.67.54:8443/idp/';
  }

  public UrlSiconv() {
    return 'https://10.139.67.53:8443/voluntarias/';
  }
}

export class MenuServiceMock {
  getMenu(): Observable<any> {
    const menu = {
      'ListaMenu':
      [
        {
          'Items':
          [
            {
              'Label': 'Consultar Ente/Entidade', 'LabelKey': null,
              'Funcionalidades': 'CADASTRAMENTO_CONSULTAR_PROPONENTE_MENU_CADASTRAMENTO',
              'Action': 'https://hom.transfere.convenios.gov.br/habilitacao/consulta-entidade.html',
              'Ambiente': null, 'Certificated': false
            }
          ],
          'Id': 'CADASTRAMENTO',
          'ItensPorColuna': 4,
          'Label': 'Cadastramento',
          'LabelKey': null,
          'Funcionalidades': null,
          'Action': null,
          'Ambiente': null,
          'Certificated': false
        }
      ],
      'UrlImagemLogo': 'https://hom.convenios.gov.br/siconv/layout/homologacao/imagens/logo_portal.png',
      'UrlLinkLogo': 'https://hom.convenios.gov.br/siconv/ForwardAction.do?modulo=Principal&path=/Principal.do',
      'UrlLinkLogout': 'https://11.123.8.11:123/idp/?LLO=true',
      'InfoUasg': '',
      'InfoConvenio': '',
      'TextoLogout': 'Sair do Sistema',
      'IdentificacaoUsuario': '123.123.123-12',
      'NomeUsuario': 'José da Silva',
      'IsUsuarioGuest': false

    };
    return of(menu);
  }
}
