export class ConstratoRepasseResumido {
  constructor(
    public id: number = 0,
    public sequencial: number = 0,
    public ano: number = 0,
    public idConvenio: number = 0,
    public sequencialConvenio: String = '0',
    public anoConvenio: number = 0,
  ) { }
}
