import { Component, OnInit } from '@angular/core';
import { UtilSqlService } from '../../core/services';

@Component({
  selector: 'app-util-sql',
  templateUrl: './util-sql.component.html',
  styleUrls: ['./util-sql.component.scss']
})
export class UtilSqlComponent implements OnInit {


  sql: string;
  result = [];
  constructor(private utilSqlService: UtilSqlService) { }

  ngOnInit() {
  }

  click() {
    this.utilSqlService.post(this.sql).subscribe(data => {
      // console.log(this.result);
    })
  }

}
