export class CpsDadosGerais {

    constructor(
        public id: number,
        public ano: number,
        public cpsVersao: number,
        public cpsSituacao: number,
        public cpsProcesso: string,
        public cpsNumero: string,
        public orgaoSuperior: number,
        public orgaoConcedente: number,
        public orgaoContratada: number,
        public dtInicioVigencia: Date,
        public dtFimVigencia: Date,
        public dtInicioLegal: Date,        
        public dtFimLegal: Date,  
        public douSecao: number,
        public douPagina: number,
        public douProcesso: string,
        public douDtPublicacao: Date,
        public vlContrato: number,
        public uasg: number,
        public pt: number,
        public acao: number,
        public fonte: number,
        public banco: number,
        public agencia: number,
        public conta: number,
        public cpfcnpjFavorecido: number,
        public nomeFavorecido: string
    ) { }

    static fromServer(obj: any): CpsDadosGerais {
        return new CpsDadosGerais(
            +obj.id,
            +obj.ano,
            +obj.cpsVersao,
            +obj.cpsSituacao,
            obj.cpsProcesso,
            obj.cpsNumero,
            +obj.orgaoSuperior,
            +obj.orgaoConcedente,
            +obj.orgaoContratada,
            obj.dtInicioVigencia, // Date,
            obj.dtFimVigencia, // Date,
            obj.dtInicioLegal,
            obj.dtFimLegal,
            +obj.douSecao,
            +obj.douPagina,
            obj.douProcesso,
            obj.douDtPublicacao, // Date,
            +obj.vlContrato,
            +obj.uasg,
            +obj.pt,
            +obj.acao,
            +obj.fonte,
            +obj.banco,
            +obj.agencia,
            +obj.conta,
            +obj.cpfcnpjFavorecido,
            obj.nomeFavorecido);
        //   +obj.id,
        //   obj.nome,
        //   +obj.matriculados,
        //   +obj.professores,
        //   obj.labinformatica === 1 ? true : false,
        //   obj.labciencias === 1 ? true : false);
    }

    static fromView(obj: any): CpsDadosGerais {
        return new CpsDadosGerais(
            +obj.id,
            +obj.ano,
            +obj.cpsVersao,
            +obj.cpsSituacao,
            obj.cpsProcesso,
            obj.cpsNumero,
            +obj.orgaoSuperior,
            +obj.orgaoConcedente,
            +obj.orgaoContratada,
            obj.dtInicioVigencia, // Date,
            obj.dtFimVigencia, // Date,
            obj.dtInicioLegal, // Date,
            obj.dtFimLegal, // Date,
            +obj.douSecao,
            +obj.douPagina,
            obj.douProcesso,
            obj.douDtPublicacao, // Date,
            +obj.vlContrato,
            +obj.uasg,
            +obj.pt,
            +obj.acao,
            +obj.fonte,
            +obj.banco,
            +obj.agencia,
            +obj.conta,
            +obj.cpfcnpjFavorecido,
            obj.nomeFavorecido);
    }

    toServer(): any {
        return {
            id: this.id,
            ano: this.ano,
            versao: this.cpsVersao, // cps-versao.ts id
            situacao: this.cpsSituacao, // cps-versao.ts
            processo: this.cpsProcesso,
            codigo: this.cpsNumero,
            orgaoSuperior: this.orgaoSuperior,
            orgaoConcedente: this.orgaoConcedente,
            orgaoMandataria: this.orgaoContratada,
            dtInicioVigencia: this.dtInicioVigencia, // cps-versao.ts: Date,
            dtFimVigencia: this.dtFimVigencia, // cps-versao.ts: Date,
            dtInicioLegal: this.dtInicioLegal, // cps-versao.ts: Date,
            dtFimLegal: this.dtFimLegal, // cps-versao.ts: Date,
            secao: this.douSecao,
            pagina: this.douPagina,
            numeroProcesso: this.douProcesso,
            dtPublicacao: this.douDtPublicacao, // : Date,
            valorTotal: this.vlContrato, // cps-versao.ts
            uasg: this.uasg,
            programaTrabalho: this.pt,
            acaoOrcamentaria: this.acao,
            fonte: this.fonte,
            banco: this.banco,
            agencia: this.agencia,
            conta: this.conta,
            cpfCnpjFavorecido: this.cpfcnpjFavorecido,
            nomeFavorecido: this.nomeFavorecido
        };
    }
}
