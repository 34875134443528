import { Pipe, PipeTransform } from "@angular/core";

export enum CpsVersaoSituacaoEnum {
    ELABORACAO = 1,
    ENVIADO_PARA_CIENCIA = 2,
    ATIVO = 3,
    EM_ADITIVACAO = 4,
    ENCERRADO = 6,
    SUSPENSO = 7,
    ENCERRADO_POR_ADITIVO = 8,
    SUSPENSO_POR_ADITIVO = 9,
    ADITIVADO_PARA_ENCERRAMENTO = 10,
    ADITIVADO_POR_EXCLUSAO_DE_UGS = 11
    
}

@Pipe({
    name: 'cpsVersaoSituacaoPipe'
  })
  export class CpsVersaoSituacaoEnumPipe implements PipeTransform {
    transform(status: CpsVersaoSituacaoEnum): string {          
        switch (status) {
            case CpsVersaoSituacaoEnum.ADITIVADO_PARA_ENCERRAMENTO:
                return 'Aditivado Para Encerramento'; 
            case CpsVersaoSituacaoEnum.ATIVO:
                return 'Ativo'; 
            case CpsVersaoSituacaoEnum.ADITIVADO_POR_EXCLUSAO_DE_UGS:
                return 'Aditivado por Exclusão de UGs';      
			case CpsVersaoSituacaoEnum.ELABORACAO:
				return 'Em Elaboração';
			case CpsVersaoSituacaoEnum.EM_ADITIVACAO:
				return 'Em Aditivação';
			case CpsVersaoSituacaoEnum.ENCERRADO:
				return 'Encerrado';
            case CpsVersaoSituacaoEnum.ENCERRADO_POR_ADITIVO:
                return 'Encerrado por Aditivo';    
            case CpsVersaoSituacaoEnum.ENVIADO_PARA_CIENCIA:
                return 'Enviado para Ciência';
            case CpsVersaoSituacaoEnum.SUSPENSO:
                return 'Suspenso';
            case CpsVersaoSituacaoEnum.SUSPENSO_POR_ADITIVO:
                return 'Suspenso por Aditivo';
            default:
                return "";
        }           
    }
  }

