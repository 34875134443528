import { FileUtil } from './util/file-util';
import { FileUploadModule } from 'primeng/components/fileupload/fileupload';
import { NgModule } from '@angular/core';

import { MenuComponent } from './components/menu/menu.component';
import { LimitarTextoPipe } from './pipes/limitar-texto.pipe';
import { CpfPipe } from './pipes/cpf.pipe';

import { InputMaskModule } from 'primeng/components/inputmask/inputmask';

import { PropostaSituacaoPipe } from './pipes/proposta-situacao.pipe';
import { DateFormatBrPipe } from './pipes/date-format-br.pipe';
import { CpsCurrencyPipe } from './pipes/currency.pipe';
import { BootstrapModule } from './bootstrap.module';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MenuModule } from './components/menu/menu.module';
import { NgSelectModule, NG_SELECT_DEFAULT_CONFIG } from '@ng-select/ng-select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UtilToken } from './util';
import { ZeroEsquerdaPipe } from './pipes/zero-esquerda.pipe';
import { SiconvDatePipe, SiconvDateHourPipe, SelectOrgaoPipe, ValueIdPipe, SelectTransferenciaCPSPipe, SelectPropostaPipe, SelectEgtPipe } from './pipes';
import { DisableControlDirective } from './directives/disable-control.directive';
import { SelectMotivoEncerramentoPipe, SelectCPSPipe, SelectDataReferenciaPrecoPipe, SelectDescricaoIdPipe } from './pipes/selects.pipe';

@NgModule({
  imports: [
    BootstrapModule,
    CommonModule,
    FormsModule,
    InputMaskModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    MenuModule,
    NgSelectModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    RouterModule,
    FileUploadModule
  ],
  exports : [
    BootstrapModule,
    CommonModule,
    FormsModule,
    InputMaskModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    MenuComponent,
    MenuModule,
    NgSelectModule,
    BrowserAnimationsModule,
    CpfPipe,
    CpsCurrencyPipe,
    DateFormatBrPipe,
    SiconvDatePipe,
    SiconvDateHourPipe,
    LimitarTextoPipe,
    ZeroEsquerdaPipe,
    PropostaSituacaoPipe,
    SelectTransferenciaCPSPipe,
    SelectDataReferenciaPrecoPipe,
    SelectOrgaoPipe,
    SelectCPSPipe,
    SelectMotivoEncerramentoPipe,
    SelectEgtPipe,
    SelectPropostaPipe,
    SelectDescricaoIdPipe,
    ValueIdPipe,
    FileUploadModule
  ],
  declarations: [
    CpsCurrencyPipe,
    CpfPipe,
    DateFormatBrPipe,
    SiconvDatePipe,
    SiconvDateHourPipe,
    LimitarTextoPipe,
    ZeroEsquerdaPipe,
    PropostaSituacaoPipe,
    SelectOrgaoPipe,
    SelectCPSPipe,
    SelectMotivoEncerramentoPipe,
    SelectEgtPipe,
    SelectPropostaPipe,
    SelectDescricaoIdPipe,
    SelectTransferenciaCPSPipe,
    SelectDataReferenciaPrecoPipe,
    ValueIdPipe,
    DisableControlDirective,
    MenuComponent
  ],
  providers: [
    UtilToken,
    CpsCurrencyPipe,
    FileUtil,
    {
      provide: NG_SELECT_DEFAULT_CONFIG,
      useValue: {
          notFoundText: 'Item não encontrado'
      }
    },
  ]
})
export class SharedModule { }
