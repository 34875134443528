declare var env: any;

export class SettingsService {

  private _currentRoute = null;

  get currentRoute(): string {
    let rc: string;
    if (sessionStorage.length > 0) {
      rc = JSON.parse(sessionStorage.getItem('rota-corrente')); 
      this._currentRoute = rc;    
    } else {
      this._currentRoute = null;
    }
    return this._currentRoute;
  }

  set currentRoute(rc: string) {
    sessionStorage.setItem('rota-corrente', JSON.stringify(rc));
    this._currentRoute = rc;
  }

  public removerCurrentRoute() {
    sessionStorage.removeItem('rota-corrente');
  }

  public UrlCpsRest() {
    return env.apiUrl();
  }

  public UrlIdp() {
    return env.idpUrl();
  }

  public UrlSiconv() {
    return env.siconvUrl();
  }
}
