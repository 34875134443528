import { ContratoRepasseVinculado, ContratoRepasseSubrogado, 
         ConstratoRepasseResumido, HistoricoCps } from '../../shared/models';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CpsHttpClient } from './cps-http.service';
import { SettingsService } from './settings.service';

@Injectable()
export class DetalhamentoContratoService {

    constructor(private http: CpsHttpClient, private settings: SettingsService) { }

    consultarContratosRepasseVinculados(cpsId): Observable<ContratoRepasseVinculado[]> {
        return this.http.get<ContratoRepasseVinculado[]>(this.settings.UrlCpsRest() + 'api/cps/contratorepasse/' + cpsId);
    }

    consultarContratosRepasseResumidos(cpsId): Observable<ConstratoRepasseResumido[]> {
        return this.http.get<ConstratoRepasseResumido[]>(this.settings.UrlCpsRest() + 'api/cps/contratorepasse/resumido/' + cpsId);
    }

    consultarContratosRepasseSubrogados(cpsId): Observable<ContratoRepasseSubrogado[]> {
        return this.http.get<ContratoRepasseSubrogado[]>(this.settings.UrlCpsRest() + 'api/cps/contratorepasse/subrogado/' + cpsId);
    }

    consultarHistoricoCps(cpsId): Observable<HistoricoCps[]> {
        return this.http.get<HistoricoCps[]>(this.settings.UrlCpsRest() + 'api/cps/historico/' + cpsId);
    }

}
