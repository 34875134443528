import { CpsAnexo } from './cps-anexo';
import { DocumentoCobranca } from './documento-cobranca';
import { EgtVersao } from './egt-versao';
export class CpsVersao {

    constructor(
        public id: number = 0,
        public codigo: number = null, // cd_versao
        public situacao: number = 1, // in_situacao
        public valorTotal: number = null,
        public dtInicioVigencia: Date = null,
        public dtFimVigencia: Date = null,
        public dtInicioLegal: Date = null,
        public dtFimLegal: Date = null,
        public dtReferenciaPreco: Date = null,        
        public inEncerramento: Boolean = null,
        public codigoF: string = '',
        public situacaoF: string = '',
        public dtInicioF: string = '',
        public dtFimF: string = '',
        public egts: EgtVersao[] = [],
        public anexos: CpsAnexo[] = [],        
        public documentosCobranca: DocumentoCobranca[] = []
    ) { }
}
