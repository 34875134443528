import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Orgao } from '../../shared/models';
import { CpsHttpClient } from './cps-http.service';
import { SettingsService } from './settings.service';

@Injectable()
export class OrgaoService {

  constructor(private http: CpsHttpClient, private settings: SettingsService) {}

  getOrgaoUsuario(): Observable<Orgao> {
    return this.http.get(this.settings.UrlCpsRest() + 'api/orgao-administrativo/usuario');
  }

  getOrgaos(): Observable<Orgao[]> {
    return this.http.get(this.settings.UrlCpsRest() + 'api/orgao-administrativo');
  }

  getOrgao(orgaoId: number): Observable<Orgao> {
    return this.http.get(this.settings.UrlCpsRest() + 'api/orgao-administrativo/' + orgaoId);
  }

  getOrgaoCodigo(codigo: number): Observable<Orgao> {
    return this.http.get(this.settings.UrlCpsRest() + 'api/orgao-administrativo/codigo/' + codigo);
  }

  getSubordinados(codigoOrgao: string): Observable<Orgao[]> {
    return this.http.get(this.settings.UrlCpsRest() + 'api/orgao-administrativo/' + codigoOrgao + '/subordinados');
  }

  getMandatarias(): Observable<Orgao[]> {
    return this.http.get(this.settings.UrlCpsRest() + 'api/orgao-administrativo/mandatarias');
  }
}
