export class ContratoRepasseVinculado {
  constructor(
    public numeroProposta: string,
    public numeroSiconv: string,
    public uf: string,
    public convenente: string,
    public situacaoProposta: string,
    public valorRepasse: number,
    public valorRepasseF: string,
    public cpsOrigem: string,
    public orgaoOrigem: string,
    public dataSubrogacao: string,
    public propFk: number = null,
    public transferido: boolean,
  ) {}
}

