export class HistoricoCps {
    constructor(
        public usuario: string,
        public dataSituacao: Date = null,
        public dataSituacaoF: string,
        public situacaoPropostaF: string,
        public situacaoPropostaAnteriorF: string,
        public motivo: string,
        public justificativa: string,
        public cpsVersaoFk: number,
        public codigo: number

    ) { }

}

