import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { CpsService, EgtService, DocumentoCobrancaService, IdpService, OrgaoService, CpsHttpClient,
          MenuService, SettingsService, EventButtonService, UsuarioService,
          DetalhamentoContratoService, TransferenciaInstrumentoService, RelatorioEgteService } from './services';
import { SiconvCacheService } from './services/siconv-cache.service';
import { UtilSqlService } from './services/util-sql.service';
import { SiconvAlertMessagesModule } from '@serpro/ngx-siconv';

@NgModule({
  imports: [
    SharedModule,
    SiconvAlertMessagesModule
  ],
  providers: [
    CpsService,
    TransferenciaInstrumentoService,
    EgtService,
    EventButtonService,
    DetalhamentoContratoService,
    DocumentoCobrancaService,
    IdpService,
    OrgaoService,
    UsuarioService,
    CpsHttpClient,
    MenuService,
    SettingsService,
    SiconvCacheService,
    UtilSqlService,
    RelatorioEgteService
  ]
})
export class CoreModule {
  constructor() {
  }
}

