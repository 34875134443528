import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CpsHttpClient } from './cps-http.service';
import { SettingsService } from './settings.service';

@Injectable()
export class UsuarioService {

    constructor(public httpClient: HttpClient, private http: CpsHttpClient, private settings: SettingsService ) { }

    isUsuarioMandatariaGestor(cpf): Observable<boolean> {
        return this.http.get(`${this.getUrl()}` + '/ehGestor/' + cpf);
    }

    public getUrl(): string {
        return this.settings.UrlCpsRest() + 'api/usuarioMandataria';
    }

}
