import { CpsHttpClient } from './cps-http.service';
import { SettingsService } from './settings.service';
import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class UtilSqlService {

  constructor(private http: CpsHttpClient, private settings: SettingsService) {}
  
  post(sql: any) {
    const headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    return this.http.post(this.settings.UrlCpsRest() + 'api/sql', sql, { headers: headers });
  }
}


