import { Pipe, PipeTransform } from '@angular/core';
import { ZeroEsquerdaPipe } from './zero-esquerda.pipe';

@Pipe({
  name: 'siconvDateHour'
})
export class SiconvDateHourPipe implements PipeTransform {
  zero: ZeroEsquerdaPipe = new ZeroEsquerdaPipe();
  transform(value: any): any {        
    if (typeof value === 'object') {      
      const ano = value.year;
      const mes = value.monthValue;
      const dia = value.dayOfMonth;
      return this.zero.transform(dia, 2) + '/' + this.zero.transform(mes, 2) + '/' + this.zero.transform(ano, 2);
    } else {
      const data = value.toString().split('-');
      const ano = data[0];
      const mes = data[1];
      const dia = data[2];
      const dia_hora = dia.toString().split(' ');
      if(dia_hora.length > 0){
        return this.zero.transform(dia, 2).toString().substring(0, 2) 
          + '/' + this.zero.transform(mes, 2) 
          + '/' + this.zero.transform(ano, 2)
          + ' '   + dia_hora[1].substring(0, 8);
      } else {
        return this.zero.transform(dia, 2).toString().substring(0, 2) + '/' + this.zero.transform(mes, 2) + '/' + this.zero.transform(ano, 2);
      }      
    }
    return null;
  }
}

