import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { CpsService, DetalhamentoContratoService, 
         IdpService, OrgaoService } from '../../core/services';
import { ContratoRepasseVinculado, Cps, Usuario, Orgao, InstrumentoTransferido, DataExport } from '../../shared/models';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { TransferenciaInstrumentoService } from '../../core/services/transferencia-instrumento.service';
import { TransferenciaInstrumento } from '../../shared/models/transferencia-instrumento';
import { Angular2Csv } from 'angular2-csv/Angular2-csv';
import { AlertMessageService } from '@serpro/ngx-siconv';

@Component({
  selector: 'cps-transferencia',
  templateUrl: './cps-transferencia.component.html',
  styleUrls: ['./cps-transferencia.component.scss']
})
export class CpsTransferenciaComponent implements OnInit {

  modalRef: BsModalRef;
  modalMessage = '';
  funcao: any = null;
  form: FormGroup;
  file: Angular2Csv = null;
  
  listaOrgaos: Orgao[] = new Array();
  listaCpsOrigem: Cps[] = new Array();
  listaCpsDestino: Cps[] = new Array();

  listaContratosRepasseSelecionados: ContratoRepasseVinculado[] = new Array();
  listaContratoRepasse: ContratoRepasseVinculado[] = new Array();
  lista: any[] = null;

  usuario: Usuario = null;
  ehUsuarioMandatariaGestor = false;

  orgaoId: number = null;
  cpsOrigemId: number = null;

  selection = new SelectionModel<number>(true, []);

  // Grid
  export: DataExport;
  fileName: string = 'CPS'

  constructor(
    private cpsService: CpsService,    
    private detalhamentoContratoService : DetalhamentoContratoService,
    private orgaoService: OrgaoService,
    private idpService: IdpService,
    private router: Router,
    private mensageService: AlertMessageService,
    private modalService: BsModalService,
    private transferenciaService: TransferenciaInstrumentoService,
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.mensageService.dismissAll();
    this.usuario = this.idpService.loggedUser;
    this.idpService.retoken();
    this.getForm();   
    this.carregarCombos();
    this.ehUsuarioMandatariaGestor = this.usuario.isUsuarioMandatariaGestor();    
  }

  orgaoCtrl: FormControl = new FormControl('', Validators.required);
  cpsOrigemCtrl: FormControl = new FormControl('', Validators.required);
  cpsDestinoCtrl: FormControl = new FormControl('', Validators.required);
  justificativaCtrl: FormControl = new FormControl('', Validators.compose([Validators.required, Validators.maxLength(2000)]));

  getForm(): void {
    this.form = this.formBuilder.group({
      orgao: this.orgaoCtrl,
      cpsOrigem: this.cpsOrigemCtrl,
      cpsDestino: this.cpsDestinoCtrl,
      justificativa: this.justificativaCtrl
    });
  }
  
  carregarCombos(){
    this.listarOrgaos();
    this.form.controls['orgao'].setValue(this.usuario.orgao.id);
    this.listarCpsOrigem();
  }

  onSalvarCPSTransferencia (template: TemplateRef<any>) {
    let hasError = false;
    this.mensageService.dismissAll();
    let erros = [];

    if (!this.form.controls['cpsOrigem'].value) {
      hasError = true;
      erros.push('O CPS origem é obrigatório');
    }
    if (!this.form.controls['cpsDestino'].value) {
      hasError = true;
      erros.push('CPS destino é obrigatório');
    }
    if (!this.form.controls['justificativa'].value) {
      hasError = true;
      erros.push('Justificativa é obrigatória');
    }
    if (this.selection.selected.length === 0) {
      hasError = true;
      erros.push('Nenhum contrato de repasse selecionado');
    }    

    if (hasError === false) {
      const numSelected = this.selection.selected.length;
      const cpsOrigem = this.obterCpsdaLista(this.listaCpsOrigem, this.form.controls['cpsOrigem'].value);
      const cpsDestino = this.obterCpsdaLista(this.listaCpsDestino, this.form.controls['cpsDestino'].value);
      let msg = '' ;
      if (numSelected === 1){
        msg = 'Confirmar a transferência do contrato de repasse do CPS '+ cpsOrigem.cps +' para o CPS '+ cpsDestino.cps + '? ';
      } else {
        msg = 'Confirmar a transferência dos '+ numSelected +' contratos de repasse do CPS '+ cpsOrigem.cps +' para o CPS '+ cpsDestino.cps + '? ';
      }
      this.showConfirm(template, msg, this.salvar);
    } else {
      this.mensageService.error('Campo obrigatório', '', erros);
    }
  }

  async salvar() {
    const instrumentos = new Array<InstrumentoTransferido>();
    if(this.listaContratosRepasseSelecionados != null){
      this.listaContratosRepasseSelecionados.forEach(element => {       
        instrumentos.push(new InstrumentoTransferido(null, element.propFk));
      });
    }

    const transferenciaInstrumento = new TransferenciaInstrumento(
       null,
       this.form.controls['cpsOrigem'].value,
       this.form.controls['cpsDestino'].value,
       this.form.controls['justificativa'].value,
       null, null, instrumentos
    );
    await this.transferenciaService.salvar(transferenciaInstrumento).subscribe(
      (data) => {
        const ret = data;
        this.listarContratoRepasse();
        this.mensageService.success('Contratos Transferidos', 
        'Contratos de repasse transferidos com sucesso');
      }
    );
  }

  getTotalSelecionados(){
    return this.selection.selected.length;
  }
  
  listarOrgaos() {
    let orgao = this.usuario.orgao;    
    if (orgao) {
      this.orgaoService.getSubordinados(orgao.codigo).subscribe(
        (response: Orgao[]) => {
          if (response) {
            this.listaOrgaos = response;
          } else {
            this.listaOrgaos = new Array();
          }
        }
      );      
    } else {
      this.mensageService.dismissAll();
      this.mensageService.warn('O usuario não associado', 
      'O usuario não está associado a nenhum orgão');
    }
  }

  listarCpsOrigem() {    
    this.orgaoId = this.form.controls['orgao'].value;
    this.form.controls['cpsOrigem'].setValue(null);
    this.form.controls['cpsDestino'].setValue(null);   
    this.form.controls['cpsOrigem'].disable();
    this.form.controls['cpsDestino'].disable();
    this.listaCpsOrigem = new Array();            
    this.listaCpsDestino = new Array();     
    
    if (this.orgaoId != null && this.orgaoId != undefined) {
      this.cpsService.listarCpsOrigem(this.orgaoId).subscribe(
        (response: Cps[]) => {
          if (response) {
            this.form.controls['cpsOrigem'].enable();
            this.listaCpsOrigem = response;       
          } else {            
            this.form.controls['cpsOrigem'].disable();
            this.form.controls['cpsDestino'].disable();
            const msg = 'Não existe CPS ativo ou encerrado cadastrado para o órgão selecionado.';            
            this.mensageService.dismissAll();
            this.mensageService.warn('CPS não existe', msg);
          }
        }
      );
    }    
  }

  obterCpsdaLista (listacps: Cps[], id: number): Cps {
    return listacps.find(row => row.id == id);
  }

  obterOrgaodaLista (listaOrgao: Orgao[], id: number): Orgao {
    return listaOrgao.find(row => row.id == id);
  }

  listarCpsDestino() {
    this.cpsOrigemId = this.form.controls['cpsOrigem'].value;       
    this.form.controls['cpsDestino'].setValue(null);    
    this.listaCpsDestino = new Array();

    if (this.cpsOrigemId != null && this.cpsOrigemId != undefined) { 
      this.cpsService.listarCpsDestino(this.orgaoId, this.cpsOrigemId).subscribe(
        (response: Cps[]) => {
          if (response) {
            this.form.controls['cpsDestino'].enable();
            this.listaCpsDestino = response;       
          } else {            
            this.form.controls['cpsDestino'].disable();
            const msg = 'Não existe CPS destino disponível para o CPS origem selecionado';
            this.mensageService.dismissAll();
            this.mensageService.warn('Não existe CPS', msg);
          }
        }
      );
      this.listarContratoRepasse();
    }  
  }

  listarContratoRepasse() {        
    this.listaContratoRepasse = new Array();
    this.selection.clear();
    if (this.cpsOrigemId != null) {      
      this.detalhamentoContratoService.consultarContratosRepasseVinculados(this.cpsOrigemId).subscribe(
        (response: ContratoRepasseVinculado[]) => {
          if(response){
            this.listaContratoRepasse = response;  
            this.getExport(); 
          } 
        }
      );
    } else {
      this.listaContratoRepasse = new Array();
      this.mensageService.dismissAll();
      this.mensageService.warn('Contratos não encontrados', 
      'Não foi possível recuperar os Contratos de Repasse');
    }    
  }

  masterToggle(event: any) {
    const target = event.target || event.srcElement;
    if (target && target.checked === true) {
      this.isAllSelected() ? this.selection.clear() : this.listaContratoRepasse.forEach(row => {
        this.selection.select(row.propFk);
      });
    } else {
      this.selection.clear();
    }

    this.montaListaSelecionada();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.listaContratoRepasse.length;
    return numSelected === numRows;
  }

  getTotalRepasse(): number{
    return this.listaContratoRepasse ? this.listaContratoRepasse.length : 0;
  }

  montaListaSelecionada() {
    const lista = this.listaContratoRepasse.filter(
      cr =>
        this.selection.selected.find(s => s === cr.propFk)
    );
    this.listaContratosRepasseSelecionados = lista;    
  }

  selecionar(selection, item: ContratoRepasseVinculado) {
    selection.toggle(item.propFk);
    this.montaListaSelecionada();
  }

  campoBloqueado(): any {
    if (!this.ehUsuarioMandatariaGestor) {
      return null;
    } else {
      return true;
    }
  }

  showConfirm(template: TemplateRef<any>, msg: string, funcao) {
    this.modalMessage = msg;
    this.funcao = funcao;
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
  }

  confirm(): void {
    if (this.funcao != null) {
      if (typeof (this.funcao) === 'function') {
        this.funcao.call(this);
      }
    }
    this.modalRef.hide();
  }

  decline(): void {
    this.modalRef.hide();
  }

  getListaPaginada(listap){
    this.lista = listap;
  }

  getExport(): void {
    let data = []
    const columns = [
      'Número da Proposta', 'Número SICONV/SIAFI', 'UF', 'Convenente', 'Valor de Repasse'
    ];

    this.listaContratoRepasse.forEach(element => {
      let linha = [];
      linha.push(element.numeroProposta);
      linha.push(element.numeroSiconv);
      linha.push(element.uf);
      linha.push(element.convenente);
      linha.push(element.valorRepasseF);
      data.push(linha);
    });   

    this.export = new DataExport(columns, data);
  }
}


