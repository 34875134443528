export class Egt {
  constructor(
    public id: number,
    public codigo: string,
    public descricao: string,
    public tipo: string,
    public in_alteracao_contratual: string,
    public quantidade: number,
    public nivel1: string,
    public nivel1a: string,
    public nivel2: string,    
    public nivel3a: string,
    public nivel3b: string,
    public nivel3c: string,
    public nivel4: string,
    public nivel5: string
  ) {}
}

