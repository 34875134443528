import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CpsService, EgtService, IdpService } from '../../../core/services';
import { Egt, EgtVersao, Usuario } from '../../../shared/models';
import { log } from 'util';
import * as moment from 'moment';
import { EventButtonService } from '../../../core/services/event-button.service';
import { CpsVersaoSituacaoEnum } from '../../../shared/models/enum/cps-versao-situacao.enum';

@Component({
  selector: 'cps-egt',
  templateUrl: './egt.component.html',
  styleUrls: ['./egt.component.scss']
})
export class EgtComponent implements OnInit {

  @Input() modoEdicao: Boolean = true;
  @Input() modoAditivacao: Boolean = true;  

  egts: EgtVersao[] = null;
  selection1 = new SelectionModel<Egt>(true, []);
  selection2 = new SelectionModel<Egt>(true, []);
  egtsOrdinarias: any[] = new Array();
  egtsExtras: any[] = new Array();
  listaEgtsSelecionada = new Array();
  listaTabelaReferenciaPreco: any[] = new Array();
  tabelaReferenciaPreco = null;
  usuarioLogado: Usuario = null;
  dtReferenciaPreco = null;  

  constructor(
    private router: Router,
    private idpService: IdpService,
    private egtservice: EgtService,
    private cpsservice: CpsService) {      
  }

  ngOnInit() {
    this.egts = this.cpsservice.cpsCorrente.versao.egts;
    this.dtReferenciaPreco = this.cpsservice.cpsCorrente.versao.dtReferenciaPreco ? moment(this.cpsservice.cpsCorrente.versao.dtReferenciaPreco).format('DD-MM-YYYY') : null;    
    this.usuarioLogado = this.idpService.loggedUser;
    this.carregarTabelasReferenciaPreco();
    this.carregarEgtsTela();
    this.campoBloqueado();
  }

  carregarTabelasReferenciaPreco(){
    this.egtservice.listarTabelasReferenciaPreco().subscribe(
      (response: any[]) => {
        if (response) {
          this.listaTabelaReferenciaPreco = response;          
        }
      }
    );
  }

  onChangeTabelaReferencia(){
    this.carregarEgtsTela();
    this.carregarEgtsSalvas();
  }
    
  carregarEgtsTela() {
    this.listarEgtsOrdinarias(this.dtReferenciaPreco);
    this.selection1.clear();
    this.selection2.clear();
    this.listarEgtsExtras(this.dtReferenciaPreco);
  }

  listarEgtsOrdinarias(data) {
    this.egtservice.listarEgtsOrdinarias(data).subscribe(
      (response: Egt[]) => {
        if (response) {
          this.egtsOrdinarias = response;
          this.carregarEgtsSalvas();
        }
      }
    );
  }

  listarEgtsExtras(data) {
    this.egtservice.listarEgtsExtras(data).subscribe(
      (response: Egt[]) => {
        if (response) {
          this.egtsExtras = response;
          this.carregarEgtsSalvas();
        }
      }
    );
  }

  carregarEgtsSalvas() {
    // this.dtReferenciaPreco = moment(this.cpsservice.cpsCorrente.versao.dtReferenciaPreco).format('DD-MM-YYYY');    
    this.egtservice.listarEgtsVersao(this.cpsservice.cpsCorrente.id, this.dtReferenciaPreco).subscribe(
      (response: EgtVersao[]) => {
        if (response) {
          this.listaEgtsSelecionada = response;
          this.listaEgtsSelecionada.forEach(element => {
            const item1 = this.egtsOrdinarias.find(x => x.id === element.egtFk);
            if (item1) {
              this.selection1.select(element.egtFk);
              item1.quantidade = element.quantidade;
            }

            const item2 = this.egtsExtras.find(x => x.id === element.egtFk);
            if (item2) {
              this.selection2.select(element.egtFk);
              item2.quantidade = element.quantidade;
            }
          });
        }
      }
    );
  }


  isAllSelected1() {
    const numSelected = this.selection1.selected.length;
    const numRows = this.egtsOrdinarias.length;
    return numSelected === numRows;
  }

  isAllSelected2() {
    const numSelected = this.selection2.selected.length;
    const numRows = this.egtsExtras.length;
    return numSelected === numRows;
  }

  masterToggle1(event: any) {
    const target = event.target || event.srcElement;
    if (target && target.checked === true) {
      this.isAllSelected1() ? this.selection1.clear() : this.egtsOrdinarias.forEach(row => {
        this.selection1.select(row.id);
      });
    } else {
      this.selection1.clear();
    }

    this.montaListaSelecionada();
  }

  onChangeQuantidade(selection, item) {
    const isChecked = selection.isSelected(item.id);

    if (!isChecked && item.quantidade > 0) {
      selection.select(item.id);
    }
    if (isChecked && item.quantidade <= 0) {
      selection.deselect(item.id);
    }
    this.montaListaSelecionada();
  }

  masterToggle2(event: any) {
    const target = event.target || event.srcElement;
    if (target && target.checked === true) {
      this.isAllSelected2() ? this.selection2.clear() : this.egtsExtras.forEach(row => {
        this.selection2.select(row.id);
      });
    } else {
      this.selection2.clear();
    }
    this.montaListaSelecionada();
  }

  selecionar(selection, item) {
    selection.toggle(item.id);
    if (!selection.isSelected(item.id)) {
      item.quantidade = null;
    }
    this.montaListaSelecionada();
  }

  montaListaSelecionada() {
    const lista1 = this.egtsOrdinarias.filter(
      egt =>
        this.selection1.selected.find(s => s === egt.id)
    );
    const lista2 = this.egtsExtras.filter(
      egt =>
        this.selection2.selected.find(s => s === egt.id)
    );
    this.listaEgtsSelecionada = lista1.concat(lista2);
    this.egts = this.listaEgtsSelecionada.map(
      e => new EgtVersao(this.cpsservice.cpsCorrente.versao.id, e.id, e.quantidade, null));
    const _cps = this.cpsservice.cpsCorrente;
    _cps.versao.egts = this.egts;
    _cps.versao.dtReferenciaPreco = this.dtReferenciaPreco;
    this.cpsservice.cpsCorrente = _cps;
  }

  campoBloqueado(): any {
    if (!this.usuarioLogado.isMandataria() && this.modoEdicao && (this.cpsservice.cpsCorrente.versao.situacao !== CpsVersaoSituacaoEnum.ATIVO)
        || (this.cpsservice.cpsCorrente.versao.situacao === CpsVersaoSituacaoEnum.ATIVO && this.modoAditivacao)){  
      return null;
    } else {
      return true;
    }
  }

  onPaste(e: any) {
    e.preventDefault();
  }
}
