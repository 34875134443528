export class DocumentoCobrancaAnexo {

  constructor(
    public id: number = 0,
    public documentoCobrancaFk: number = 0,
    public txDescricao: string = null,
    public arquivo: string = null,
    public nome: string = null,
    public situacao: string = null,
    public tamanho: number = null,
    public adtLogin: string = null,
    public ehMandataria: boolean = null
  ) { }

}
