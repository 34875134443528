import { CpsVersao } from './cps-versao';
export class Cps {

  constructor(
    public id: number = 0,
    public codigo: number = null,
    public ano: number = null,
    public processo: string = '',
    public cps: string = '',
    public orgaoSuperior: number = null,
    public orgaoConcedente: number = null,
    public orgaoMandataria: number = null,
    public douSecao: number = null,
    public douPagina: number = null,
    public douNumeroProcesso: string = '',
    public douDtPublicacao: Date = null,
    public uasg: number = null,
    public programaTrabalho: string = '',
    public acaoOrcamentaria: string = '',
    public fonte: string = '',
    public banco: number = null,
    public agencia: number = null,
    public conta: number = null,
    public cpfCnpjFavorecido: string = '',
    public nomeFavorecido: string = '',
    public versao: CpsVersao = new CpsVersao(),
    public versoes: CpsVersao[] = [],
    public nomeOrgaoConcedente: string = '',
    public codigoOrgaoConcedente: string = '',
    public dtPrimeiraAtivacao: Date = null
  ) { }
}
