import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'propostaSituacao'
})
export class PropostaSituacaoPipe implements PipeTransform {

  // transform(value: any, args?: any): any {
  //   return null;
  // }
  transform(value: string) {
    if (value) {
      switch (value.toString()) {
        case 'RASCUNHO':
          return 'Proposta/Plano de Trabalho em rascunho';
        case 'PROPOSTA_CADASTRADA':
          return 'Proposta/Plano de Trabalho Cadastrados';
        case 'PROPOSTA_RECEBIDA_COM_PENDENCIA':
          return 'Proposta recebida com pendências';
        case 'EM_CHAMAMENTO_PUBLICO_ENVIADA_PARA_ANALISE_PRELIMINAR':
          return 'Em Chamamento Público Enviada para Análise Preliminar';
        case 'EM_CHAMAMENTO_PUBLICO_CLASSIFICADA_ANALISE_PRELIMINAR':
          return 'Em Chamamento Público Classificada em Análise Preliminar';
        case 'EM_CHAMAMENTO_PUBLICO_ELIMINADA_ANALISE_PRELIMINAR':
          return 'Em Chamamento Público Eliminada em Análise Preliminar';
        case 'EM_CHAMAMENTO_PUBLICO_ELIMINADA_ANALISE_DEFINITIVA':
          return 'Em Chamamento Público Eliminada em Análise Definitiva';
        case 'EM_CHAMAMENTO_PUBLICO_CLASSIFICADA_ANALISE_DEFINITIVA':
          return 'Em Chamamento Público Classificada em Análise Definitiva';
        case 'PROPOSTA_APROVADA_AGUARDANDO_PLANO_TRABALHO':
          return 'Proposta Aprovada/Aguardando Plano de Trabalho';
        case 'PROPOSTA_ELIMINADA_EM_CHAMAMENTO_PUBLICO':
          return 'Proposta Eliminada em Chamamento Público';
        case 'PROPOSTA_ENVIADA_ANALISE':
          return 'Proposta/Plano de Trabalho enviado para Análise';
        case 'PROPOSTA_EM_ANALISE':
          return 'Proposta/Plano de Trabalho em Análise';
        case 'PROPOSTA_EM_COMPLEMENTACAO':
          return 'Proposta/Plano de Trabalho em Complementação';
        case 'PROPOSTA_COMPLEMENTADA_ENVIADA_ANALISE':
          return 'Proposta Complementada enviada para Análise';
        case 'PROPOSTA_COMPLEMENTADA_EM_ANALISE':
          return 'Proposta Complementada em Análise';
        case 'PROPOSTA_APROVADA_CONTRATACAO_PREJUDICADA':
          return 'Proposta Aprovada/Contratação Prejudicada';
        case 'PROPOSTA_CANCELADA':
          return 'Proposta cancelada';
        case 'PROPOSTA_REPROVADA':
          return 'Proposta/Plano de Trabalho Rejeitados';
        case 'PROPOSTA_REJEITADA_IMPEDIMENTO_TECNICO':
          return 'Proposta/Plano de Trabalho Rejeitados por Impedimento técnico';
        case 'PROPOSTA_LEGADO':
          return 'Proposta do Legado SIAFI';
        case 'HISTORICO':
          return 'Histórico';
        case 'PLANO_TRABALHO_ENVIADO_ANALISE':
          return 'Proposta Aprovada e Plano de Trabalho enviado para Análise';
        case 'PLANO_TRABALHO_EM_ANALISE':
          return 'Proposta Aprovada e Plano de Trabalho em Análise';
        case 'PLANO_TRABALHO_EM_COMPLEMENTACAO':
          return 'Proposta Aprovada e Plano de Trabalho em Complementação';
        case 'PLANO_TRABALHO_COMPLEMENTADO_ENVIADO_ANALISE':
          return 'Proposta Aprovada e Plano de Trabalho Complementado enviado para Análise';
        case 'PLANO_TRABALHO_COMPLEMENTADO_EM_ANALISE':
          return 'Proposta Aprovada e Plano de Trabalho Complementado em Análise';
        case 'PLANO_TRABALHO_APROVADO':
          return 'Proposta Aprovada e Plano de Trabalho Aprovado';
        case 'ASSINADA':
          return 'Assinada';
        case 'EM_EXECUCAO':
          return 'Em Execução';
        case 'AGUARDANDO_PRESTACAO_CONTAS':
          return 'Aguardando Prestação de Contas';
        case 'PRESTACAO_CONTAS_ATRASADA':
          return 'Prestação de Contas Atrasada';
        case 'PRESTACAO_CONTAS_ENVIADA_ANALISE':
          return 'Prestação de Contas Enviada para Análise';
        case 'PRESTACAO_CONTAS_EM_COMPLEMENTACAO':
          return 'Prestação de Contas em Complementação';
        case 'PRESTACAO_CONTAS_EM_ANALISE':
          return 'Prestação de Contas em Análise';
        case 'PRESTACAO_CONTAS_APROVADA_COM_RESSALVAS':
          return 'Prestação de Contas Aprovada com Ressalvas';
        case 'PRESTACAO_CONTAS_APROVADA':
          return 'Prestação de Contas Aprovada';
        case 'PRESTACAO_CONTAS_REJEITADA':
          return 'Prestação de Contas Rejeitada';
        case 'CONVENIO_ANULADO':
          return 'Convênio Anulado Encerrado';
        case 'CONVENIO_RESCINDIDO':
          return 'Convênio Rescindido Encerrado';
        case 'CONVENIO_EXTINTO':
          return 'Convênio Extinto Encerrado';
        case 'INADIMPLENTE':
          return 'Inadimplente';
        case 'ASSINATURA_PENDENTE_REGISTRO_TV_SIAFI':
          return 'Assinatura Pendente Registro TV Siafi';
        case 'EM_DILIGENCIA':
          return 'Em Diligência';
        case 'INADIMPLENCIA_SUSPENSA':
          return 'Inadimplência Suspensa';
        case 'INADIMPLENCIA_EFETIVA_ATRASO_ENTREGA_DOCUMENTACAO':
          return 'Inadimplência Efetiva Atraso na Entrega da Documentação';
        case 'INADIMPLENCIA_EFETIVA_APOS_ENTREGA_DOCUMENTACAO':
          return 'Inadimplência Pendente de Complementação no Siafi';
        case 'INADIMPLENCIA_PENDENTE_COMPLEMENTACAO':
          return 'Inadimplência Pendente de Complementação no Siafi';
        case 'INADIMPLENCIA_SUSPENSA_PENDENTE_COMPLEMENTACAO':
          return 'Inadimplência Suspensa Pendente de Complementação no Siafi';
        case 'PRESTACAO_CONTAS_COMPROVADA_EM_ANALISE':
          return 'Prestação de Contas Comprovada em Análise';
        case 'PRESTACAO_CONTAS_CONCLUIDA':
          return 'Prestação de Contas Concluída';
        default:
          break;
      }
    }
  }

}
