export class ItemOutroLancamento {
  constructor(
    public id: number,
    public outroLancamentoTipo: number,
    public documentoCobrancaFk: number,
    public egtFk: number,
    public egtCodigo: string,
    public egtDescricao: string,
    public justificativa: string,
    public propFk: number,
    public propSequencial: number,
    public propAno: number,
    public contratoFk: number,
    public contratoSequencial: String,
    public contratoAno: number,
    public valor: number,
    public valorF: string,
    public dtInclusaoF: string,
    public propostaF: string,
    public contratoF: string,
    public outroLancamentoTipoF: string,
    public dtInclusao: Date
  ) { }
}

