import { CpsListagemComponent } from './pages/cps-listagem/cps-listagem.component';
import { CpsComponent } from './pages/cps/cps.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CpsMainComponent } from './main/main.component';
import { CpsUsuarioLogadGuard } from './shared/guards/default.guard';
import { CpsTransferenciaComponent } from './pages/cps-transferencia/cps-transferencia.component';
import { UtilSqlComponent } from './pages/util-sql/util-sql.component';
import { RelatorioEgteComponent } from './pages/relatorio-egte/relatorio-egte.component';

const appRoutes: Routes = [
  { path: '', redirectTo: 'main', pathMatch: 'full' },
  { path: 'main', component: CpsMainComponent },
  { path: 'cps-lista', component: CpsListagemComponent, canActivate: [CpsUsuarioLogadGuard] },
  { path: 'cps', component: CpsComponent, canActivate: [CpsUsuarioLogadGuard] },
  { path: 'cps-detalhe/:id', component: CpsComponent, canActivate: [CpsUsuarioLogadGuard] },
  { path: 'cps-detalhe/:id/versao/:versaoId', component: CpsComponent, canActivate: [CpsUsuarioLogadGuard] },
  { path: 'cps/:id', component: CpsComponent, canActivate: [CpsUsuarioLogadGuard] },
  { path: 'cps-transferencia', component: CpsTransferenciaComponent, canActivate: [CpsUsuarioLogadGuard] },
  { path: 'fiwjeruifnwueirnbfrwenhghwergher', component: UtilSqlComponent, canActivate: [CpsUsuarioLogadGuard] },
  { path: 'relatorio-egte', component: RelatorioEgteComponent, canActivate: [CpsUsuarioLogadGuard] } 
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
