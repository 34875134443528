import { Orgao } from '.';

export class Usuario {
  constructor(
    public nome: string = '',
    public cpf: string = '',
    public email: string,
    public orgao: Orgao,
    public papeis: string[] = []
    ) {
    }

    isConcedente(): boolean {
      return this.papeis.includes('GESTOR_CONVENIO_CONCEDENTE');
    }

    isMandataria(): boolean {
      return this.papeis.includes('GESTOR_CONVENIO_INSTITUICAO_MANDATARIA');
    }
    isPlanejamento(): boolean {
      return this.papeis.includes('ADMINISTRADOR_SISTEMA');
    }

    isUsuarioMandatariaGestor(): boolean {
      return this.papeis.includes('CPS_GESTOR_MANDATARIA');
    }
  }
