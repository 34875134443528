import { PipeTransform, Pipe } from "@angular/core";
import { Orgao, Cps } from "../models";
import { Egt } from '../models/egt';
import { DatePipe } from '@angular/common';
import { ZeroEsquerdaPipe } from './zero-esquerda.pipe';


@Pipe({
  name: 'selectDescricaoId'
})
export class SelectDescricaoIdPipe implements PipeTransform {
  transform(tipos: any[]): string[] {
    return tipos.map(tipo => tipo.descricao);
  }
}

@Pipe({
  name: 'selectProposta'
})
export class SelectPropostaPipe implements PipeTransform {
  transform(props: any[]): string[] {
    return props.map(prop => prop.sequencial + '/' + prop.ano);
  }
}

@Pipe({
  name: 'selectCPS'
})
export class SelectCPSPipe implements PipeTransform {
  transform(lista: any[]): string[] {
    return lista.map(cps => cps.cps);
  }
}


@Pipe({
  name: 'selectEgt'
})
export class SelectEgtPipe implements PipeTransform {
  transform(items: Egt[]): string[] {
    return items.map(item => item.codigo);
  }
}



@Pipe({
  name: 'selectMotivoEncerramento'
})
export class SelectMotivoEncerramentoPipe implements PipeTransform {
  transform(motivos: any[]): string[] {
    return motivos.map(motivo => motivo.tx_descricao);
  }
}

@Pipe({
  name: 'selectOrgao'
})
export class SelectOrgaoPipe implements PipeTransform {
  transform(orgaos: Orgao[]): string[] {
    return orgaos.map(orgao => orgao.codigo + " - " + orgao.nome);
  }
}

@Pipe({
  name: 'selectTransferenciaCPS'
})
export class SelectTransferenciaCPSPipe implements PipeTransform {
  transform(listaCps: Cps[]): string[] {
    return listaCps.map(
      cps => cps.cps + " - Período de vigência: " 
             + cps.versao.dtInicioF + " à "
             + cps.versao.dtFimF + " - Situação: " 
             + cps.versao.situacaoF);
  }
}


@Pipe({
  name: 'selectDataReferenciaPreco'
})
export class SelectDataReferenciaPrecoPipe  implements PipeTransform {
  transform(lista): string[] {
    let zero: ZeroEsquerdaPipe = new ZeroEsquerdaPipe();  
    return lista.map(value => {
      const ano = value.year;
      const mes = value.monthValue;
      return zero.transform(mes, 2) + '/' + zero.transform(ano, 2);
    });
  }
}

// @Pipe({
//   name: 'valueDataReferenciaPreco'
// })
// export class ValueDataReferenciaPrecoPipe implements PipeTransform {
//   transform(values: Date[]): string[] {
//     return values.map(v => v);
//   }
// }