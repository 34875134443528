export class ContratoRepasseSubrogado {
  constructor(
    public numeroProposta: string,
    public numeroSiconv: string,
    public uf: string,
    public convenente: string,
    public situacaoProposta: string,
    public valorRepasse: number,
    public valorRepasseF: string,
    public idCpsDestino: string,
    public cpsDestino: string,
    public orgaoDestino: string,
    public dataSubrogacao: string,
    public dataSubrogacaoF: string
  ) {}
}

