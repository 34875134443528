import { Cps, DocumentoCobranca, DocumentoCobrancaAnexo, 
         DocumentoCobrancaSituacaoEnum, TipoOutroLancamentoEnum, Usuario } from './../../../shared/models';
import { DocumentoCobrancaService, CpsService, IdpService, 
         EventButtonService } from './../../../core/services';
import { FileUtil } from './../../../shared/util';
import { CpfPipe, CpsCurrencyPipe } from './../../../shared/pipes';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap';
import { Router } from '@angular/router';
import { Component, OnInit, TemplateRef, Input, OnDestroy, EventEmitter, Output } from '@angular/core';
import { Angular2Csv } from 'angular2-csv/Angular2-csv';
import { FiltroDocumentoCobranca } from '../../../shared/models/filtro-documento-cobranca';
import { AlertMessageService } from '@serpro/ngx-siconv';
import * as moment from 'moment';

@Component({
  selector: 'cps-documento-cobranca',
  templateUrl: './documento-cobranca.component.html',
  styleUrls: ['./documento-cobranca.component.scss']
})
export class DocumentoCobrancaComponent implements OnInit, OnDestroy {

  @Input() nomeOrgaoConcedente: String = '';
  @Input() codigoOrgaoConcedente: String = '';
  
  modalMessage = '';
  funcao: any = null;
  modalRef: BsModalRef;
  exibirOutrosLancamentos = false;
  indice = null;
  itemsPorPagina = 10;
  anexoDescricao: string = '';
  nomeAnexo: string = '';

  cps: Cps = null;
  cpsId = null;

  selectedRow = null;
  isNotEnviadoParaAnalise = true;
  isNotFechado = true;
  documentosCobranca: any = new Array();
  documentosCobrancaPaginada: any = new Array();
  documentoCobrancaRecente: DocumentoCobranca = null; // documento de cobranca mais recente trazido do banco
  documentoCobranca: DocumentoCobranca = null;
  painel = 1; // 1 - para painel cobranca ; 2 - para painel analitico
  usuario: Usuario = null;
  ehUsuarioMandatariaGestor = false;

  isAnexando: Boolean = false;
  anexos: DocumentoCobrancaAnexo[] = new Array();
  anexoAtual: DocumentoCobrancaAnexo;

  filtroDocCobranca: FiltroDocumentoCobranca = null;
  selecionarFatura: boolean = false;

  tipoVisao: any;

  ultimoItem: any;
  ultimoIndice: any;

  @Output() setAnexos = new EventEmitter();

  constructor(private router: Router,
    private fileUtil: FileUtil,
    private dcservice: DocumentoCobrancaService,
    private cpsservice: CpsService,
    private modalService: BsModalService,
    private idpService: IdpService,
    private mensageService: AlertMessageService) {
    EventButtonService.get('incluirOuDeletarOutrosLancamentos').subscribe(data => {
      if (data) {
        this.exibirOutrosLancamentos = false;
        this.carregarFaturasAnteriores();
        this.reloadDocumentoCobranca();
      }
    });
    EventButtonService.get('cancelarOutrosLancamentos').subscribe(data => {
      if (data) {
        this.exibirOutrosLancamentos = false;
      }
    });
    EventButtonService.get('filtrarDocCob').subscribe(filtro => {
      if (filtro) {
        this.filtroDocCobranca = filtro;        
        this.reloadDocumentoCobranca();
      }
    });    
  }

  ngOnInit() {
    this.mensageService.dismissAll();
    this.cps = this.cpsservice.cpsCorrente;
    this.cpsId = this.cps.id;
    this.usuario = this.idpService.loggedUser;
    this.carregarFaturasAnteriores();
    this.ehUsuarioMandatariaGestor = this.usuario.isUsuarioMandatariaGestor();
  }

  ngOnDestroy() {
    EventButtonService.unsubscribe('cancelarOutrosLancamentos');
    EventButtonService.unsubscribe('incluirOuDeletarOutrosLancamentos');
    EventButtonService.unsubscribe('filtrarDocCob');
  }

  carregarAnexos() {
    if (this.documentoCobranca && this.documentoCobranca.id) {
      this.dcservice.listarAnexos(this.documentoCobranca.id).subscribe(
        (response: DocumentoCobrancaAnexo[]) => {
          this.anexos = new Array();
          if (response) {
            response.map(e => {
              this.anexos.push(e);
            });
          }
        }
      );
    }
  }

  async enviarParaAnalise() {
    if (this.validarAnexos(this.anexos)) {
      await this.dcservice.enviar(this.documentoCobranca, this.anexos).subscribe(
        (response) => {
          this.dcservice.listarDocumentosCobranca(this.cpsId, this.filtroDocCobranca).subscribe(
            (response: DocumentoCobranca[]) => {
              if (response != null) {
                this.documentosCobranca = response;
              }
              this.documentoCobranca = this.documentosCobranca[0];
              this.documentoCobrancaRecente = this.documentoCobranca;
              this.dcservice.documentoCobrancaCorrente = this.documentoCobranca;
              this.detalhar(this.ultimoItem, this.ultimoIndice);
            }
          );                    
          this.mensageService.success('Documento enviado', 
          'Documento de cobrança enviado para análise com sucesso');
        }
      );
    }
  }

  validarAnexos(listaAnexos: DocumentoCobrancaAnexo[]): boolean {
    let retorno = true;
    let tamanhoAnexos = 0;
    listaAnexos.forEach(anexo => {
      if ((anexo.tamanho) && (anexo.situacao === 'N')) {
        tamanhoAnexos += anexo.tamanho;
      }
    });
    if (tamanhoAnexos / 1000000 > 25) {
      this.mensageService.error("", 
      'O somatório do tamanho dos arquivos anexados excede o limite de 25MB');
      retorno = false;
    }
    return retorno;
  }

  async darAceite() {
    await this.dcservice.aceitar(this.documentoCobranca.id).subscribe(
      (response) => {
        this.dcservice.listarDocumentosCobranca(this.cpsId, this.filtroDocCobranca).subscribe(
          (response: DocumentoCobranca[]) => {
            if (response != null) {
              this.documentosCobranca = response;
            }
            this.documentoCobranca = this.documentosCobranca[0];
            this.documentoCobrancaRecente = this.documentoCobranca;
            this.dcservice.documentoCobrancaCorrente = this.documentoCobranca;
            this.detalhar(this.ultimoItem, this.ultimoIndice);
          }
        ); 
        this.mensageService.success('Documento aceito', 
        'Aceite do documento de cobrança realizado com sucesso');
      }
    );
  }

  async carregarFaturasAnteriores() {    
    await this.dcservice.listarDocumentosCobranca(this.cpsId, this.filtroDocCobranca).subscribe(
      (response: DocumentoCobranca[]) => {
        if (response != null) {
          this.documentosCobranca = response;
        }
        this.documentoCobranca = this.documentosCobranca[0];
        this.documentoCobrancaRecente = this.documentoCobranca;
        this.dcservice.documentoCobrancaCorrente = this.documentoCobranca;
        this.detalhar(this.ultimoItem, this.ultimoIndice);
      }
    );
  }

  onEnviarParaAnalise(template) {
    this.mensageService.dismissAll();
    let msg = '';
    if (this.existeAnexo(this.anexos)) {
      msg = 'Confirmar o envio do documento de cobrança para análise?';
    } else {
      msg = 'Não existem arquivos anexados ao documento de cobrança. Confirmar o envio do documento de cobrança para análise?';
    }
    this.showConfirm(template, msg, this.enviarParaAnalise);
  }

  existeAnexo(anexos: DocumentoCobrancaAnexo[]): boolean {
    if (anexos) {
      const anexosfilter = anexos.filter(a => a.situacao !== 'E');
      return anexosfilter.length > 0;
    } else {
      return false;
    }
  }

  onAceitarDocumentoCobranca(template) {
    this.mensageService.dismissAll();
    const msg = 'Confirmar o aceite do documento de cobrança?';
    this.showConfirm(template, msg, this.darAceite);
  }

  isPainelCobranca() {
    return this.painel === 1;
  }

  async detalhar(item, indice) {
    this.indice = indice;
    // detalhar os relatorios analitico e resumido a partir do banco
    if (item && item.id) {
      await this.dcservice.detalharDocumentosCobranca(item.id, this.filtroDocCobranca).subscribe(
        (response: DocumentoCobranca) => {
          if (response != null) {
            // salva na memoria o documento-corrente para que os relatorios
            // internos consigam enxergar
            this.documentoCobranca = response;
            this.dcservice.documentoCobrancaCorrente = response;
            this.selectedRow = indice;

            this.carregarCpsVersao(this.documentoCobranca.cpsVersaoFk);

            this.carregarAnexos();
          }
        }
      );
    }
    this.ultimoItem = item;
    this.ultimoIndice = indice;
    this.selecionarFatura = false;
  }

  carregarCpsVersao(cpsVersaoId){
    this.cpsservice.getCps(this.cpsId, cpsVersaoId)
      .subscribe(
        (_cps: Cps) => {
          this.documentoCobranca.cpsVersao = _cps.versao;
          });
  }

  onFaturaAtual() {
    this.documentoCobranca = this.documentoCobrancaRecente;
    this.detalhar(this.documentoCobranca, null);
    this.painel = 1;
  }

  showConfirm(template: TemplateRef<any>, msg: string, funcao) {
    this.modalMessage = msg;
    this.funcao = funcao;
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
  }


  confirm(): void {
    if (this.funcao != null) {
      if (typeof (this.funcao) === 'function') {
        this.funcao.call(this);
      }
    }
    this.modalRef.hide();
  }

  decline(): void {
    this.modalRef.hide();
  }

  onExportarCsv() {
    this.mensageService.dismissAll();

    const options = {
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      headers: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '']
    };

    const nome = 'CPS' + this.cps.cps + '-' +
      this.nomeOrgaoConcedente.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '') + '-'
      + this.documentoCobranca.dtFechamentoF;
    const file: Angular2Csv = new Angular2Csv(this.getTableData(this.nomeOrgaoConcedente), nome.replace('/', '-'), options);

  }

  getTableData(nomeConcedente) {
    const retorno = [];
    let valorTotal = 0;
    const cpfPipe: CpfPipe = new CpfPipe();

    retorno.push({
      '0': this.cpsservice.cpsCorrente.cps + ' - ' + nomeConcedente,
      '1': '',
      '2': '',
      '3': '',
      '4': '',
      '5': '',
      '6': '',
      '7': '',
      '8': '',
      '9': '',
      '10': '',
      '11': '',
      '12': '',
      '13': '',
      '14': '',
      '15': '',
      '16': '',
      '17': '',
      '18': '',
      '19': '',
      '20': '',
      '21': '',
    });

    retorno.push({
      '0': 'Período do Documento de Cobrança: ' + this.documentoCobranca.periodoF,
      '1': '',
      '2': '',
      '3': '',
      '4': '',
      '5': '',
      '6': '',
      '7': '',
      '8': '',
      '9': '',
      '10': '',
      '11': '',
      '12': '',
      '13': '',
      '14': '',
      '15': '',
      '16': '',
      '17': '',
      '18': '',
      '19': '',
      '20': '',
      '21': '',
    });

    retorno.push({
      '0': 'Situação do Documento de Cobrança: ' + this.documentoCobranca.situacaoF,
      '1': '',
      '2': '',
      '3': '',
      '4': '',
      '5': '',
      '6': '',
      '7': '',
      '8': '',
      '9': '',
      '10': '',
      '11': '',
      '12': '',
      '13': '',
      '14': '',
      '15': '',
      '16': '',
      '17': '',
      '18': '',
      '19': '',
      '20': '',
      '21': '',
    });

    // retorno.push({
    //   '0': 'Tabela de Referência: ' + (this.documentoCobranca.cpsVersao ? moment(this.documentoCobranca.cpsVersao.dtReferenciaPreco).format('DD-MM-YYYY') : null),
    //   '1': '',
    //   '2': '',
    //   '3': '',
    //   '4': '',
    //   '5': '',
    //   '6': '',
    //   '7': '',
    //   '8': '',
    //   '9': '',
    //   '10': '',
    //   '11': '',
    //   '12': '',
    //   '13': '',
    //   '14': '',
    //   '15': '',
    //   '16': '',
    //   '17': '',
    //   '18': '',
    //   '19': '',
    //   '20': ''
    // });

    retorno.push({
      '0': '', '1': '', '2': '', '3': '', '4': '', '5': '', '6': '', '7': '', '8': '',
      '9': '', '10': '', '11': '', '12': '', '13': '', '14': '', '15': '', '16': '', '17': '',
      '18': '', '19': '', '20': '', '21': ''
    });

    retorno.push({
      '0': 'EGT',
      '1': 'Número da Proposta',
      '2': 'Número SICONV/SIAFI',
      '3': 'UF',
      '4': 'Convenente',
      '5': 'Programa',
      '6': 'Empenho',
      '7': 'UG Emitente',
      '8': 'UG Responsável',
      '9': 'Fonte',
      '10': 'Funcional',
      '11': 'Objeto',
      '12': 'Repasse',
      '13': 'Nível',
      '14': 'Data de Contratação',
      '15': 'Data Fim de Vigência',
      '16': 'CPF do Responsável do Concedente',
      '17': 'Nome do Responsável do Concedente',
      '18': 'Data do EGT',
      '19': 'Status do EGT',
      '20': 'Valor da Tarifa',
      '21': 'Tabela de Referência',
    });

    this.documentoCobranca.itensCobrancaOrdinaria.forEach(
      item => {
        valorTotal = valorTotal + item.valorTarifa;

        retorno.push({
          '0': item.egtCodigo + '-' + item.egtDescricao,
          '1': item.propostaF,
          '2': item.contratoF,
          '3': item.uf,
          '4': item.convenente,
          '5': item.programa,
          '6': item.empenho === null ? '' : item.empenho,
          '7': item.ugEmitente === null ? '' : item.ugEmitente,
          '8': item.ugResponsavel === null ? '' : item.ugResponsavel,
          '9': item.fonte === null ? '' : item.fonte,
          '10': item.funcional === null ? '' : item.funcional,
          '11': item.objeto,
          '12': item.valorRepasseF,
          '13': item.nivel,
          '14': item.dtInicioContratacaoF,
          '15': item.dtFimContratacaoF,
          '16': item.cpfResponsavelConcedente === null ? '' : cpfPipe.transform(item.cpfResponsavelConcedente),
          '17': item.nomeResponsavelConcedente === null ? '' : item.nomeResponsavelConcedente,
          '18': item.dtFimImrF,
          '19': item.statusEgt,
          '20': item.valorTarifaF,
          '21': item.tabelaReferencia,
        });
      });


    this.documentoCobranca.itensCobrancaExtra.forEach(
      item => {
        valorTotal = valorTotal + item.valorTarifa;
        retorno.push({
          '0': item.egtCodigo + '-' + item.egtDescricao,
          '1': item.propostaF,
          '2': item.contratoF,
          '3': item.uf,
          '4': item.convenente,
          '5': item.programa,
          '6': item.empenho === null ? '' : item.empenho,
          '7': item.ugEmitente === null ? '' : item.ugEmitente,
          '8': item.ugResponsavel === null ? '' : item.ugResponsavel,
          '9': item.fonte === null ? '' : item.fonte,
          '10': item.funcional === null ? '' : item.funcional,
          '11': item.objeto,
          '12': item.valorRepasseF,
          '13': item.nivel,
          '14': item.dtInicioContratacaoF,
          '15': item.dtFimContratacaoF,
          '16': item.cpfResponsavelConcedente === null ? '' : cpfPipe.transform(item.cpfResponsavelConcedente),
          '17': item.nomeResponsavelConcedente === null ? '' : item.nomeResponsavelConcedente,
          '18': item.dtFimImrF,
          '19': item.statusEgt,
          '20': item.valorTarifaF,
          '21': item.tabelaReferencia,
        });
      });

    retorno.push({
      '0': '', '1': '', '2': '', '3': '', '4': '', '5': '', '6': '', '7': '', '8': '',
      '9': '', '10': '', '11': '', '12': '', '13': '', '14': '', '15': '', '16': '', '17': '',
      '18': '', '19': '', '20': '', '21': ''
    });

    retorno.push({
      '0': 'Outros Lançamentos', '1': '', '2': '', '3': '', '4': '', '5': '', '6': '', '7': '', '8': '',
      '9': '', '10': '', '11': '', '12': '', '13': '', '14': '', '15': '', '16': '', '17': '',
      '18': '', '19': '', '20': '', '21': ''
    });

    if (this.documentoCobranca.itensOutroLancamento
      && this.documentoCobranca.itensOutroLancamento.length > 0) {
      retorno.push({
        '0': 'Número da Proposta',
        '1': 'Tipo de Lançamento',
        '2': 'EGT',
        '3': 'Data de Inclusão',
        '4': 'Justificativa',
        '5': 'Valor',
        '6': '',
        '7': '',
        '8': '',
        '9': '',
        '10': '',
        '11': '',
        '12': '',
        '13': '',
        '14': '',
        '15': '',
        '16': '',
        '17': '',
        '18': '',
        '19': '',
        '20': '',
        '21': '',
      });
    }

    this.documentoCobranca.itensOutroLancamento.forEach(
      item => {
        if (item.outroLancamentoTipo === TipoOutroLancamentoEnum.DEBITO) {
          valorTotal = valorTotal - item.valor;
        } else if (item.outroLancamentoTipo === TipoOutroLancamentoEnum.CREDITO) {
          valorTotal = valorTotal + item.valor;
        }

        retorno.push({
          '0': (item.propFk && item.propFk !== 0) ? item.propostaF : '',
          '1': item.outroLancamentoTipoF,
          '2': item.egtCodigo ? item.egtCodigo + '-' + item.egtDescricao : '',
          '3': item.dtInclusaoF,
          '4': item.justificativa,
          '5': this.getValorNegativoFormatado(item.valor, item.outroLancamentoTipo),
          '6': '',
          '7': '',
          '8': '',
          '9': '',
          '10': '',
          '11': '',
          '12': '',
          '13': '',
          '14': '',
          '15': '',
          '16': '',
          '17': '',
          '18': '',
          '19': '',
          '20': '',
          '21': '',
        });
      });

    retorno.push({
      '0': '', '1': '', '2': '', '3': '', '4': '', '5': '', '6': '', '7': '', '8': '',
      '9': '', '10': '', '11': '', '12': '', '13': '', '14': '', '15': '', '16': '', '17': '',
      '18': '', '19': '', '20': '', '21': '',
    });

    retorno.push({
      '0': 'Valor Total da Fatura: ' + this.getValorNegativoFormatado(valorTotal, null),
      '1': '',
      '2': '',
      '3': '',
      '4': '',
      '5': '',
      '6': '',
      '7': '',
      '8': '',
      '9': '',
      '10': '',
      '11': '',
      '12': '',
      '13': '',
      '14': '',
      '15': '',
      '16': '',
      '17': '',
      '18': '',
      '19': '',
      '20': '',
      '21': '',
    });
    return retorno;
  }

  getValorNegativoFormatado(valorTotal, tipo): string {

    const currencyPipe: CpsCurrencyPipe = new CpsCurrencyPipe();
    let valorF: string = 'R$ ' + (valorTotal === 0 ? '0,00' : currencyPipe.transform((100 * Number(valorTotal.toFixed(2))).toFixed(2).toString()));
    if (valorTotal < 0 || tipo === TipoOutroLancamentoEnum.DEBITO) {
      valorF = 'R$ -' + currencyPipe.transform((100 * Math.abs(Number(valorTotal.toFixed(2)))).toFixed(2).toString());
    }
    return valorF;
  }

  podeExportar() {
    return (
      this.documentoCobranca &&
      (this.documentoCobranca.itensCobrancaOrdinaria || this.documentoCobranca.itensCobrancaExtra)
    );
  }

  podeEnviarParaAnalise(): boolean {
    return (
      this.documentoCobranca != null && this.documentoCobranca.situacao === DocumentoCobrancaSituacaoEnum.FECHADO
      && this.usuario.isMandataria() && this.ehUsuarioMandatariaGestor
    );
  }

  podeAceitar(): boolean {
    return (
      this.documentoCobranca != null &&
      this.documentoCobranca.situacao === DocumentoCobrancaSituacaoEnum.ENVIADO_PARA_ANALISE && this.usuario.isConcedente() &&
      (this.usuario.orgao.id === this.cps.orgaoConcedente || this.usuario.orgao.id === this.cps.orgaoSuperior)
    );
  }

  podeExcluir(anexo: DocumentoCobrancaAnexo): boolean {
    if (anexo && anexo.adtLogin) {

      const isUsuarioConcedenteOuSuperior =
        (this.usuario.orgao.id === this.cpsservice.cpsCorrente.orgaoConcedente)
        || (this.usuario.orgao.id === this.cpsservice.cpsCorrente.orgaoSuperior);

      // se o usuario eh mandataria e o anexo pertence a uma mandataria
      // ou se o usuario eh concedente e o anexo pertence ao mesmo orgao ou subordinado
      return (anexo.ehMandataria && this.ehUsuarioMandatariaGestor)
        || (!anexo.ehMandataria && isUsuarioConcedenteOuSuperior);
    } else { return true; }
  }

  podeAnexar(): boolean {
    // if (!this.cpsService.cpsCorrente.orgaoSuperior) {
    //   return true;
    // }
    const orgaoUsuarioLogado = this.usuario.orgao;
    return orgaoUsuarioLogado && (
      this.ehUsuarioMandatariaGestor ||
      (
        (orgaoUsuarioLogado.id === this.cpsservice.cpsCorrente.orgaoConcedente
          || orgaoUsuarioLogado.id === this.cpsservice.cpsCorrente.orgaoSuperior)
      ));
  }

  public onSalvarAnexo() {
    this.mensageService.dismissAll();
    if (this.validarAnexos(this.anexos)) {
      this.anexar();
    }
  }

  async anexar() {
    await this.dcservice.salvarAnexo(this.documentoCobranca, this.anexos).subscribe(
      (data) => {
        this.carregarAnexos();
        if (data) {
          this.mensageService.success('Documento salvo', 
          'Documento de Cobrança salvo com sucesso');
        }
      }
    );
  }

  addAnexo() {
    this.isAnexando = true;
  }
  
  confirmarAnexo() {
    if (!this.anexoAtual) {
      this.mensageService.error('', 'Nenhum arquivo selecionado');
    } else if (!this.anexoDescricao) {
      this.mensageService.error('', 'A descrição do arquivo é obrigatória');
    } else {
      this.anexoAtual.txDescricao = this.anexoDescricao;      
      this.anexos.push(this.anexoAtual);
      this.setAnexos.emit(this.anexos);
      this.isAnexando = false;
      this.anexoDescricao = '';
      this.nomeAnexo= '';
      this.anexoAtual = null;
    }
  }

  anexoDownload(anexo) {
    if (anexo.id) {
      this.dcservice.baixarAnexo(this.documentoCobranca.id, anexo.id).subscribe(
        (response: DocumentoCobrancaAnexo[]) => {
          response.map(e => {
            this.fileUtil.downloadFile(e.arquivo, e.nome);
          });
        }
      );
    } else {
      this.fileUtil.downloadFile(anexo.arquivo, anexo.nome);
    }

  }

  removerAnexo(anexo: DocumentoCobrancaAnexo) {
    anexo.situacao = 'E';
    anexo.arquivo = '';
  }

  fileUpload(event, descricao) {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (this.isArquivoValido(file)) {
        const anexo = new DocumentoCobrancaAnexo();
        anexo.documentoCobrancaFk = this.documentoCobranca.id;
        const readerResult: any = reader.result;
        anexo.arquivo = readerResult.split(',')[1];
        anexo.txDescricao = descricao;
        anexo.nome = file.name;
        anexo.tamanho = file.size;
        anexo.situacao = 'N';
        this.anexoAtual = anexo;
      }
    };
    const [file] = event.target.files;
    reader.readAsDataURL(file);
  }

  isArquivoValido(file: File): boolean {
    const tiposValidos: string[] = new Array(
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'image/jpeg',
      'image/png',
      'application/vnd.oasis.opendocument.text',
      'application/vnd.oasis.opendocument.spreadsheet');
    let isValido = true;
    if (file.size / 1000000 > 25) {
      isValido = false;
      this.mensageService.error('', 'O tamanho máximo do arquivo é 25MB');
    } else if (tiposValidos.indexOf(file.type) < 0) {
      isValido = false;
      this.mensageService.error('', 
      'Formatos permitidos: PDF, DOC, DOCX, XLS, XLSX, JPG, JPEG, PNG, ODT e ODS');
    }
    return isValido;
  }

  onExibirOutrosLancamentos() {
    this.exibirOutrosLancamentos = !this.exibirOutrosLancamentos;
  }

  isExibirOutrosLancamentos() {
    return this.exibirOutrosLancamentos;
  }

  async reloadDocumentoCobranca() {    
    if(this.documentoCobranca){
      await this.dcservice.detalharDocumentosCobranca(this.documentoCobranca.id, this.filtroDocCobranca).subscribe(
        (response: DocumentoCobranca) => {
          if (response != null) {
            this.documentoCobranca = response;           
            this.carregarCpsVersao(this.documentoCobranca.cpsVersaoFk);              
          }
        }
      );    
    }
  }

  podeIncluirOutrosLancamentos() {  
    if (this.documentoCobranca) {
      return this.ehUsuarioMandatariaGestor && (this.documentoCobranca.situacao !== DocumentoCobrancaSituacaoEnum.ACEITO
        && this.documentoCobranca.situacao !== DocumentoCobrancaSituacaoEnum.PAGO);
    } else {
      return false;
    }    
  }
  
  selecionarFatura_click() {
    this.selecionarFatura = !this.selecionarFatura; 
  }

  getListaPaginada(listap){
    this.documentosCobrancaPaginada = listap;
  }
}
