import { Orgao } from '../../../shared/models/orgao';
import { OrgaoService } from '../../../core/services/orgao.service';
import { FiltroRelatorioEgte } from '../../../shared/models/filtro-relatorio-egte';
import { EventButtonService } from '../../../core/services';
import { Component, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import 'moment/locale/pt-br';
import { AlertMessageService } from '@serpro/ngx-siconv';

@Component({
  selector: 'cps-rel-egte-filtro',
  templateUrl: './filtrorelatorioegte.component.html'
})
export class FiltroRelatorioEgteComponent implements OnInit {

  @Output() filtroRelEgte: FiltroRelatorioEgte = null;
  form: FormGroup;

  listaUFs: any[] = ['AC','AL','AM','AP','BA','CE','DF','ES','GO','MA','MG',
  'MS','MT','PA','PB','PE','PI','PR','RJ','RN','RO','RR','RS','SC','SE','SP','TO'];
  covenenteCtrl: FormControl = new FormControl('');
  dtEgtInicioCtrl: FormControl = new FormControl('');
  dtEgtFimCtrl: FormControl = new FormControl('');
  egtCtrl: FormControl = new FormControl('');
  listaOrgaosConcedentes: any[] = new Array()

  constructor(
    private formBuilder: FormBuilder,
    private orgaoService: OrgaoService,
    private messageService: AlertMessageService
    
  ) {}

  ngOnInit() {
    this.getForm();
    this.listarOrgaos();
    this.onFiltrar();
  }

  getForm(): void {    
    let date = new Date();
    this.form = this.formBuilder.group({
      egt : 'EGTE1' ,
      proposta: null,
      uf: null,
      orgao : null,
      convenente: null,
      dtEgtInicio: new Date(date.getFullYear(), date.getMonth(), 1),
      dtEgtFim: null
    });

    
  }

   
  getFiltro(form: FormGroup): FiltroRelatorioEgte {
    let filtro: FiltroRelatorioEgte = new FiltroRelatorioEgte();

    const egt = form.controls['egt'].value;
    if (egt) {
      filtro.egt = egt;
    } else {
      filtro.egt = null;
    }
    const proposta = form.controls['proposta'].value;
    if (proposta) {
      filtro.proposta = proposta;
    } else {
      filtro.proposta = null;
    }
    
    const uf = form.controls['uf'].value;
    if (uf) {
      filtro.uf = uf;
    } else {
      filtro.uf = null;
    }

    const convenente = form.controls['convenente'].value;
    if (convenente) {      
      filtro.convenente = convenente;
    }else{
      filtro.convenente = null;
    }
        
    const orgao = form.controls['orgao'].value;
    if (orgao) {
      filtro.orgao = orgao;
    } else {
      filtro.orgao = null;
    }

    if (form.controls['dtEgtInicio'].value) {
      filtro.dataInicialEgt = (moment(form.controls['dtEgtInicio'].value, 'YYYY-MM-DD').format('YYYY-MM-DD'));
    } else {
      filtro.dataInicialEgt = null;
    }
    if (form.controls['dtEgtFim'].value) {
      filtro.dataFinalEgt = (moment(form.controls['dtEgtFim'].value, 'YYYY-MM-DD').format('YYYY-MM-DD'));
    } else {
      filtro.dataFinalEgt = null;
    }
    return filtro;
    }

  private listarOrgaos(): void {
    this.orgaoService.getOrgaos().subscribe(
      (response: Orgao[]) => {
        this.listaOrgaosConcedentes = response;
      }
    );
  }

  onFiltrar() {      
    this.messageService.dismissAll();
    this.filtroRelEgte = this.getFiltro(this.form);    

    if(!this.filtroRelEgte || !this.filtroRelEgte.egt || this.filtroRelEgte.egt === 'selecione'){
      this.messageService.error('', 'O EGT é obrigatório');
      return false;
    }  
    EventButtonService.get('filtrarRelEgt').emit(this.filtroRelEgte);    
  }  
}