import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HttpModule} from '@angular/http';
import { MenuComponent } from './menu.component';

@NgModule({
  imports: [
    CommonModule,
    HttpModule,
    BrowserModule
  ]
})
export class MenuModule { }
