import { Orgao, Usuario } from './../../shared/models';
import { OrgaoService } from './orgao.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UtilToken } from '../../shared/util';
import { CpsHttpClient } from './cps-http.service';
import { SettingsService } from './settings.service';
import { UsuarioService } from './usuario.service';

@Injectable()
export class IdpService {

  private _idpToken = null;
  private _loggedUser = null;  
  
  constructor(
    protected http: CpsHttpClient,
    private utilToken: UtilToken,
    private settings: SettingsService,
    private orgaoService: OrgaoService,
    private usuarioService: UsuarioService,
    private router: Router) { }

  get idpToken(): String {
    return this._idpToken;
  }
  set idpToken(idpToken: String) {
    this._idpToken = idpToken;
  }

  get loggedUser(): Usuario {
    return this._loggedUser;
  }
  set loggedUser(loggedUser: Usuario) {
    this._loggedUser = loggedUser;
  }  

  public logout(): void {
    this.utilToken.delToken();
    this.idpToken = null;
    window.location.href = this.settings.UrlSiconv() + '?LLO=true';
  }

  private loadLoggedUser(orgao: Orgao, papeis?): Usuario {
    const jwt = this.utilToken.getToken();
    const infoToken = this.utilToken.parseJwt(jwt.token);
    if (infoToken) {    
      let papeisUsuario;
      if (papeis) {
        papeisUsuario = papeis;
      } else {
        papeisUsuario = infoToken.papeis;
      }
      const user = new Usuario(infoToken.nome, infoToken.cpf, '', orgao, papeisUsuario);
      return user;
    } else {
      return null;
    }
  }

  public getUrl(): String {
    return this.settings.UrlIdp();
  }

  getIdpToken(): void {
    window.location.href = this.getUrl() + '/token/key?app=CPS&path=' + encodeURIComponent('#/main');
  }

  async getJWT(idpToken: String) {
    let jwt = null;
    this.utilToken.delToken();
    const response = await this.getIdpJwt(idpToken).toPromise();
    jwt = response;
    this.utilToken.setToken(jwt);
    const infoToken = this.utilToken.parseJwt(jwt.token);
    if (infoToken) {
      const orgao = await this.orgaoService.getOrgaoUsuario().toPromise();
      // Verificando se o usuário é um mandatariaGestor, para adicionar o papel GESTOR_MANDATARIA_CPS e evitar
      // requests futuros. O método isUsuarioMandatariaGestor da classe Usuario poderá ser chamada para essa verificacao
      const isUsuarioGestor = await this.usuarioService.isUsuarioMandatariaGestor(infoToken.cpf).toPromise();
      if (isUsuarioGestor) {
        let papeis = [] ;
        if(infoToken.papeis){
          papeis = infoToken.papeis;
        }
        papeis.push('CPS_GESTOR_MANDATARIA');
        this.loggedUser = this.loadLoggedUser(orgao, papeis);
      } else {
        this.loggedUser = this.loadLoggedUser(orgao);
      }

      if (this.settings.currentRoute && this.settings.currentRoute == 'cps-transferencia') {
        this.router.navigate(['/'.concat(this.settings.currentRoute)]);
        this.settings.removerCurrentRoute();
      } else if ((this.settings.currentRoute && this.settings.currentRoute == 'relatorio-egte')){
        this.router.navigate(['/'.concat(this.settings.currentRoute)]);
        this.settings.removerCurrentRoute();
      } else {
        this.router.navigate(['/'.concat('cps-lista')]);
      }     
    }
    return jwt;
  }

  getIdpJwt(idpToken: String) {
    const options = this.http.getDefaultRequestOptions();
    options.withCredentials = true;
    return this.http.getNoToken(`${this.getUrl() + '/token/jwt?token='}${idpToken}`, options);
  }

  async retoken() {
    const response = await this.getRetoken().toPromise();
    const jwt = response;
    this.utilToken.delToken();
    this.utilToken.setToken(jwt);
    return this.utilToken.getToken();
  }

  getRetoken() {
    const options = this.http.getDefaultRequestOptions();
    options.withCredentials = true;
    return this.http.get(`${this.getUrl() + '/jwt/refresh'}`, options);
  }
}
