import { CanActivate, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { IdpService, SettingsService } from '../../core/services';

@Injectable()
export class CpsUsuarioLogadGuard implements CanActivate {

  constructor(private idpService: IdpService, private settingsService: SettingsService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.idpService.loggedUser) {
      this.settingsService.removerCurrentRoute();
      return true;
    } else {
      this.settingsService.currentRoute = route.routeConfig.path;
      this.idpService.getIdpToken();
    }
    return false;
  }
}
