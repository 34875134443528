import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, LOCALE_ID } from '@angular/core';
import { HashLocationStrategy, LocationStrategy, APP_BASE_HREF, registerLocaleData } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { PagesModule } from './pages/pages.module';
import { AppComponent } from './app.component';
import { NgHttpLoaderModule } from 'ng-http-loader/ng-http-loader.module';

import { SimpleTimer } from 'ng2-simple-timer';

import { CpsMainComponent } from './main/main.component';
import { CpsUsuarioLogadGuard } from './shared/guards/default.guard';

import { ptBrLocale } from 'ngx-bootstrap/locale';
import { CoreModule } from './core/core.module';
import { AppConfigService } from './app.config.service';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { updateLocale } from 'ngx-bootstrap/chronos/locale/locales';
import localeBr from '@angular/common/locales/pt';
import localeBrExtra from '@angular/common/locales/extra/pt';

defineLocale('pt-br', ptBrLocale);
updateLocale('pt-br', { invalidDate: 'Data Inválida' });
registerLocaleData(localeBr, 'pt-br', localeBrExtra);

export function loadConfigService(configService: AppConfigService) {
  return () => {
    return configService.load();
  };
}

@NgModule({
  declarations: [
    AppComponent,
    CpsMainComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    PagesModule,
    HttpClientModule,
    NgHttpLoaderModule,
    CoreModule
  ],
  providers: [
    CpsUsuarioLogadGuard,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: LOCALE_ID, useValue: 'pt-br' },
    SimpleTimer
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
